import {
  gameConfig,
  pathsConfig
} from '@/app/config'
import {
  game,
  THREE
} from '@powerplay/core-minigames'

/**
 * Lines Manager
 * Trieda spravujuca drahy po ktorych behaju hraci
 */
export class LinesManager {

  /** dostupne drahy */
  private playerPaths: THREE.CurvePath<THREE.Vector3>[] = []

  /** raycast z hraca */
  private debugRaycast = new THREE.Raycaster()

  /**
   * init
   */
  public init(): void {

    const gameObjectWithLines = new THREE.Group()

    const { trackNumbers } = pathsConfig

    for (let i = 0; i < trackNumbers.length; i++) {

      const pathName = `TrackLine${ trackNumbers[i]}`
      const guideName = `TrackGuide${trackNumbers[i]}`

      const trackPlayer = game.getObject3D(pathName)
      const guide = game.getObject3D(guideName)
      trackPlayer.visible = false
      guide.visible = false
      this.playerPaths[i] = new THREE.CurvePath<THREE.Vector3>()

      this.setupCurvePathFromObject(trackPlayer as THREE.LineSegments, this.playerPaths[i])

      gameObjectWithLines.add(trackPlayer)

      game.scene.add(gameObjectWithLines)

      /*
       * const geometrySphere = new THREE.SphereGeometry(0.1, 0.1, 0.1)
       * const materialSphere = new THREE.MeshBasicMaterial({ color: Math.random() * 0xffffff })
       * const meshSphere = new THREE.Mesh(geometrySphere, materialSphere)
       * const position = this.playerPaths[i].getPointAt(
       *     finishPhaseConfig.finishPhaseStartPercent[i]
       * )
       * meshSphere.position.set(
       *     position.x, position.y + gameConfig.yPlayerCorrection, position.z
       * )
       * meshSphere.name = `${pathName}_debug`
       * game.scene.add(meshSphere)
       */

    }

  }

  /**
   * @param index - index drahy
   * @returns - drahu, ak je validny index
   */
  public getPath(index: number): THREE.CurvePath<THREE.Vector3> | undefined {

    if (index < 0) return undefined
    if (index > this.playerPaths.length - 1) return undefined

    return this.playerPaths[index]

  }

  /**
   * Vytvorenie curvePath z modelu
   * @param object - objekt s krivkou
   * @param curvePath - objekt, kde ulozime curvePath
   */
  private setupCurvePathFromObject(
    object: THREE.LineSegments,
    curvePath: THREE.CurvePath<THREE.Vector3>
  ): void {

    const pointsArray: number[] = Array.from(object.geometry.attributes.position.array)
    const coordinates: (THREE.Vector3 | undefined)[] = pointsArray
      .map((_: number, idx: number, origArray) => {

        if (idx % 3 !== 0) {

          return undefined

        }
        return new THREE.Vector3(
          origArray[idx],
          origArray[idx + 1],
          origArray[idx + 2]
        )

      }).filter(e => e !== undefined)

    if (coordinates.includes(undefined)) {

      // error
      return

    }

    const lastPoint = new THREE.Vector3()
    coordinates.forEach((point, index) => {

      if (point === undefined) return
      if (index === 0) console.log(point)
      if (index > 0) {

        curvePath.add(new THREE.LineCurve3(
          new THREE.Vector3(lastPoint.x, lastPoint.y, lastPoint.z),
          new THREE.Vector3(point.x, point.y, point.z)
        ))

      }

      lastPoint.copy(point)

    })

  }

  /**
   * Tool na pocitanie percent pre vsetky drahy z percenta na jednej
   * @param percent - percento na hlavnej drahe
   * @param pathIndex - index hlavnej drahy
   */
  public getPercentsOnAllPathsFromOne(percent: number, pathIndex = 3): number[] {

    const position = this.playerPaths[pathIndex].getPointAt(percent)
    position.y += gameConfig.yPlayerCorrection
    const lookAt = this.playerPaths[pathIndex].getPointAt(percent + 0.0016)
    lookAt.setY(position.y)

    const axisY = new THREE.Vector3(0, 1, 0)
    const resultPercents = []

    const { trackNumbers } = pathsConfig
    for (let i = 0; i < trackNumbers.length; i++) {

      if (i === pathIndex) {

        resultPercents.push(percent)
        continue

      }
      let toRight = false
      if (i > pathIndex) toRight = true

      const vector = new THREE.Vector3()
      vector.subVectors(lookAt, position).normalize()

      let angle = Math.PI / 2
      if (!toRight) angle *= -1

      vector.applyAxisAngle(axisY, angle)

      this.debugRaycast.set(position, vector)
      /*
       * const debugArrow =  new THREE.ArrowHelper(
       *     this.debugRaycast.ray.direction,
       *     this.debugRaycast.ray.origin,
       *     2,
       *     Math.random() * 0xffffff
       * )
       * game.scene.add(debugArrow)
       */

      const trackNumber = pathsConfig.trackNumbers[i]

      // zoberiem si bod, kde sa pretal s krivkou kde chcem ist
      const intersectionPoint = this.debugRaycast
        .intersectObject(game.getObject3D(`TrackGuide${trackNumber}`))?.[0]?.point

      if (intersectionPoint === undefined) continue
      intersectionPoint.setY(0)

      // zoberiem si z tej krivky x bodov pred aktualnym percentom a x bodov po aktualnom percente
      const pointsToSearch = this.getPointsToSearch(percent, i)

      // prejdem tento zoznam a najdem najblizsi bod,
      let distance: number | undefined = undefined
      let lowestDistancePointIndex = 0
      pointsToSearch.forEach((point, index) => {

        const newDistance = point.distanceTo(intersectionPoint)

        if (distance === undefined || newDistance < distance) {

          distance = newDistance
          lowestDistancePointIndex = index

        }

      })

      const { percentDiff, pointsPerPercent } = pathsConfig.changePathConfig
      const newPercent = percent +
                (lowestDistancePointIndex / pointsPerPercent - percentDiff)
      resultPercents.push(newPercent)

    }

    return resultPercents

    resultPercents.forEach((result, i) => {

      const spherePosition = this.playerPaths[i].getPointAt(result)
      const name = `${i}_percent_debug`

      const sphere = game.scene.getObjectByName(name)

      if (sphere) {

        sphere.position.set(spherePosition.x, spherePosition.y + gameConfig.yPlayerCorrection, spherePosition.z)
        return

      }
      const geometrySphere = new THREE.SphereGeometry(0.1, 0.1, 0.1)
      const materialSphere = new THREE.MeshBasicMaterial({ color: Math.random() * 0xffffff })
      const meshSphere = new THREE.Mesh(geometrySphere, materialSphere)

      meshSphere.position.set(spherePosition.x, spherePosition.y + gameConfig.yPlayerCorrection, spherePosition.z)
      meshSphere.name = name
      game.scene.add(meshSphere)

    })

    console.warn('Percenta: ', resultPercents)

  }

  /**
   * Vyberie body vyseku drahy s frekvenciou podla configu
   * @param percent - percento na hlavnej drahe
   * @param pathIndex - index hlavnej drahy
   * @returns pointsToSearch - body na vyseku drahy
   */
  private getPointsToSearch(percent: number, pathIndex: number): THREE.Vector3[] {

    const pointsToSearch: THREE.Vector3[] = []
    const { percentDiff, pointsPerPercent } = pathsConfig.changePathConfig

    for (let i = 0; i < percentDiff * 2 * pointsPerPercent; i++) {

      const point = this.playerPaths[pathIndex].getPointAt(percent + (i / pointsPerPercent - percentDiff))
      if (point) {

        point.setY(0)
        pointsToSearch.push(point)

      } else {

        pointsToSearch.push(new THREE.Vector3())

      }

    }
    return pointsToSearch

  }

}
export const linesManager = new LinesManager()