<template>
  <section class="movement-buttons-section">
    <movement-buttons
      orientation="vertical"
      :disabled="disabled"
      :is-scaled="false"
      @position="position"
      @end-contact="endContact"
    />
  </section>
</template>

<script lang="ts">
import { MovementButtons } from '@powerplay/core-minigames-ui'

import { defineComponent } from 'vue'

export default defineComponent({
  name: 'MovementButtonsComponent',
  components: {
    MovementButtons
  },
  props: {
    disabled: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  methods: {
    position(value: number | string) {

      if (value === 1) {

        window.dispatchEvent(new KeyboardEvent('keydown', { key: 'w' }))

      } else {

        window.dispatchEvent(new KeyboardEvent('keydown', { key: 's' }))

      }

    },
    endContact() {

      window.dispatchEvent(new KeyboardEvent('keyup', { key: 'w' }))
      window.dispatchEvent(new KeyboardEvent('keyup', { key: 's' }))

    }
  }
})

</script>
