/**
 * Konfig pre tep srdca
 */
export const heartRateConfig = {

  /** Zmena tepu sprint */
  sprintPhaseAdd: 5,

  /** Zmena tepu downhill */
  downhillPhaseRemove: -3,

  /** Zmena tepu beh hore */
  runningPhaseAdd: 2,

  /** Zmena tepu beh dole */
  runningPhaseRemove: -2,

  /** Minimalna hodnota tepu */
  minRate: 120,

  /** Maximalna hodnota tepu */
  maxRate: {
    sprinting: 200,
    skating: 190
  },

  /** Maximalna hodnota tepu v splitStreame*/
  slipStreamMaxHeartRate: 190,

  /** Hodnota pre vypocet speedBarHeartRate hodnoty */
  speedBarCoef: 190,

  /** SplipStream koeficient pre tep, podla toho ci je, alebo nie je v zavetri */
  slipStreamBPM: {
    is: 10,
    not: 0
  },

  /** hodnota na pridavanie tepu pocas rozbehu */
  speedUpBPM: 1

}
