import {
  finishPhaseConfig,
  pathsConfig,
  playerAnimationConfig
} from '@/app/config'
import { disciplinePhasesManager } from '@/app/phases/DisciplinePhasesManager'
import {
  AudioNames,
  DisciplinePhases,
  PlayerAnimationsNames,
  TutorialEventType
} from '@/app/types'
import {
  CallbackAnimationTypes,
  corePhasesManager,
  modes,
  playersManager,
  trainingManager,
} from '@powerplay/core-minigames'
import { audioHelper } from '@/app/audioHelper/AudioHelper'
import type { StartPhaseManager } from '@/app/phases/StartPhase/StartPhase'
import type { RunningPhase } from '@/app/phases/RunningPhase/RunningPhase'
import type { Athlete } from './Athlete'
import { tutorialFlow } from '@/app/modes/tutorial/TutorialFlow'
import { InternalCrossfadesManager } from '.'

/**
 * Dedikovany manazer animacii pre hraca/superov.
 */
export class AthleteAnimationManager {

  /** Prave beziaca animacia */
  private animationRunning = PlayerAnimationsNames.doublePoling

  /** Ci uz bola nastavena specialna animacia prepare, ak treba */
  private prepareAnimationSet = false

  /** Ci je aktivny starting state */
  private startingStateActive = false

  /** Ci je aktivny start run state */
  private startRunStateActive = false

  /** Ci je aktivny starting state */
  private skatingStateActive = false

  /** Ci je aktivny end state */
  private endStateActive = false

  /** Ci je aktivny lunge state */
  private lungeStateActive = false

  /** aktualna animacia - TODO rozsir o vsetky animacie mimo konecnej */
  public actualAnimation?: PlayerAnimationsNames

  /** ci sme nastavili konecnu animaciu */
  public isEndEmotionSet = false

  /** nazov animacie pri zmene drahy */
  public changingPathAnimation = PlayerAnimationsNames.changeL

  /** ci uz skoncila lunge animacia */
  private lungeAnimationEnded = false

  /** pocet framov od skoncenia lunge animacie */
  private lungeStateFrames = 0

  /** Posledna animacia pred zmenou drahy */
  private animationBeforeChangePath = PlayerAnimationsNames.doublePoling

  /** Manazer internych crossfadov */
  private internalCrossfadesManager: InternalCrossfadesManager

  /** Mozne animacie pre zmenu drahy */
  private readonly changePathAnimations = [
    PlayerAnimationsNames.changeL,
    PlayerAnimationsNames.changeR,
    PlayerAnimationsNames.downhillLeft,
    PlayerAnimationsNames.downhillRight
  ]

  /**
   * Konstruktor
   * @param athlete - Atlet
   */
  public constructor(private athlete: Athlete) {

    this.internalCrossfadesManager = new InternalCrossfadesManager(athlete, this.changePathAnimations)

  }

  /**
   * Nastavenie interneho crossfade
   * @param animation - Animacia
   * @param frames - Pocet frameov na tento proces
   * @param changeTime - Ci sa ma nastavovat cas podla starej animacie, v podstate keepTime parameter crossfadeTo
   */
  private setInternalCrossfade(animation: PlayerAnimationsNames, frames: number, changeTime = true): void {

    const possibleNewRunnningAnimation = this.internalCrossfadesManager.setCrossfade(
      animation,
      this.animationRunning,
      frames,
      changeTime
    )
    if (possibleNewRunnningAnimation) this.animationRunning = possibleNewRunnningAnimation

  }

  /**
   * Spustenie startovacej animacie
   */
  private startAnimation(): void {

    // player.animationsManager.crossfadeTo(PlayerAnimationsNames.start, 0.01, true, false)
    this.athlete.animationsManager.changeTo(PlayerAnimationsNames.start)

  }

  /**
   * Spustenie animacie neutral
   */
  private startNeutralAnimation(): void {

    this.athlete.animationsManager.changeTo(PlayerAnimationsNames.neutral)

  }

  /**
   * Spustenie animacie happy
   */
  private startHappyAnimation(): void {

    this.athlete.animationsManager.changeTo(PlayerAnimationsNames.happy)

  }

  /**
   * Start animacie lunge
   */
  private startLungeAnimation(): void {

    this.athlete.animationsManager.addAnimationCallback(
      PlayerAnimationsNames.lunge,
      CallbackAnimationTypes.end,
      () => {

        this.athlete.animationsManager.removeAnimationCallback(
          PlayerAnimationsNames.lunge,
          CallbackAnimationTypes.end
        )

        this.lungeAnimationEnded = true


      }
    )
    this.internalCrossfadesManager.disable(this.animationRunning)
    this.athlete.animationsManager.changeTo(PlayerAnimationsNames.lunge)

  }

  /**
   * Start animacie lunge to emotion
   */
  private startLungeToEmotionAnimation(): void {

    this.lungeAnimationEnded = false
    this.athlete.animationsManager.addAnimationCallback(
      PlayerAnimationsNames.lungeToEmotion,
      CallbackAnimationTypes.end,
      () => {

        this.athlete.animationsManager.removeAnimationCallback(
          PlayerAnimationsNames.lungeToEmotion,
          CallbackAnimationTypes.end
        )
        this.athlete.isLunge = false

      }
    )
    this.athlete.animationsManager.changeTo(PlayerAnimationsNames.lungeToEmotion)

  }

  /**
   * Start V3 skatingu
   */
  private startStartRunAnimation(): void {

    this.athlete.animationsManager.crossfadeTo(
      PlayerAnimationsNames.startRun,
      playerAnimationConfig.defaultCrossfadeTime,
      true,
      false
    )

  }

  /**
   * Updatovanie casu animacie zmeny drahy
   */
  private updateChangingPathAnimation(): void {

    const { framesToChange } = pathsConfig.changePathConfig

    // musime si zaznamenat animaciu pred zmenou drahy
    if (!this.changePathAnimations.includes(this.animationRunning)) {

      this.animationBeforeChangePath = this.animationRunning

    }
    this.setInternalCrossfade(this.changingPathAnimation, 3)

    const { changePathTransitionFrames } = this.athlete.hillLinesManager
    const time = (framesToChange - changePathTransitionFrames) / framesToChange * 100
    this.athlete.animationsManager.manualyUpdateTimeByPercent(this.changingPathAnimation, time)

    // ked je koniec prechodu, tj 100%
    if (time >= 100) {

      this.setInternalCrossfade(this.animationBeforeChangePath, 3)

      const runningPhase = disciplinePhasesManager
        .getDisciplinePhaseManager(DisciplinePhases.running) as RunningPhase
      runningPhase.switchLaneBlocker = false
      this.athlete.isChangingPath = false

      if (this.athlete.playable) {

        tutorialFlow.eventActionTrigger(TutorialEventType.changePathDone)

      }

    }

  }

  /**
   * Spustenie animacie sprintu
   */
  private startStrideGlideAnimation(): void {

    this.setInternalCrossfade(PlayerAnimationsNames.strideGlide, 3)

  }

  /**
   * Spustenie animacie tucku
   */
  private startDownhillAnimation(): void {

    this.setInternalCrossfade(PlayerAnimationsNames.downhill, 15, false)

  }

  /**
   * Start animacie V1
   */
  private startDoublePolingAnimation(): void {

    this.setInternalCrossfade(PlayerAnimationsNames.doublePoling, 3)

  }

  /**
   * Start animacie glidePoling
   */
  private startGlidePolingAnimation(): void {

    this.setInternalCrossfade(PlayerAnimationsNames.glidePoling, 3)

  }

  /**
   * Start animacie prepare
   * @param crossfadeTime - Cas, za ktory sa vykona crossfade
   */
  private startPrepareAnimation(crossfadeTime = playerAnimationConfig.defaultCrossfadeTime): void {

    console.log('prepare anim', this.athlete.uuid)

    const animationName = Math.random() < 0.5 ?
      PlayerAnimationsNames.prepare :
      PlayerAnimationsNames.prepare2
    this.athlete.animationsManager.crossfadeTo(
      animationName,
      crossfadeTime,
      true,
      false
    )

  }

  /**
   * Start animacie prestart
   * @param crossfadeTime - Cas, za ktory sa vykona crossfade
   */
  private startPositionAnimation(crossfadeTime = playerAnimationConfig.defaultCrossfadeTime): void {

    console.log('start position anim', this.athlete.uuid)

    this.athlete.animationsManager.crossfadeTo(
      PlayerAnimationsNames.startPosition,
      crossfadeTime,
      true,
      false
    )

  }

  /**
   * Start animacie stop1
   * @param crossfadeTime - Cas, za ktory sa vykona crossfade
   */
  private startStopAnimation(crossfadeTime = playerAnimationConfig.defaultCrossfadeTime): void {

    const animationName = Math.random() < 0.5 ?
      PlayerAnimationsNames.stop1 :
      PlayerAnimationsNames.stop2

    this.athlete.animationsManager.addAnimationCallback(
      animationName,
      CallbackAnimationTypes.loop,
      () => {

        this.athlete.animationsManager.removeAnimationCallback(
          animationName,
          CallbackAnimationTypes.loop
        )
        this.startFinalAnimation(finishPhaseConfig.crossfadeTimeFinish)

      }
    )

    this.internalCrossfadesManager.disable(this.animationRunning)

    this.athlete.animationsManager.crossfadeTo(
      animationName,
      crossfadeTime,
      true,
      false
    )

  }

  /**
   * Start animacie final
   * @param crossfadeTime - Cas, za ktory sa vykona crossfade
   */
  private startFinalAnimation(crossfadeTime = playerAnimationConfig.defaultCrossfadeTime): void {

    const { nothing, finishAnimation } = finishPhaseConfig.finishAnimationsProbabilities
    if (Math.random() < nothing) return

    const animationName = Math.random() < finishAnimation ?
      PlayerAnimationsNames.final1 :
      PlayerAnimationsNames.final2

    this.athlete.animationsManager.crossfadeTo(
      animationName,
      crossfadeTime,
      true,
      false
    )

  }

  /**
   * Zmena rychlosti animacii
   * @param speed - Nova rychlost animacii
   */
  private changeAnimationSpeed(speed: number) {

    this.athlete.animationsManager.setSpeed(speed)

  }

  /**
   * Reset rychlosti animacii
   */
  private resetAnimationSpeed() {

    this.athlete.animationsManager.resetSpeed()

  }

  /**
   * Spustenie animacie downhill pre rovny smer
   */
  private downhillForward(): void {

    if (
      this.animationRunning !== PlayerAnimationsNames.downhill &&
            !this.athlete.isChangingPath
    ) {

      this.startDownhillAnimation()

    }

  }

  /**
   * Riesenie veci pre downhill animacie
   */
  private downhillSituation(): void {

    if (this.athlete.isTuck) {

      if (this.animationRunning === PlayerAnimationsNames.strideGlide) this.resetAnimationSpeed()
      // console.log('Starting Downhill Animation')

      if (this.athlete.playable) audioHelper.playMovementAudio(AudioNames.skiingGlide)

      this.downhillForward()

    }

  }

  /**
   * Riesenie veci pre sprint
   */
  private sprintSituation(): void {

    if (
      this.athlete.isSprinting
    ) {

      if (
        this.animationRunning === PlayerAnimationsNames.downhill ||
                this.animationRunning === PlayerAnimationsNames.downhillLeft ||
                this.animationRunning === PlayerAnimationsNames.downhillRight
      ) {

        this.resetAnimationSpeed()

      }
      // console.log('Starting Sprint Animation')
      if (this.athlete.isUphillEnabled()) {

        this.changeAnimationSpeed(playerAnimationConfig.animationSpeedForSprint)
        this.startStrideGlideAnimation()

      } else {

        this.startDoublePolingAnimation(/* true */)

      }

    }

  }

  /**
   * Riesenie veci pre klasicky beh
   * @returns True, ak ide o neutralnu situaciu
   */
  private neutralSituation(): boolean {

    if (
      !this.athlete.isTuck &&
            !this.athlete.isSprinting &&
            !this.athlete.setStartSkating
    ) {

      this.setRunningAnimationFromDownhillSituation()
      return true

    } else {

      return false

    }

  }

  /**
   * Nastavenie animacii pre downhill
   */
  private setRunningAnimationFromDownhillSituation(): void {

    if (this.animationRunning !== PlayerAnimationsNames.glidePoling) {

      // console.log('Starting V2 Animation')
      if (this.internalCrossfadesManager.isActive()) this.resetAnimationSpeed()
      this.startGlidePolingAnimation()

    }


  }

  /**
   * Riesenie veci pre specialne situacie
   * @returns True, ak ide o specialnu situaciu
   */
  private specialSituation(): boolean {

    const isGameStart = this.isGameStartState()
    const isStarting = this.isStartingState()
    const isStartRun = this.isStartRunState()
    const isSkating = this.isSkatingState()
    const isLunge = this.isLungeState()
    const isEnd = this.isEndState()
    const isChangingPath = this.isChangingPath()

    return isGameStart || isStarting || isStartRun || isSkating || isLunge || isEnd ||
            isChangingPath

  }

  /**
   * Riesenie veci pre changingPath stav
   * @returns True, ak sa meni draha
   */
  private isChangingPath(): boolean {

    if (!this.athlete.isChangingPath) return false

    this.updateChangingPathAnimation()

    return true

  }

  /**
   * Riesenie veci pre game start stav
   * @returns True, ak ide o dany stav
   */
  private isGameStartState(): boolean {

    if (disciplinePhasesManager.getActualPhase() === DisciplinePhases.preStart) {

      if (!this.prepareAnimationSet) {

        this.prepareAnimationSet = true
        this.startPrepareAnimation()

      }

      return true

    }

    if (disciplinePhasesManager.getActualPhase() === DisciplinePhases.start) {

      const discipline = disciplinePhasesManager
        .getDisciplinePhaseManager(DisciplinePhases.start) as StartPhaseManager

      if (discipline.getCameraInPostIntroState() &&
                this.animationRunning !== PlayerAnimationsNames.startPosition
      ) {

        console.log('ideme start pos anim in start phase', this.athlete.uuid)
        this.startPositionAnimation()
        this.animationRunning = PlayerAnimationsNames.startPosition

      }

      return true

    } else {

      return false

    }
    /*
     * return (disciplinePhasesManager.getActualPhase() === DisciplinePhases.preStart ||
     * disciplinePhasesManager.getActualPhase() === DisciplinePhases.start)
     */

  }

  /**
   * Riesenie veci pre starting stav
   * @returns True, ak ideo o dany stav
   */
  private isStartingState(): boolean {

    if (!this.startingStateActive && this.athlete.isStarting) {

      this.startingStateActive = true

      this.athlete.animationsManager.addAnimationCallback(
        PlayerAnimationsNames.start,
        CallbackAnimationTypes.end,
        () => {

          console.log('SKONCIL STATE STARTING, ideme na start run', this.athlete.uuid)
          /*
           * console.log('START ANIMATION -- end')
           * this.startSkatingAnimation()
           */
          this.athlete.isStarting = false
          this.athlete.isStartRun = true
          this.startingStateActive = false

          this.athlete.animationsManager.removeAnimationCallback(
            PlayerAnimationsNames.start,
            CallbackAnimationTypes.end
          )

        }
      )

      this.changeAnimationSpeed(playerAnimationConfig.animationSpeedForSprint)
      this.startAnimation()
      // audioHelper.playMovementAudio(AudioNames.runFastpace)

    }

    return this.athlete.isStarting

  }

  /**
   * Riesenie veci pre start run stav
   * @returns True, ak ideo o dany stav
   */
  private isStartRunState(): boolean {

    if (!this.startRunStateActive && this.athlete.isStartRun) {

      this.startRunStateActive = true
      console.log('nastavili sme state start run, cakame na koniec', this.athlete.uuid)

      this.changeAnimationSpeed(playerAnimationConfig.animationSpeedForSkating)
      this.startStartRunAnimation()

    }

    return this.athlete.isStartRun

  }

  /**
   * Riesenie veci pre skating stav
   * @returns True, ak ideo o dany stav
   */
  private isSkatingState(): boolean {

    if (!this.skatingStateActive && this.athlete.setStartSkating) {

      this.skatingStateActive = true

      // console.log('nastavili sme start skating a davame v2', this.athlete.uuid)
      this.athlete.activeUpdatingMovementAnimations = true
      this.resetAnimationSpeed()

      this.athlete.animationsManager.addAnimationCallback(
        PlayerAnimationsNames.glidePoling,
        CallbackAnimationTypes.crossfade,
        () => {

          this.athlete.animationsManager.removeAnimationCallback(
            PlayerAnimationsNames.glidePoling,
            CallbackAnimationTypes.crossfade
          )

          this.internalCrossfadesManager.enable()
          this.athlete.setStartSkating = false

          this.setInternalCrossfade(PlayerAnimationsNames.glidePoling, 1, false)

        }
      )

      // tuto musime spravit crossfade maly, aby vsetko na zaciatku fungovalo
      this.athlete.animationsManager.crossfadeTo(
        PlayerAnimationsNames.glidePoling,
        0.02,
        true,
        false
      )

    }

    return this.athlete.setStartSkating

  }

  /**
   * Riesenie veci pre lunge stav
   * @returns True, ak ide o dany stav
   */
  private isLungeState(): boolean {

    if (!this.lungeStateActive && this.athlete.isLunge) {

      this.lungeStateActive = true
      this.startLungeAnimation()

    }

    if (this.lungeAnimationEnded) {

      if (this.lungeStateFrames >= finishPhaseConfig.waitAfterLungeFrames) {

        this.startLungeToEmotionAnimation()

      } else {

        this.lungeStateFrames += 1

      }

    }

    return this.athlete.isLunge

  }

  /**
   * Riesenie veci pre end stav
   * @returns True, ak ide o dany stav
   */
  private isEndState(): boolean {

    if (
      !this.endStateActive &&
            this.athlete.isEnd &&
            !this.athlete.isLunge
    ) {

      this.endStateActive = true

      const emotionAnimation = modes.isTrainingMode() ?
        this.getEndEmotionTraining() :
        this.getEndEmotion()
      this.actualAnimation = emotionAnimation

      this.athlete.animationsManager.addAnimationCallback(
        emotionAnimation,
        CallbackAnimationTypes.end,
        () => {

          // this.endStateActive = false

          this.athlete.animationsManager.removeAnimationCallback(
            emotionAnimation,
            CallbackAnimationTypes.end
          )

          this.athlete.animationsManager.resetSpeed()
          this.startStopAnimation(0.2)

        }
      )

      this.resetAnimationSpeed()

      if (emotionAnimation === PlayerAnimationsNames.neutral) this.startNeutralAnimation()
      if (emotionAnimation === PlayerAnimationsNames.happy) this.startHappyAnimation()

    }

    return this.athlete.isEnd

  }

  /**
   * Samotna logika
   */
  private animationLogic(): void {

    const isSpecialSituation = this.specialSituation()

    if (!isSpecialSituation) {

      const isNeutralState = this.neutralSituation()

      if (!isNeutralState) {

        this.sprintSituation()
        this.downhillSituation()

      }

    }

  }

  /**
   * Update metoda volana v move metode velocity manazera
   */
  public update(): void {

    this.animationLogic()

    // aplikujeme zmenu vah pre interne crossfady
    this.internalCrossfadesManager.update()

  }

  /**
   * Vratenie konecnej emocie pre trening
   * @returns Emocia
   */
  private getEndEmotionTraining = (): PlayerAnimationsNames => {

    this.isEndEmotionSet = true

    if (!this.athlete.playable) return PlayerAnimationsNames.neutral

    const tasks = trainingManager.getTrainingTasks()
    const sum = tasks.reduce((prev, current) => prev + current.value, 0)
    const average = sum / tasks.length

    let emotion = PlayerAnimationsNames.neutral
    if (average > 0.9) emotion = PlayerAnimationsNames.happy

    return emotion

  }

  /**
   * Vratenie konecnej emocie
   * @returns Emocia
   */
  private getEndEmotion = (): PlayerAnimationsNames => {

    this.isEndEmotionSet = true

    const time = playersManager.players[0].resultsArr?.[
      corePhasesManager.disciplineActualAttempt - 1
    ].main
    const player = playersManager.getPlayerById(this.athlete.uuid)

    if (
      player &&
      (playersManager.getPlayerActualPosition(this.athlete.uuid) <= 3 ||
      (time !== undefined && time <= player.personalBest))
    ) {

      return PlayerAnimationsNames.happy

    }

    return PlayerAnimationsNames.neutral

  }

  /**
   * Zresetovanie
   */
  public reset(): void {

    this.animationRunning = PlayerAnimationsNames.doublePoling
    this.prepareAnimationSet = false
    this.startingStateActive = false
    this.startRunStateActive = false
    this.skatingStateActive = false
    this.endStateActive = false
    this.lungeStateActive = false
    this.actualAnimation = undefined
    this.isEndEmotionSet = false
    this.changingPathAnimation = PlayerAnimationsNames.changeL
    this.lungeAnimationEnded = false
    this.lungeStateFrames = 0
    this.internalCrossfadesManager.reset()

  }

}
