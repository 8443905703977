/**
 * Mena sekcie
 */
export enum SectionNames {
  handCheck = 'handCheck',
  startSection = 'startSection',
  startSectionSecond = 'startSectionSecond',
  startSectionThird = 'startSectionThird',
  afterRunUpFirst = 'afterRunUpFirst',
  learningEvents = 'learningEvents',
  endSection = 'endSection'
}

type NameKeys = keyof typeof SectionNames;
type NameKeyFields = { [key in NameKeys]: string }

export interface UiState extends NameKeyFields {
  NameKeyFields: () => void
}

export enum TutorialEventType {
  awaitingEvent = 'awaitingEvent',
  failedStart = 'failedStart',
  goodStart = 'goodStart',
  changePathTimeout = 'changePathTimeout',
  changePathSuccess = 'changePathSuccess',
  changePathDone = 'changePathDone',
  pathBlocked = 'pathBlocked',
  forcedChangePath = 'forcedChangePath',
  slipStream = 'slipStream',
  slipStreamSuccess = 'slipStreamSuccess',
  finish = 'finish',
}

export enum TutorialObjectiveIds {
  start = 'tutorialTask8-1',
  changePath = 'tutorialTask8-2',
  slipStream = 'tutorialTask8-3'
}
