import { player } from '../../entities/player'
import {
  AudioNames,
  AudioGroups,
  MovementInFinishPhases,
  type DisciplinePhaseManager,
  PlayerAnimationsNames
} from '../../types'
import store from '@/store'
import {
  finishPhaseConfig,
  opponentConfig
} from '../../config'
import {
  timeManager,
  playersManager,
  THREE,
  fpsManager,
  cameraManager,
  CameraStates,
  modes,
  corePhasesManager,
  gsap,
  audioManager,
  TimesTypes,
  minigameConfig,
  trainingManager
} from '@powerplay/core-minigames'
import { endManager } from '@/app/EndManager'
import { inputsManager } from '@/app/InputsManager'
import { audioHelper } from '@/app/audioHelper/AudioHelper'
import { opponentsManager } from '@/app/entities/opponent/OpponentsManager'
import { trainingTasks } from '@/app/modes/training'

/**
 * Trieda fazy pre dojazd v cieli (resp naburanie)
 */
export class FinishPhaseManager implements DisciplinePhaseManager {

  /** pocet zle prejdenych branok */
  #successfulGates = 0

  /** callback na zavolanie po skonceni fazy */
  private callbackEnd: () => unknown

  /** tween na ukoncenie fazy po animacii */
  private finishPhaseTween !: gsap.core.Tween

  /** ci faza skoncila */
  private ended = false

  /** kolko framov preslo od zaciatku fazy */
  private framesInPhase = 0

  /** kolko framov preslo od zaciatku podfazy movementu */
  private framesInLocalPhase = 0

  /** audio divakov podla emocie */
  private audienceAudio !: AudioNames

  /** Aktualna rychlost pohybu */
  private actualSpeed = 0

  /** Aktualny step v linearnej faze pohybu */
  private linearDecreaseStep = 0

  /** Aktualna faza pohybu v cieli */
  private movementInFinishPhase = MovementInFinishPhases.before

  /** pole objektov faz pohybu v cieli */
  private movementPhasesUpdates: (() => unknown)[] = []

  /** tween na zmenu UI stavu */
  private changeUiStateTween!: gsap.core.Tween

  /** Tween na hlasitost v cieli */
  private volumeEndTween!: gsap.core.Tween

  /** ci je mozne skipnut */
  private SKIPPABLE = true

  /** kolko po starte mame zobrazit finish top box */
  private SHOW_FINISH_TOP_BOX_SECONDS = 2

  /** Freeznutie zaznamenavania superov pri dennej lige, lebo pouzivame fake superov */
  public dailyLeagueSetResultsOpponentsFreeze = false

  /**
   * Konstruktor
   */
  public constructor(callbackEnd: () => unknown) {

    this.callbackEnd = callbackEnd

  }

  /**
   * getter
   */
  public get successfulGates(): number {

    return this.#successfulGates

  }

  /**
   * setter
   */
  public set successfulGates(newValue: number) {

    this.#successfulGates = newValue

  }

  /**
   * Pripravenie fazy
   */
  public preparePhase = (): void => {

    // nastavime fazy pohybu
    this.movementPhasesUpdates[MovementInFinishPhases.setThings] = this.moveInFinishSetThings
    this.movementPhasesUpdates[MovementInFinishPhases.nonLinearDecrease] =
            this.moveInFinishNonLinear
    this.movementPhasesUpdates[MovementInFinishPhases.linearDecrease] = this.moveInFinishLinear
    this.movementPhasesUpdates[MovementInFinishPhases.stay] = this.moveInFinishStay

  }

  /**
   * Start fazy
   */
  public startPhase = (): void => {

    if (modes.isTutorial()) return

    fpsManager.pauseCounting()

    audioManager.stopAudioByName(AudioNames.heartbeat)
    audioManager.stopAudioByName(AudioNames.heavyBreathing)

    this.reset()
    this.preparePhase()

    this.setDnfForOpponents()
    player.finishTime = timeManager.getGameTimeWithPenaltyInSeconds(false, undefined, 1)
    playersManager.setPlayerResults(player.finishTime)

    console.log(
      'Finalny cas',
      player.finishTime,
      `, bez randomu ${timeManager.getGameTimeWithPenaltyInSeconds(false, undefined, 1)}`
    )

    playersManager.setStandings(1)
    console.log('STANDINGS', playersManager.getStandings())
    store.commit('TableState/SET_DATA', playersManager.getStandings())

    cameraManager.setState(CameraStates.disciplineOutro)
    cameraManager.playTween()

    console.warn('finish phase started')
    store.commit('InputsState/SET_VISIBLE', false)
    store.commit('HeartRateState/SET_VISIBLE', false)
    store.commit('GamePhaseState/SET_SMALL_ACTION', false)

    player.finishAction()

    this.actualSpeed = player.speedManager.getActualSpeed()
    console.log(`Finish speed ${this.actualSpeed}`)

    this.movementInFinishPhase = MovementInFinishPhases.nonLinearDecrease

    store.commit('UiState/SET_STATE', {
      showTimeKeeper: false,
      showSplitTimes: true,
      showFinishTopBox: false,
      showTrainingLayout: modes.isTrainingMode(),
      isTraining: modes.isTrainingMode()
    })

    let duration = this.SHOW_FINISH_TOP_BOX_SECONDS
    if (modes.isTrainingMode()) duration = 0
    this.changeUiStateTween = gsap.to({}, {
      duration,
      onComplete: () => {

        if (modes.isTrainingMode()) {

          store.commit('TrainingState/SET_HIGH_SCORE', {
            newHighScore: Math.ceil(trainingManager.getNewPotentialHighScore()),
            showNewHighScore: trainingManager.isNewHighScore()
          })

        }
        store.commit('UiState/SET_STATE', {
          showTimeKeeper: false,
          showSplitTimes: true,
          showFinishTopBox: (!modes.isTutorial() && !modes.isTrainingMode()),
          showTrainingLayout: modes.isTrainingMode(),
          isTraining: modes.isTrainingMode()
        })
        this.setFinishTopBoxData()

      }
    })

    // data pre tabulku
    if (!modes.isTrainingMode()) {

      this.setDataForPositionsTable()
      store.commit(
        'GameplayTableState/SET_TABLES_VISIBILITY',
        {
          showTables: true,
          showLeftTable: true,
          showRightTable: true
        }
      )

    }

    this.playCommentatorAudio()

  }

  /**
   * Musime protihracom, ktori nepresli cielom dat dnf kvoli vyhodnocovaniu pozicie
   */
  private setDnfForOpponents() {

    const opponents = opponentsManager.getOpponents()
    opponents.forEach(opponent => {

      if (!opponent.isEnd) {

        playersManager.setPlayerResultsById(opponent.uuid, minigameConfig.dnfValue)

      }

    })

  }

  /**
   * nastavime data pre top box
   */
  private setFinishTopBoxData(): void {

    if (modes.isDailyLeague() && !playersManager.isPlayerImproved()) return

    const personalBest = playersManager.getPlayer().personalBest
    const timeFormat = timeManager.getTimeInFormatFromSeconds(player.finishTime, 1)
    const position = modes.isTrainingMode() ?
      trainingTasks.position + 1 :
      playersManager.getPlayerActualPosition(player.uuid)

    console.log(`Finish phase TrainingTasks position: ${trainingTasks.position
    }, playersManager position: ${playersManager.getPlayerActualPosition(player.uuid)
    }`)
    const showFirstBox = position < 4
    const showSecondBox = player.finishTime <= personalBest

    store.commit('FinishTopBoxState/SET_STATE', {
      showFirstBox: showFirstBox,
      showSecondBox: showSecondBox,
      firstPlace: position === 1,
      personalBest: player.finishTime === personalBest,
      newPersonalBest: player.finishTime < personalBest,
      time: timeFormat,
      position: position
    })

  }

  /**
   * pustime komentatora
   */
  private playCommentatorAudio(): void {

    const pos = modes.isTrainingMode() ?
      trainingTasks.position + 1 :
      playersManager.getPlayerActualPosition(player.uuid)

    let audio = AudioNames.commentFinish4

    if (pos === 1) {

      audio = AudioNames.commentFinish1

    } else if (pos <= 3) {

      audio = AudioNames.commentFinish2

    } else if (pos <= (modes.isDailyLeague() || modes.isBossCompetition() ? 10 : 5)) {

      audio = AudioNames.commentFinish3

    }

    audioManager.stopAudioByGroup(AudioGroups.commentators)
    audioManager.play(audio)

  }

  /**
   * zapneme audience audio
   */
  private startAudienceAudio(): void {

    audioManager.stopAudioByName(AudioNames.audienceHype)

    this.audienceAudio = AudioNames.audienceSad

    if (player.athleteAnimationManager.actualAnimation === PlayerAnimationsNames.happy) {

      this.audienceAudio = AudioNames.audienceYay

    }
    audioManager.play(this.audienceAudio)
    audioManager.changeAudioVolume(this.audienceAudio, 1/* this.audienceVolume */)

  }

  /**
   * naplnime tabulku pozicii okolo hraca a cas hraca
   */
  public setDataForPositionsTable(): void {

    const data = playersManager.getSimilarPositionPlayersForTable(1)
    const tableData = []
    let playerResultString = ''

    for (let i = 0; i < data.length; i++) {

      if (data[i] === undefined) continue

      tableData.push({
        position: data[i].position,
        country: data[i].country,
        countryString: data[i].countryString,
        player: {
          name: data[i].name,
          isPlayer: data[i].playable
        },
        time: data[i].result,
        timeDiff: data[i].result,
        isBonus: false
      })

      if (data[i].playable) {

        playerResultString = data[i].result || ''

      }

    }
    store.commit(
      'GameplayTableState/SET_TABLE_DATA',
      tableData
    )

    const playerData = playersManager.getPlayer()

    store.commit('GameplayTableState/SET_PLAYER_DATA', {
      position: modes.isTrainingMode() ?
        trainingTasks.position + 1 :
        playersManager.getPlayerActualPosition(player.uuid),
      country: playerData.country,
      countryString: playerData.countryString,
      player: {
        name: playerData.name,
        isPlayer: true
      },
      time: timeManager.getTimeInFormatFromSeconds(
        playerData.resultsArr?.[corePhasesManager.disciplineActualAttempt - 1].main || 0,
        1
      ),
      timeDiff: playerResultString,
      isBonus: !playerResultString.includes('+')
    })

  }

  /**
   * Hybanie sa v cieli - faza nastavenie veci
   */
  private moveInFinishSetThings = (): void => {

    const playerPosition = player.getPosition()

    player.athleteObject.lookAt(new THREE.Vector3(
      playerPosition.x,
      playerPosition.y,
      playerPosition.z + 2
    ))

    this.movementInFinishPhase = MovementInFinishPhases.nonLinearDecrease

  }

  /**
   * Hybanie sa v cieli - faza nelinearny pohyb
   */
  private moveInFinishNonLinear = (): void => {

    this.framesInLocalPhase++

    const { coef, everyXFrames, frames } = finishPhaseConfig.nonLinearDecreasingSpeed

    // kazdych x frameov menime rychlost
    if (this.framesInLocalPhase % everyXFrames === 1) this.actualSpeed *= coef

    // check konca
    if (this.framesInLocalPhase === frames) {

      this.movementInFinishPhase = MovementInFinishPhases.linearDecrease
      this.framesInLocalPhase = 0
      this.linearDecreaseStep = this.actualSpeed /
                finishPhaseConfig.linearDecreasingSpeed.frames

    }

  }

  /**
   * Hybanie sa v cieli - faza linearny pohyb
   */
  private moveInFinishLinear = (): void => {

    this.framesInLocalPhase++

    this.actualSpeed -= this.linearDecreaseStep
    if (this.actualSpeed < 0) this.actualSpeed = 0

    // check konca
    if (this.framesInLocalPhase === finishPhaseConfig.linearDecreasingSpeed.frames) {

      this.movementInFinishPhase = MovementInFinishPhases.stay
      this.framesInLocalPhase = 0

    }

  }

  /**
   * Hybanie sa v cieli - faza statie
   */
  private moveInFinishStay = (): void => {

    this.framesInLocalPhase++

    if (this.framesInLocalPhase === finishPhaseConfig.stayAtEndFrames) {

      this.setFinishPhaseTween()

    }

  }

  /**
   * Pohyb v cieli
   */
  private moveInFinish(): void {

    this.framesInPhase++

    // po x frameoch nastavujeme animaciu konca
    if (this.framesInPhase === finishPhaseConfig.startAnimationEndAfterFrames) {

      // finishPhase.setFinishPhaseTween()
      audioHelper.playMovementAudio(AudioNames.skiingBreak)

    }

    // update aktualnej fazy
    this.movementPhasesUpdates[this.movementInFinishPhase]?.()

  }

  /**
   * Aktualizovanie fazy
   */
  public update = (): void => {

    this.moveInFinish()
    this.skipPhase()

    if (player.athleteAnimationManager.isEndEmotionSet && !this.audienceAudio) {

      this.startAudienceAudio()

    }

  }

  /**
   * Zobrazenie finalnej tabulky
   */
  private prepareFinishTable() {

    this.generateFakeOpponentsData()
    timeManager.setActive(TimesTypes.game, false)
    // data pre tabulku
    playersManager.setStandings(1)
    console.log('STANDINGS', playersManager.getStandings())
    store.commit('TableState/SET_DATA', playersManager.getStandings())

  }

  /**
   * Vygenerovanie casy pre hracov ktori nestihli prejst cielom
   */
  private generateFakeOpponentsData(): void {

    if (modes.isDailyLeague()) {

      // musime nastavit, aby sa uz ziadne data nezapisovali dalsie
      this.dailyLeagueSetResultsOpponentsFreeze = true

      // kvoli dennej lige musime dat naspat originalnych superov, aby sa zobrazili v konecnej listine
      opponentsManager.setOriginalData()
      return

    }
    const opponents = opponentsManager.getOpponents()
    const lastTimeSet = timeManager.getGameTimeWithPenaltyInSeconds(false, undefined, 1)

    opponents.forEach(opponent => {

      if (!opponent.isEnd) {

        const metersToFinish = (1 - opponent.hillLinesManager.getActualPercent()) /
                    opponent.hillLinesManager.oneMeterInPercent
        const finishTime = lastTimeSet + metersToFinish / opponentConfig.fakeTimeMetersDivider
        playersManager.setPlayerResultsById(opponent.uuid, finishTime)

      }

    })

  }

  /**
   * skipnutie fazy
   */
  public skipPhase(): void {

    if (!this.SKIPPABLE || !inputsManager.actionPressed) return

    this.finishPhase()

  }

  /**
   * Ukoncene fazy
   * @param type - Typ ukoncenia
   */
  public finishPhase = (): void => {

    this.prepareFinishTable()
    if (this.ended) return
    this.ended = true

    if (this.volumeEndTween) this.volumeEndTween.kill()
    if (this.finishPhaseTween) this.finishPhaseTween.kill()
    if (this.changeUiStateTween) this.changeUiStateTween.kill()

    store.commit(
      'GameplayTableState/SET_TABLES_VISIBILITY',
      {
        showTables: false
      }
    )

    store.commit('UiState/SET_STATE', {
      showTimeKeeper: false,
      showSplitTimes: false,
      showFinishTopBox: (!modes.isTutorial() && !modes.isTrainingMode()),
      showTrainingLayout: false,
      isTraining: modes.isTrainingMode()
    })

    fpsManager.pauseCounting()
    endManager.sendLogEnd()
    endManager.sendSaveResult()

    console.warn('finish phase ended')
    this.callbackEnd()

  }

  /**
   * sets tween to finish phase
   */
  public setFinishPhaseTween(): void {

    this.finishPhase()

  }

  /**
   * reset fazy
   */
  public reset(): void {

    this.successfulGates = 0
    this.ended = false
    this.movementInFinishPhase = MovementInFinishPhases.before
    if (this.changeUiStateTween) this.changeUiStateTween.kill()

  }

}
