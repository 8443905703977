<template>
  <div
    v-show="inSlipStream && !hideSlipStreamWarning"
    class="slip-stream-warning"
  >
    <blue-box
      :style="blueBoxStyle"
    >
      <span class="slipstream-icon" />
      <span class="slipstream-text">{{ $t('slipStream') }}</span>
      <span class="slipstream-text slipstream-seconds">{{ slipStreamSeconds }}</span>
    </blue-box>
  </div>
</template>

<script lang="ts">
/* eslint-disable max-len */
import {
  BlueBox,
  WindowAspect
} from '@powerplay/core-minigames-ui'
import { mapGetters } from 'vuex'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'SlipStreamWarning',
  components: {
    BlueBox
  },
  mixins: [WindowAspect],
  data() {

    return {
      blueBoxStyle: {
        left: '50%',
        top: '45%',
        width: '840px',
        height: '80px',
        position: 'absolute',
        'flex-direction': 'row'
      }
    }

  },
  computed: {
    ...mapGetters({
      slipStreamSeconds: 'UiState/getSlipStreamSeconds',
      inSlipStream: 'UiState/getInSlipStream',
      hideSlipStreamWarning: 'UiState/getHideSlipStreamWarning'
    })
  },
  created() {

    if (this.isMobile()) {

      this.blueBoxStyle.position = 'fixed'

    }

  }
})

</script>

<style lang="less" scoped>
.slip-stream-warning{
    .slipstream-icon {
        width: 80px;
        height: 80px;
        background-image: url('https://appspowerplaymanager.vshcdn.net/images/winter-sports/minigame/core-ui/icons/slipstream-ico.png');
        background-size: cover;
    }
    .slipstream-text {
        text-shadow: 1.1px 1.7px 1px rgba(0, 0, 0, 0.46);
        font-family: Roboto;
        font-size: 48px;
        font-stretch: condensed;
        font-style: italic;
        text-align: center;
        color: #fff;
        text-transform: uppercase;
    }
    .slipstream-seconds {
        font-weight: bold;
        color: #fadd1e;
        padding-left: 20px;
    }
}
</style>
