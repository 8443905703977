import { audioGameConfig } from '@/app/config'
import { heartRateConfig } from '@/app/config/heartRateConfig'
import { disciplinePhasesManager } from '@/app/phases/DisciplinePhasesManager'
import {
  AudioNames,
  DisciplinePhases
} from '@/app/types'
import store from '@/store'
import {
  audioManager,
  fpsManager
} from '@powerplay/core-minigames'
import type { Athlete } from './Athlete'

/** Klassa na heart rate manager */
export class HeartRateManager {

  /** Srdcovy tep - ta hodnota ako rychlo bije srdce: https://en.wikipedia.org/wiki/Heart_rate */
  private heartRate = heartRateConfig.minRate

  /** Specificka hodnota ktoru vyhodnocujeme pre rychlostny bar */
  private speedBarHeartRate = 120

  /** FrameRate counter */
  private frameOfGame = 0

  /** Zmena heart rate */
  private heartRateChange = 0

  /** Ziskanie heart rateu na pouzitie pre ine moduly */
  public getHeartRate(): number {

    return Math.floor(this.heartRate)

  }

  /**
   * Setter
   * @param value - hodnota na aku setnut
   */
  public setHeartRate(value: number): this {

    this.heartRate = value
    return this

  }

  /**
   * Pridavanie hodnoty ku aktualnemu tepu
   * @param value - hodnota o aku zvysit
   * @param athlete - atlet ktoremu zvysujeme
   */
  public addHeartRate(value: number, athlete: Athlete): void {

    const maxHeartRate = athlete.isSprinting ?
      heartRateConfig.maxRate.sprinting :
      heartRateConfig.maxRate.skating
    this.heartRate += value
    if (this.heartRate > maxHeartRate) this.heartRate = maxHeartRate

  }

  /** jedna sekunda */
  private oneSecPassed(): boolean {

    this.frameOfGame++
    return this.frameOfGame % fpsManager.fpsLimit === 0

  }

  /**
   * Zistenie ci hra este neodstartovala
   * @returns True, ak neostartovala este hra
   */
  private gameNotStarted(): boolean {

    return disciplinePhasesManager.oneOfPhaseIsActual([
      DisciplinePhases.start,
      DisciplinePhases.preStart,
      DisciplinePhases.end,
      DisciplinePhases.finish
    ])

  }

  /**
   * Kalkulacia heartrateu v sprinte
   * @param athlete - atlet
   */
  private calculateSprintingHeartRate(athlete: Athlete): void {

    if (athlete.isSprinting) this.heartRateChange = heartRateConfig.sprintPhaseAdd

  }

  /**
   * Kalkulacia heartrateu v couchingu
   * @param athlete - atlet
   */
  private calculateDownhillHeartRate(athlete: Athlete): void {

    if (athlete.isTuck) this.heartRateChange = heartRateConfig.downhillPhaseRemove

  }

  /**
   * Vypocet heartratebaru
   * @param athlete - atlet
   */
  private calculateSpeedBarHeartRate(athlete: Athlete): void {

    const { slipStreamBPM } = heartRateConfig
    const BPMBase = athlete.velocityManager.getSpeedPowerState() *
            (athlete.speedManager.getActualSpeed() / athlete.velocityManager.getMaxSpeed())

    if (athlete.playable) {

      store.commit('MainState/SET_BPMB', BPMBase)

    }
    this.speedBarHeartRate =
            BPMBase +
            190 -
            athlete.getSpeedBarMaxValue() -
            (athlete.isInSlipStream ? slipStreamBPM.is : slipStreamBPM.not)

  }

  /**
   * Prisposobenie hodnoty k heartratebaru
   * @param athlete - atlet
   */
  private calculateRunningHeartRate(athlete: Athlete) {

    this.calculateSpeedBarHeartRate(athlete)

    const { runningPhaseRemove, runningPhaseAdd } = heartRateConfig

    if (this.speedBarHeartRate < this.heartRate) {

      this.heartRateChange = this.speedBarHeartRate - this.heartRate <= runningPhaseRemove ?
        runningPhaseRemove :
        this.heartRate - this.speedBarHeartRate

    } else if (this.speedBarHeartRate > this.heartRate) {

      this.heartRateChange = this.speedBarHeartRate - this.heartRate >= runningPhaseAdd ?
        runningPhaseAdd :
        this.heartRate - this.speedBarHeartRate

    }

  }

  /**
   * Zmena hnodty tepu
   * @param athlete - atlet
   * @param minHeartRate - minimalnyy tep
   * @param maxHeartRate - maximalny tep
   */
  private changeHeartRate(athlete: Athlete): void {

    const maxHeartRate = athlete.isSprinting ?
      heartRateConfig.maxRate.sprinting :
      heartRateConfig.maxRate.skating
    this.heartRate += this.heartRateChange

    if (this.heartRate < heartRateConfig.minRate) this.heartRate = heartRateConfig.minRate
    if (this.heartRate > maxHeartRate) this.heartRate = maxHeartRate

    if (
      athlete.isInSlipStream &&
            athlete.isSprinting &&
            this.heartRate > heartRateConfig.slipStreamMaxHeartRate
    ) {

      this.heartRate = heartRateConfig.slipStreamMaxHeartRate

    }

    this.heartRateChange = 0

  }

  /**
   * calculacia heartrateu, komplexna logika
   * @param athlete - atlet
   */
  private calculateHeartRate(athlete: Athlete): void {

    if (athlete.startRunActive) return
    this.calculateRunningHeartRate(athlete)
    this.calculateSprintingHeartRate(athlete)
    this.calculateDownhillHeartRate(athlete)

    this.changeHeartRate(athlete)

  }

  /**
   * Update metoda - tuto volame inde lebo sa to rata kazdy frame
   * @param athlete - atlet
   */
  public update(athlete: Athlete): void {

    if (this.gameNotStarted()) return
    if (this.oneSecPassed()) this.calculateHeartRate(athlete)

    if (athlete.playable) {

      store.commit('HeartRateState/SET_RATE', this.getHeartRate())
      this.setAudio()

    }

  }

  /**
   * nastavime zvuky
   */
  private setAudio(): void {

    if (!disciplinePhasesManager.oneOfPhaseIsActual([DisciplinePhases.running])) return

    // buchanie srdca
    if (this.heartRate <= audioGameConfig.hearthRateRunningTreshold) {

      audioManager.stopAudioByName(AudioNames.heartbeat)

    } else {

      audioManager.play(AudioNames.heartbeat)

    }

  }

  /**
   * Resetovanie veci
   */
  public reset(): void {

    this.heartRate = heartRateConfig.minRate
    this.speedBarHeartRate = 190
    this.frameOfGame = 0
    this.heartRateChange = 0

  }

}
