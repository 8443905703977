<template>
  <div class="info-key-positioner">
    <arrow-animation
      v-if="tutorialButtons.glowHorizontal"
      position="bottom"
      style="position: absolute; width: 320px; height: 156px; bottom: 12%; left: 2%; transform-origin: 0 100%;"
    />
    <tutorial-keyboard-keys
      v-if="showSideArrows"
      type="arrow-keys"
      :text="$t('webInfoLeft') + ' / ' + $t('webInfoRight')"
      is-scaled
      class="keyboard-side-keys"
    >
      <section
        class="tutorial-keyboard-keys-wrapper"
      >
        <tutorial-keyboard-key
          type="arrow"
          rotation="left"
          letter="a"
          :glow="tutorialButtons.glowHorizontal"
        />
        <tutorial-keyboard-key
          type="arrow"
          rotation="right"
          letter="d"
          :glow="tutorialButtons.glowHorizontal"
        />
      </section>
    </tutorial-keyboard-keys>
    <arrow-animation
      v-if="tutorialButtons.glowVertical"
      position="bottom"
      style="position: absolute; width: 200px; height: 276px; right: 2%; bottom: 12%; transform-origin: 100% 100%;"
    />
    <tutorial-keyboard-keys
      v-if="showVerticalArrows"
      type="arrow-keys"
      :text="$t('webInfoTempo')"
      is-scaled
      class="keyboard-vertical-keys"
    >
      <section
        class="tutorial-keyboard-keys-wrapper"
      >
        <tutorial-keyboard-key
          type="arrow"
          rotation="top"
          letter="w"
          :glow="tutorialButtons.glowVertical"
        />
        <tutorial-keyboard-key
          type="arrow"
          rotation="down"
          letter="s"
          :glow="tutorialButtons.glowVertical"
        />
      </section>
    </tutorial-keyboard-keys>
    <arrow-animation
      v-if="showSpace"
      position="bottom"
      style="position: absolute; right: 2%; width: 310px; height: 146px; bottom: 12%; transform-origin: 100% 100%;"
    />
    <tutorial-keyboard-keys
      v-if="showSpace"
      type="space"
      :text="$t('webInfoStart')"
      :glow="true"
      class="keyboard-space yellow-glow"
      is-scaled
    >
      <tutorial-keyboard-key
        type="space"
        glow
      />
    </tutorial-keyboard-keys>
  </div>
</template>

<script lang="ts">
import {
  ArrowAnimation,
  TutorialKeyboardKeys,
  TutorialKeyboardKey
} from '@powerplay/core-minigames-ui'
import { mapGetters } from 'vuex'

import { defineComponent } from 'vue'

export default defineComponent({
  name: 'TutorialInfoKeys',
  components: {
    ArrowAnimation,
    TutorialKeyboardKeys,
    TutorialKeyboardKey
  },
  computed: {
    ...mapGetters({
      tutorialState: 'TutorialState/getTutorialState',
      tutorialButtons: 'TutorialState/getTutorialButtons',
    }),
    showSpace() {

      return this.tutorialState.showButtonStart

    },
    showSideArrows() {

      return this.tutorialButtons.glowHorizontal

    },
    showVerticalArrows() {

      return this.tutorialButtons.glowVertical

    }
  }
})
</script>

<style lang="less" scoped>
    .positioner {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 25%;

        .keyboard-side-keys {
            transform-origin: 0 100%;
            position: absolute;
            left: 2%;
            bottom: 10%;

            .tutorial-keyboard-keys-wrapper {
                height: 100px;
            }
        }
        .keyboard-vertical-keys {
            transform-origin: 100% 100%;
            position: absolute;
            right: 2%;
            bottom: 10%;

            .tutorial-keyboard-keys-wrapper {
                width: 100px;
            }
            .tutorial-keyboard-header {
                width: 140px;
            }
        }

        .keyboard-space {
            transform-origin: 100% 100%;
            position: absolute;
            right: 2%;
            bottom: 10%;
        }
    }
</style>
