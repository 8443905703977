<template>
  <section class="positioner">
    <section
      :class="[!gameSettingsState.isLeft ? 'row' : 'row-reverse']"
      class="flex"
    >
      <div
        :class="[gameSettingsState.isLeft ? 'bottom-right' : 'bottom-left']"
        style="position: relative"
        :style="{ transform: `scale(${scaleCoef})` }"
      >
        <arrow-animation
          v-if="tutorialButtons.glowHorizontal && isMobile()"
          position="bottom"
          style="position:absolute; width: 580px; height: 460px; bottom: 0;"
          :is-scaled="false"
        />
        <power-stick
          v-show="isMobile()"
          v-if="tutorialButtons.showJoystick"
          :only-horizontal="isHorizontal"
          :glow="tutorialButtons.glowVertical"
          :is-scaled="false"
        />
        <movement-buttons
          v-show="isMobile()"
          v-if="tutorialButtons.glowHorizontal && isMobile()"
          style="pointer-events: none"
          :glow="tutorialButtons.glowHorizontal"
          :is-scaled="false"
          @position="changeMovementX"
        />
      </div>
      <section
        :class="[!gameSettingsState.isLeft ? 'bottom-right' : 'bottom-left']"
        class="buttons-set-wrapper"
        :style="[
          { transform: `scale(${scaleCoef})`, position: 'absolute', right: '0', bottom: '0' },
          smallButtonsLeftFix
        ]"
      >
        <mobile-button
          v-if="tutorialButtons.showJoystick"
          v-show="isMobile()"
          type="shoot"
          :glow="true"
          :is-scaled="false"
        />
        <section
          v-show="isMobile()"
          class="movement-buttons-section"
        >
          <arrow-animation
            v-if="tutorialButtons.glowVertical && isMobile()"
            position="bottom"
            style="position:absolute; width: 580px; height: 460px; bottom: 0;"
            :is-scaled="false"
          />
          <movement-buttons
            v-if="tutorialButtons.glowVertical && isMobile()"
            style="pointer-events: none"
            orientation="vertical"
            :glow="tutorialButtons.glowVertical"
            :is-scaled="false"
          />
        </section>
      </section>
    </section>
  </section>
</template>
<script lang="ts">
import {
  WindowAspect,
  ArrowAnimation,
  MovementButtons,
  PowerStick,
  MobileButton
} from '@powerplay/core-minigames-ui'
import { mapGetters } from 'vuex'
import {
  MobileDetector,
  tutorialManager,
  inputsManager
} from '@powerplay/core-minigames'
// import TutorialButtonStart from '@/components/Tutorial/TutorialButtonStart.vue'
import { Sides } from '@/app/types'
import { tutorialFlow } from '@/app/modes/tutorial/TutorialFlow'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'TutorialButtons',
  components: {
    ArrowAnimation,
    // TutorialHandSettings,
    // TutorialButtonStart,
    MovementButtons,
    PowerStick,
    MobileButton,
  },
  mixins: [WindowAspect],
  data() {

    return {
      tasks: [],
      blockedEndAction: false,
      wasTypewriting: false
    }

  },
  computed: {
    ...mapGetters({
      tutorialButtons: 'TutorialState/getTutorialButtons',
      sprintBar: 'GamePhaseState/getSprintBar',
      sprintColor: 'GamePhaseState/getSprintButtonColor',
      downhillColor: 'GamePhaseState/getDownhillButtonColor',
      gameSettingsState: 'GameSettingsState/getGameSettingsState',
      isLeft: 'InputsState/isLeft',
      isHorizontal: 'MovementState/getIsHorizontal',
      isTypewriting: 'TutorialCoreState/getTypeWriter'
    }),
    basicStyle() {

      return {
        'align-items': 'flex-end',
        padding: '0 0 10px'
      }

    },
    transformCoef() {

      return { transform: `scale(${this.scaleCoef})` }

    },
    smallButtonsLeftFix() {

      if (!this.isMobile()) return { width: '100%' }
      if (this.gameSettingsState.isLeft && this.isMobile()) {

        return { left: '0' }

      }
      return {}

    }
  },
  methods: {
    performAction() {

      if (this.isTypewriting || this.blockedEndAction) {

        this.wasTypewriting = true
        return

      }

      this.$store.commit('TuckState/SET_STATE', { isTuck: true })

      if (MobileDetector.isMobile()) return

      setTimeout(() => {

        this.endAction()

      }, 100)

    },
    endAction() {

      if (this.wasTypewriting && !this.blockedEndAction) {

        this.blockedEndAction = true

        this.$store.commit('TutorialCoreState/SET_TYPE_WRITER', false)
        tutorialManager.setTypeWriting(false)

        setTimeout(() => {

          this.blockedEndAction = false
          this.wasTypewriting = false

        }, 150)

        return

      }

      if (this.blockedEndAction) return

      this.$store.commit('TuckState/SET_STATE', { isTuck: false })
      // musime dat manulane ukoncenie buttonu, aby sa ukoncil takisto actionPressed v inputs
      inputsManager.handleMouseUp()

    },
    performAction2() {

      if (this.isTypewriting || this.blockedEndAction) {

        this.wasTypewriting = true
        return

      }

      this.$store.commit('SprintState/SET_STATE', {
        isSprinting: true,
        tutorialFreeze: !MobileDetector.isMobile()
      })

      if (MobileDetector.isMobile()) return

      setTimeout(() => {

        this.endAction2()

      }, 100)

    },
    endAction2() {

      if (this.wasTypewriting && !this.blockedEndAction) {

        this.blockedEndAction = true

        this.$store.commit('TutorialCoreState/SET_TYPE_WRITER', false)
        tutorialManager.setTypeWriting(false)

        setTimeout(() => {

          this.blockedEndAction = false
          this.wasTypewriting = false

        }, 150)

        return

      }

      if (this.blockedEndAction) return

      this.$store.commit('SprintState/SET_STATE', {
        isSprinting: false,
        tutorialFreeze: false
      })
      // musime dat manulane ukoncenie buttonu, aby sa ukoncil takisto actionPressed v inputs
      inputsManager.handleMouseUp()

    },
    changeMovementX(positionX: number) {

      if (this.isTypewriting || this.blockedEndAction) {

        this.wasTypewriting = true
        return

      }
      if (positionX > 0) {

        tutorialFlow.changePathInput(Sides.RIGHT)

      }
      if (positionX < 0) {

        tutorialFlow.changePathInput(Sides.LEFT)

      }

    }
  },
})
</script>

<!-- TU nemoze byt scoped, pokial bude riesene .small-action-button-wrapper v tomto file -->
<style lang="less">
.nomobile-small-buttons-tutorial {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 375px;
  height: 375px;

  .bar {
    position: relative;
  }

  .arrow-wrapper {
    transform-origin: 50% 0%;
  }

  .bottom-right {
    transform-origin: bottom right;
  }

  .bottom-left {
    transform-origin: bottom left;
  }
}

.tutorial {

  .positioner {
    position: absolute;
    bottom: 0;
    width: 100%;
  }

  .flex {
    display: flex;
    justify-content: space-between;
  }

  .row {
    flex-direction: row;
  }

  .row-reverse {
    flex-direction: row-reverse;
  }

  .buttons-set-wrapper {
    position: relative;
    width: 580px;
    height: 580px;
  }

  .buttons-position {
    position: absolute;
    top: -60px;
    left: 0;
    width: 100%;
    height: 200px;
  }

  .tutorial-anne {
    bottom: 0;
  }

  .sub-positions {

    .bar-right {
      display: flex;
      justify-content: flex-start;
      align-items: flex-end;
      width: 50%;
      height: 200px;
      position: absolute;
      right: 0;

      .small-action-button-wrapper {
        pointer-events: auto;
        justify-content: flex-start;
        width: 100%;
        height: 100%;
        align-items: flex-end;
        padding: 0 20px;
      }
    }

    .bar-left {
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
      width: 50%;
      height: 200px;
      position: absolute;
      left: 0;

      .small-action-button-wrapper {
        pointer-events: auto;
        justify-content: flex-end;
        width: 100%;
        height: 100%;
        align-items: flex-end;
        padding: 0 20px;
      }
    }
  }

  .bottom-right {
    transform-origin: bottom right;
  }

  .bottom-left {
    transform-origin: bottom left;
  }

  .top-right {
    transform-origin: top right;
  }
}
</style>
