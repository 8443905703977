import type { TranslatesReplacements } from '@powerplay/core-minigames'

/**
 * Vymeny textov pre preklady - prekonkretnu disciplinu
 */
export const translatesReplacements: TranslatesReplacements[] = [

  {
    text: 'instructionsDiscipline8Cell1Text1',
    replace: [
      {
        original: '[key]',
        new: 'SPACE/LMB'
      }
    ]
  },
  {
    text: 'instructionsDisciplineTraining8Cell2Text1',
    replace: [
      {
        original: '[number]',
        new: '10'
      }
    ]
  },
  {
    text: 'tutorialText8-2',
    replace: [
      {
        original: '[start]',
        new: 'START'
      }
    ]
  },
  {
    text: 'tutorialTask8-3',
    replace: [
      {
        original: '[number]',
        new: '[number]'
      }
    ]
  },

]
