import { speedBarMaxValueConfig } from '@/app/config/speedBarMaxValueConfig'
import store from '@/store'

/**
 * Trieda pre maximalnu hodnotu baru
 */
export class SpeedBarMaxValueManager {

  /** Taky dzidzik na heart rate */
  private maxValue = speedBarMaxValueConfig.defaultMaxSpeedBarValue

  /** FrameChange for max speed */
  private framer = 0

  /**
   * Vratenie max hodnoty baru
   * @returns Max hodnota baru
   */
  public getSpeedBarMaxValue(): number {

    return this.maxValue

  }

  /**
   * Nastavenie max hodnoty
   * @param heartRate - tep
   * @param isPlayable - ci sa jedna o hratelneho hraca
   */
  private setMaxValue(heartRate: number, isPlayable: boolean): void {

    const condition = heartRate > speedBarMaxValueConfig.limitForCalculations
    condition ? this.decreaseMaxValue(heartRate) : this.increaseMaxValue(heartRate)

    if (isPlayable) {

      store.commit('GamePhaseState/SET_MAX_SPEED', this.maxValue)

    }

  }

  /**
   * Znizenie max hodnoty
   * @param heartRate - tep
   */
  private decreaseMaxValue(heartRate: number): void {

    const {
      limitForDecreasingValue, limitForCalculations, decreaseCoef1, decreaseCoef2
    } = speedBarMaxValueConfig

    if (this.maxValue <= limitForDecreasingValue) return

    this.updateMaxValue(
      decreaseCoef1 - decreaseCoef2 * (heartRate - limitForCalculations),
      -1
    )

  }

  /**
   * Zvysenie max hodnoty
   * @param heartRate - tep
   */
  private increaseMaxValue(heartRate: number): void {

    const { limitForIncreasingValue, increaseCoef } = speedBarMaxValueConfig

    if (this.maxValue >= limitForIncreasingValue) return

    this.updateMaxValue(heartRate - increaseCoef, 1)

  }

  /**
   * Aktualizovanie max hodnoty
   * @param frameToCheck - Ktory frame treba aktualizovat
   * @param sign - Znamienko pre pripocitanie alebo odpocitanie
   */
  private updateMaxValue(frameToCheck: number, sign: number): void {

    const {
      SPEED_RATE_DELTA, limitForDecreasingValue, limitForIncreasingValue
    } = speedBarMaxValueConfig

    if (this.framer >= frameToCheck) {

      this.maxValue += (SPEED_RATE_DELTA * sign)

      // check min max
      if (this.maxValue <= limitForDecreasingValue) this.maxValue = limitForDecreasingValue
      if (this.maxValue >= limitForIncreasingValue) this.maxValue = limitForIncreasingValue

      this.framer = 0

    }

  }

  /**
   * Aktualizacia veci
   * @param heartRate - tep
   */
  public update(heartRate: number, isPlayable = false): void {

    this.setMaxValue(heartRate, isPlayable)
    this.framer += 1

  }

  /**
   * Resetovanie
   */
  public reset(): void {

    this.maxValue = speedBarMaxValueConfig.defaultMaxSpeedBarValue
    this.framer = 0

  }

}
