/**
 * trieda pre spravu konstant pre audio
 */
export const audioGameConfig = {

  /** Konfig pre hlasitost divakov */
  audienceVolume: {

    /** Min a max hodnoty hlasitosti */
    min: 0.2,
    max: 1,

    /** Kedy sa zacne/skonci zvysovanie hlasitosti po starte */
    decreaseAfterStart: {

      start: 0.13,
      stop: 0.325

    },

    /** Kedy sa zacne/skonci znizovanie hlasitosti pred cielom */
    increaseBeforeStart: {

      start: 0.72,
      stop: 0.87

    }

  },

  /** Kde su vsade povzbudzovania */
  cheerings: [

    { preStart: 0.315,
      start: 0.325,
      stop: 0.375,
      postStop: 0.385 },
    { preStart: 0.54,
      start: 0.55,
      stop: 0.6,
      postStop: 0.61 },
    { preStart: 0.65,
      start: 0.66,
      stop: 0.72,
      postStop: 0.73 }

  ],

  /** Nastavenia pre pohyb supera */
  opponentMovement: {

    /** Pocet frameov, na kolko bude freeznuty opponent */
    freezeOneOpponentFrames: 30,

    /** Maximalna hlasitost */
    maxVolume: 0.5,

    /** Max vzdialnost, dalej bude hlasitost 0 */
    maxDistance: 15,

    /** Min vzdialenost, skor bude max hlasitost */
    minDistance: 5

  },

  /** kedy zacne buchat srdce pri behu */
  hearthRateRunningTreshold: 180,

  /** kedy dychcat srdce pri behu */
  breathingRunningTreshold: 160

}
