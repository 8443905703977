export const splitTimesConfig = {

  /** Pozicie medzicasov */
  splitTimePositions: [
    [.375, 0.3762, 0.3781, 0.38, 0.3824, 0.3849, 0.3849],
    [0.599, 0.5996, 0.6017, 0.604, 0.607, 0.6089, 0.6089],
    [0.9553, 0.9561, 0.9581999999999999, 0.9605, 0.964, 0.9679, 0.9728]
  ],

  /** kolko sekund po poslednom riadku zobrazujeme UI */
  uiHideAfterSeconds: 10,

  /** kolko zaznamov o splitoch zobrazit vratane hraca */
  splitRecordsToShow: 4

}
