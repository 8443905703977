import { opponentConfig } from '@/app/config'
import { materialsConfig } from '@/app/config/materialsConfig'
import {
  DisciplinePhases,
  MaterialsNames,
  ModelsNames,
  type StartPositionsData
} from '@/app/types'
import {
  game,
  gsap,
  THREE,
  playersManager,
  timeManager,
  type PlayerInfo,
  modes
} from '@powerplay/core-minigames'
import { Athlete } from '../athlete'
import { player } from '../player'
import { OpponentVelocityManager } from './OpponentVelocityManager'
import { disciplinePhasesManager } from '../../phases/DisciplinePhasesManager'
import { FinishPhaseManager } from '../../phases/FinishPhase/FinishPhase'

/** Trieda pre spravu protihraca */
export class Opponent extends Athlete {

  /** pocet framov do startu */
  private delayedStartFrames = 0

  /**
   * Konstruktor
   * @param uuid - UUID spera
   */
  public constructor(uuid: string, private materialIndex: number) {

    super(uuid)
    this.velocityManager = new OpponentVelocityManager(this)
    this.velocityManager.generateNextSpeedBarModifier(0)

  }

  /**
   * Nastavenie attrbiute strength napriamo
   * @param attrStrength - Attribute strength
   */
  public setAttributeStrength(attrStrength: number): void {

    this.attrStrength = attrStrength

  }

  /**
   * Vypocet attrStrength
   * @returns attrStrength - number
   */
  public getAthleteAttributeStrength(): number {

    if (modes.isTrainingMode()) return this.attrStrength
    return super.getAthleteAttributeStrength()

  }

  /**
   * Vratenie objektu atleta
   * @returns Objekt atleta
   */
  protected getObject(): THREE.Object3D {

    const sex = playersManager.getPlayerById(this.uuid)?.sex
    let objectForClone
    if (playersManager.getPlayer().sex !== sex) {

      objectForClone = game.getObject3D('skier_opponent')

    }
    console.log('HGE', objectForClone, this.uuid, playersManager.getPlayer().sex, sex)
    const athleteObject = objectForClone ?
      game.cloneSkeleton(objectForClone) :
      game.cloneSkeleton(player.athleteObject)

    // game.scene.add(athleteObject)

    materialsConfig[ModelsNames.skier]?.meshesArray?.forEach((meshName) => {

      const opponentMesh = athleteObject.getObjectByName(meshName) as THREE.Mesh
      if (!opponentMesh) return
      meshName += `_opponent_${this.materialIndex}`
      opponentMesh.name = meshName

      // musime nastavit material
      opponentMesh.material = game.materialsToUse.basic
        .get(MaterialsNames.skierOpponentPrefix + this.materialIndex) as THREE.MeshBasicMaterial

    })

    return athleteObject

  }

  /**
   * Vytvorenie protihraca
   * @param startData - Data startovacej pozicie
   */
  public create(startData: StartPositionsData): void {

    console.log('vytvaram protihraca...', this.uuid)

    const info = playersManager.getPlayerById(this.uuid) as PlayerInfo
    const sufix = playersManager.getPlayer().sex !== info.sex ?
      '_opponent' :
      ''
    super.create(startData, `Opponent${ this.uuid}`, ModelsNames.skier + sufix)

    this.animationsManager.pauseAll()
    gsap.to({}, {
      onComplete: () => {

        this.animationsManager.unpauseAll()

      },
      duration: 1
    })

  }

  /**
   * Aktualizovanie hraca pred vykonanim fyziky
   * @param frame - aktualny frame
   */
  public updateBeforePhysics(frame = 0): void {

    super.updateBeforePhysics()

    // skontrolujeme, ci netreba odstartovat
    this.checkDelayedStart(frame)

  }

  /**
   * Vymazanie vsetkych callbackov z animations managera
   */
  public removeCallbacksFromAllAnimations(): void {

    this.animationsManager.removeCallbacksFromAllAnimations()

  }

  /**
   * Nastavenie reakcnych casov
   */
  public setDelayedStartFrames(): void {

    const { from, to } = opponentConfig.startFrames
    this.delayedStartFrames = Math.round(Math.random() * (to - from) + from)

    console.log(`Protihracov ${this.uuid} reakcny cas (frame) = ${this.delayedStartFrames}`)
    // this.actualState = OpponentState.start

  }

  /**
   * Skontrolovanie oneskoreneho startu podla reakcneho casu
   * @param frame - aktualny frame
   */
  private checkDelayedStart(frame: number): void {

    if (this.delayedStartFrames !== frame) return

    this.start()

  }

  /**
   * Spravanie pri dosiahnuti ciela
   */
  public finishReached(): void {

    super.finishReached()
    this.finishTime = timeManager.getGameTimeWithPenaltyInSeconds(false, undefined, 1)
    this.activeUpdatingMovementAnimations = false

    const finishPhase = disciplinePhasesManager.getDisciplinePhaseManager(DisciplinePhases.finish) as FinishPhaseManager
    if (finishPhase.dailyLeagueSetResultsOpponentsFreeze) return
    playersManager.setPlayerResultsById(this.uuid, this.finishTime)

  }

  /**
   * reset
   */
  public reset(startData: StartPositionsData = { row: 0,
    pathIndex: 0 }): void {

    this.delayedStartFrames = 0

    super.reset(startData)

  }

}
