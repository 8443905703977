import type { MaterialDataObject } from '@powerplay/core-minigames'
import { checkpointManager } from '../modes/training'
import {
  MaterialsNames,
  TexturesNames
} from '../types'

/**
 * Konfig pre materialy
 */
export const materialsConfig: MaterialDataObject = {
  [MaterialsNames.skier]: {
    meshesArray: [
      'body', 'w_body', 'pole_L', 'pole_R', 'riffle_1', 'ski_L', 'ski_R', 'strap', 'w_pole_L',
      'w_pole_R', 'w_riffle', 'w_ski_L', 'w_ski_R', 'w_strap'],
    textureName: TexturesNames.skierClothes,
    playerIndex: 0
  },
  [MaterialsNames.skierOpponent1]: {
    meshesArray: [
      'body_opponent_1', 'w_body_opponent_1'],
    textureName: TexturesNames.skierClothesOpponent1,
    playerIndex: 1

  },
  [MaterialsNames.skierOpponent2]: {
    meshesArray: [
      'body_opponent_2', 'w_body_opponent_2'],
    textureName: TexturesNames.skierClothesOpponent2,
    playerIndex: 2

  },
  [MaterialsNames.skierOpponent3]: {
    meshesArray: [
      'body_opponent_3', 'w_body_opponent_3'],
    textureName: TexturesNames.skierClothesOpponent3,
    playerIndex: 3

  },
  [MaterialsNames.skierOpponent4]: {
    meshesArray: [
      'body_opponent_4', 'w_body_opponent_4'],
    textureName: TexturesNames.skierClothesOpponent4,
    playerIndex: 4

  },
  [MaterialsNames.skierOpponent5]: {
    meshesArray: [
      'body_opponent_5', 'w_body_opponent_5'],
    textureName: TexturesNames.skierClothesOpponent5,
    playerIndex: 5

  },
  [MaterialsNames.skierOpponent6]: {
    meshesArray: [
      'body_opponent_6', 'w_body_opponent_6'],
    textureName: TexturesNames.skierClothesOpponent6,
    playerIndex: 6

  },
  [MaterialsNames.skierOpponent7]: {
    meshesArray: [
      'body_opponent_7', 'w_body_opponent_7'],
    textureName: TexturesNames.skierClothesOpponent7,
    playerIndex: 7

  },
  [MaterialsNames.hill]: {
    textureName: TexturesNames.hill,
    lightmap: TexturesNames.lightmapHill,
    vertexColors: true,
    isDefault: true
  },
  [MaterialsNames.transparent]: {
    alpha: 0.5,
    textureName: TexturesNames.transparent,
    meshesArray: [
      'Trees', 'Audience', 'Crowd002', 'Crowd003', 'Crowd004', 'Crowd005',
    ],
    lightmap: TexturesNames.lightmapHill
  },
  [MaterialsNames.ads]: {
    textureName: TexturesNames.ads,
    meshesArray: [
      'Ads', 'Start_Gate', 'Finish_Gate', 'Barriers', 'Barrier_Finish', 'Barrier_Start',
      'VictoryStand'
    ],
    lightmap: TexturesNames.lightmapHill
  },
  [MaterialsNames.flags]: {
    textureName: TexturesNames.flags,
    meshesArray: ['Flags'],
    lightmap: TexturesNames.lightmapHill
  },
  [MaterialsNames.track]: {
    textureName: TexturesNames.track,
    meshesArray: [
      'Terrain', 'Terrain001', 'Track', 'Track00', 'Track01', 'Track02', 'Track03', 'Track04',
      'Track05', 'Track06', 'Track07', 'Track08', 'Track09', 'Track10'],
    lightmap: TexturesNames.lightmapHill,
    vertexColors: true
  },
  [MaterialsNames.staticPeople]: {
    textureName: TexturesNames.staticPeople,
    meshesArray: ['StaticPeople'],
    lightmap: TexturesNames.lightmapHill
  },
  [MaterialsNames.rocks]: {
    meshesArray: ['Rocks01', 'Rocks02', 'Rocks03', 'Rocks04', 'Rocks05', 'Rocks06', 'Rocks006'],
    textureName: TexturesNames.rocks,
    lightmap: TexturesNames.lightmapHill
  },
  [MaterialsNames.startAds]: {
    meshesArray: ['TransparentAds'],
    textureName: TexturesNames.startAds,
    transparent: true,
    lightmap: TexturesNames.lightmapHill
  },
  [MaterialsNames.screen]: {
    textureName: TexturesNames.screen,
    meshesArray: ['Screen'],
    lightmap: TexturesNames.lightmapHill
  },
  [MaterialsNames.onlyVertexColor]: {
    vertexColors: true,
    meshesArray: [checkpointManager.CHECKPOINT_MESH_NAME]
  },
  [MaterialsNames.checkpointGlow]: {
    meshesArray: [checkpointManager.CHECKPOINT_GLOW_MESH_NAME],
    transparent: true,
    textureName: TexturesNames.marker,
    opacity: 0.2
  },
}
