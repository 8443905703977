
import {
  THREE,
  game,
  type CannonNamedBody
} from '@powerplay/core-minigames'
import { player } from '../player'

/**
 * Trieda pre kopec
 */
export class Hill {

  /** Mesh kopca */
  public hillMesh!: THREE.Mesh

  /** Mesh rohu trate */
  // edgeMesh!: THREE.Mesh;

  /** Fyzikalne body kopca */
  public hillBodies: CannonNamedBody[] = []

  /** raycast */
  private raycast = new THREE.Raycaster()

  /** Dalsi index terenoveho meshu na zobrazenie */
  private nextTerrainObjectIndex = 0

  /** Pomocna pozicia */
  private helperPosition = new THREE.Vector3()

  /** Maximalny index terenoveho objektu */
  private readonly MAX_TERRAIN_OBJECT_INDEX = 10

  /** Vektor smerom hore, ktory budeme davat kvoli raycastu */
  private readonly VECTOR_SHIFT_UP = new THREE.Vector3(0, 1, 0)

  /** Vektor smelom dole */
  private readonly VECTOR_DOWN = new THREE.Vector3(0, -1, 0)

  /**
   * Vytvorenie kopca
   */
  public create(): void {

    console.log('VYTVARAM HILL....')
    this.createHillPhysics()

    console.log('HILL vytvoreny....')



  }

  /**
   * Vytvorenie fyziky kopca
   */
  private createHillPhysics(): void {

    /*
     * this.hillMesh = game.getMesh('Physics_Track')
     * this.hillMesh.visible = false
     */

  }

  /**
   * Nastavenie tienov
   */
  public setUpShadows(): void {

    game.shadowsManager.setObjectToFollowWithLight(player.athleteObject)
    this.setMeshAsShadowPlane('Track00')
    this.setMeshAsShadowPlane('Track01')
    this.nextTerrainObjectIndex = 2

  }

  /** Nastavenie meshu ako shadow plane
   * @param meshName - nazov meshu
   */
  public setMeshAsShadowPlane(meshName: string): void {

    const mesh = game.getMesh(meshName).clone()
    mesh.name += 'cloned'
    game.scene.add(mesh)
    game.shadowsManager.setTerrainShadowPlane(mesh) // TODO TEMP mesh clone iba docasne

  }

  /**
   * Spravovanie shadow planeov
   */
  public manageShadowPlanes(): void {

    // ak uz nemame kam posuvat, tak nic nerobime
    if (this.nextTerrainObjectIndex > this.MAX_TERRAIN_OBJECT_INDEX) return

    this.helperPosition.copy(player.getPosition()).add(this.VECTOR_SHIFT_UP)
    this.raycast.set(this.helperPosition, this.VECTOR_DOWN)

    const indexToCheck = this.nextTerrainObjectIndex - 1
    const nextObject = game.getMesh(`Track${indexToCheck < 10 ? `0${indexToCheck}` : indexToCheck}`)
    if (this.raycast.intersectObject(nextObject).length > 0) {

      const index = this.nextTerrainObjectIndex < 10 ? `0${this.nextTerrainObjectIndex}` : this.nextTerrainObjectIndex
      this.setMeshAsShadowPlane(`Track${index}`)
      this.nextTerrainObjectIndex += 1

    }

  }

}

export const hill = new Hill()
