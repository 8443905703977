import type { Module } from 'vuex'
import type { RootState } from '../index'


export interface PlayerState {
  maxSpeed: number,
  speed: number,
  gradient: number,
  sprintBonus: number,
  forces: {
    forwardForce: number,
    forwardForceTuck: number,
    forwardForceSprint: number,
    backwardForce: number
  },
  BPMBase: number
}

const initialState = () => ({
  maxSpeed: 1,
  speed: 0,
  gradient: 0,
  sprintBonus: 0,
  forces: {
    forwardForce: 0,
    forwardForceTuck: 0,
    forwardForceSprint: 0,
    backwardForce: 0
  },
  BPMBase: 0
})

const mainState: Module<PlayerState, RootState> = {
  state: initialState(),

  namespaced: true,

  getters: {
    getMainState: (moduleState: PlayerState) => moduleState
  },

  mutations: {
    RESET: (moduleState: PlayerState) => {

      Object.assign(moduleState, initialState())

    },
    SET_STATE: (moduleState: PlayerState, newState: PlayerState) => {

      moduleState.maxSpeed = newState.maxSpeed
      moduleState.speed = newState.speed
      moduleState.gradient = newState.gradient
      moduleState.sprintBonus = newState.sprintBonus

    },
    SET_FORCES: (moduleState: PlayerState, forces: {
      forwardForce: number,
      forwardForceTuck: number,
      forwardForceSprint: number,
      backwardForce: number
    }) => {

      moduleState.forces = forces

    },
    SET_BPMB: (moduleState: PlayerState, BPMB: number) => {

      moduleState.BPMBase = BPMB

    }
  }
}

export default mainState
