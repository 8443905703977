import { velocityConfig } from '@/app/config'
import type { Athlete } from './Athlete'

/**
 * Uphill stav manager
 */
export class UphillManager {

  /** Samotne povolenie ci sa smie spustat wonhill */
  private isUphillAllowed = false

  /**
   * Konstruktor
   * @param athlete - Atlet
   */
  public constructor(private athlete: Athlete) {}

  /**
   * Public metoda na zistenie ci je uphill
   * @returns Ci je uphill povoleny
   */
  public getUphillAllowed(): boolean {

    return this.isUphillAllowed

  }

  /**
   * Zistenie ci sa ma udiat akcia zmeny povolenia uphillu
   */
  private checkIfActionMustHappen(): void {

    const { uphillGradientLimit } = velocityConfig
    this.isUphillAllowed = this.athlete.getVelocityGradient() >= uphillGradientLimit

  }

  /**
   * Update metoda ktoru mame pouzit externe
   */
  public update(): void {

    this.checkIfActionMustHappen()

  }

  /**
   * Resetovanie veci
   */
  public reset(): void {

    this.isUphillAllowed = false

  }

}
