import { gameConfig } from '@/app/config'
import { SmallActionButtonColors } from '@/app/types'
import type { Opponent } from './Opponent'

/**
 * Trieda pre spravu sprintu hraca
 */
export class OpponentSprintBarManager {

  /** Mnozstvo sprintu v sprintbare */
  private barValue = 0

  /** Mnozstvo frameov bez sprint fillu */
  private frameWithoutFill = 0

  /** Minimalna hodnota baru */
  public readonly MIN_VALUE = 0

  /** Maximalna hodnota baru */
  public readonly MAX_VALUE = 100

  /**
   * Obraz stateu storageu ktorym zabezpecime, ze nebudeme permanentne posielat zmenu stavou
   * do storage - farba obrazka
   */
  private actualColor = SmallActionButtonColors.yellow

  /** Zistenie ci sa smie sprintovat */
  public isSprintAvailable(): boolean {

    return this.barValue >= gameConfig.turnOnSprintDecrease

  }

  /**
   * Getter
   * @returns barValue
   */
  public getBarValue(): number {

    return this.barValue

  }

  /**
   * Metoda na upravu sprintBaru hore
   * @param speedBarValue - Hodnota speed baru
   */
  private refillSprint(opponent: Opponent, speedBarValue: number): void {

    if (
      this.frameWithoutFill >= gameConfig.sprintBarFrameRate
    ) {

      let sprintBarIncrease = (opponent.getSpeedBarMaxValue() - speedBarValue) / 100 +
                gameConfig.sprintBarFillRate
      if (opponent.isTuck) {

        sprintBarIncrease = gameConfig.sprintBarDownhillIncreaseRate

      } else if (opponent.isInSlipStream) {

        sprintBarIncrease = gameConfig.sprintBarSlipstreamIncreaseRate

      }

      this.barValue += sprintBarIncrease
      this.frameWithoutFill = 0

      if (this.barValue > this.MAX_VALUE) this.barValue = this.MAX_VALUE

    } else {

      this.frameWithoutFill++

    }

  }

  /**
   * Komplexna metoda ktora riesi sprint bar ako aj povolenie sprintovat
   * @returns void
   */
  private handleSprint(opponent: Opponent): void {

    if (this.isSprintAvailable()) {

      const {
        sprintBarDecreaseRate, sprintBarDecreaseRateSlipstream, sprintBarFrameRate
      } = gameConfig

      if (this.frameWithoutFill >= sprintBarFrameRate) {

        let sprintBarDecreaseRateValue = sprintBarDecreaseRate
        if (opponent.isInSlipStream) {

          sprintBarDecreaseRateValue = sprintBarDecreaseRateSlipstream

        }
        this.barValue -= sprintBarDecreaseRateValue
        this.barValue -= sprintBarDecreaseRate
        this.frameWithoutFill = 0

        if (this.barValue < this.MIN_VALUE) this.barValue = this.MIN_VALUE

      } else {

        this.frameWithoutFill++

      }

    } else {

      opponent.isSprinting = false

    }

  }

  /**
   * Aktualizacia sprint baru
   * @param speedBarValue - Hodnota speed baru
   */
  public update(opponent: Opponent, speedBarValue: number): void {

    if (opponent.isSprinting) {

      this.handleSprint(opponent)

    } else {

      this.refillSprint(opponent, speedBarValue)

    }

  }

  /**
   * Zapnutie/vypnutie sprintu
   * @param value - value to set
   */
  public setSprinting(opponent: Opponent): void {

    if (!opponent.isSprinting) {

      this.barValue -= gameConfig.turnOnSprintDecrease

      if (this.barValue < this.MIN_VALUE) {

        this.barValue = this.MIN_VALUE

      }

    }
    opponent.isSprinting = true

  }

  /**
   * Nastavenie novej hodnoty pre bar
   * @param value - Hodnota
   * @returns OpponentSprintBarManager
   */
  public setBarValue(value: number): this {

    this.barValue = value
    return this

  }

  /**
   * Resetovanie veci
   */
  public reset(): void {

    this.barValue = 0
    this.frameWithoutFill = 0

  }

}
