import { player } from '@/app/entities/player'
import type { StartPhaseManager } from '@/app/phases/StartPhase/StartPhase'
import store from '@/store'
import {
  tutorialManager,
  TutorialSectionType,
  game,
  modes,
  MobileDetector,
  gsap,
  TutorialMessageColors,
  fpsManager,
  timeManager,
  requestManager
} from '@powerplay/core-minigames'
import { disciplinePhasesManager } from '../../phases/DisciplinePhasesManager'
import {
  DisciplinePhases,
  SectionNames,
  Sides,
  TutorialEventType,
  TutorialObjectiveIds
} from '../../types'
import { tutorialObjectives } from './TutorialObjectives'
import { tutorialUIChange } from './TutorialUIChange'

/**
 *  Tutorial tasky ktore maju aj logiku v sebe na ovladanie tej ktorej udalosti
 */
export class TutorialFlow {

  private activeEventType = TutorialEventType.awaitingEvent

  /** true ak uz bola uspesna prva zmnena drahy */
  private firstTurnSuccess = false

  /** true ak uz sme zobrazili hlasku o blokovani */
  private pathBlockedFirst = false

  /** ci uz sme odbocili druhy krat */
  private secondTurn = false

  /** true ak uz bola uspesne vratenie sa z medzidrahy */
  private returnTurnSuccess = false

  /** tween na cakanie zmeny drahy hracom */
  private changePathTween?: gsap.core.Tween

  /** pocitame framy ak je v slipStreame */
  public slipStreamFrames = 0

  /** pocitadlo failed startov */
  public failedStartCount = 0

  public setObjectivesInit(): void {

    const objectives = [
      {
        id: TutorialObjectiveIds.start as string,
        passed: false,
        failed: false,
        name: 'tutorialTask8-1'
      },
      {
        id: TutorialObjectiveIds.changePath as string,
        passed: false,
        failed: false,
        name: 'tutorialTask8-2'
      },
      {
        id: TutorialObjectiveIds.slipStream as string,
        passed: false,
        failed: false,
        name: 'tutorialTask8-3'
      }
    ]
    tutorialObjectives.setObjectives(objectives)

  }

  /**
   * Inicializacia
   */
  public init(): void {

    const tutorialSections = [
      {
        name: SectionNames.handCheck,
        id: 0,
        type: TutorialSectionType.storyInput,
        sectionLogicFinish: () => {

          store.commit('ActionButtonState/SET_START_BUTTON', true)
          store.commit('BlurState/SET_IS_ACTIVE', false)
          this.setObjectivesInit()

        }
      },
      {
        name: SectionNames.startSection,
        id: 1,
        type: TutorialSectionType.storyInput
      },
      {
        name: SectionNames.startSectionSecond,
        id: 2,
        type: TutorialSectionType.storyInput,
        sectionLogicIntro: () => {

          this.resetTypeWrite()
          store.commit('TutorialState/SET_SHOW_BAR_START', true)

        },
        sectionLogicFinish: () => {

          if (game.paused) game.resumeGame()

          tutorialUIChange.setMessage(false, '')
          tutorialUIChange.setAnne(false)
          store.commit('TutorialState/SET_SHOW_BUTTON_START', false)
          this.resetTypeWrite()

        }
      },
      {
        name: SectionNames.startSectionThird,
        id: 3,
        type: TutorialSectionType.gameEvent,
        sectionLogicIntro: () => {

          this.resetTypeWrite()

        }
      },
      {
        name: SectionNames.afterRunUpFirst,
        id: 4,
        type: TutorialSectionType.storyInput,
        sectionLogicIntro: () => {

          this.resetTypeWrite()

        },
        sectionLogicFinish: () => {

          store.commit('TutorialState/SET_BUTTONS', {
            showButtonStart: false,
            showMovementButtons: false
          })

        }
      },
      {
        name: SectionNames.learningEvents,
        id: 5,
        type: TutorialSectionType.gameEvent,
        sectionLogicIntro: () => {

          this.resetTypeWrite()
          this.changePathTween = gsap.to({}, {
            onComplete: () => {

              this.eventActionTrigger(TutorialEventType.changePathTimeout)

            },
            duration: 5
          })

        }
      },
      {
        name: SectionNames.endSection,
        id: 6,
        type: TutorialSectionType.gameEvent
      }
    ]
    store.commit('ActionButtonState/SET_START_BUTTON', false)

    tutorialManager.setTutorialSections(tutorialSections)

    const firstTutorialStrings = ['chooseFirstDisciplineReturnFromMinigame', 'chooseFirstDisciplineContinue']
    if (
      (MobileDetector.isMobile() && firstTutorialStrings.includes(requestManager.TUTORIAL_ID ?? '')) ||
      disciplinePhasesManager.attempt > 1
    ) return
    tutorialManager.setActualSectionId(1)
    store.commit('ActionButtonState/SET_START_BUTTON', true)

  }

  /**
   * Public metoda do game loopu
   */
  public update(): void {

    // this.checkInput()
    tutorialUIChange.update()
    tutorialObjectives.update()

  }

  /**
   * Kontrola inputov
   */
  public checkInput(): void {

    if (TutorialSectionType.gameEvent === tutorialManager.getActualSectionType()) {

      this.eventActionPressed()

    }

  }

  /**
   * Vyhodnocovanie inputov na menenie drahy
   * @param side - side
   */
  public changePathInput(side: Sides): void {

    if (!modes.isTutorial()) return


    if (this.activeEventType === TutorialEventType.changePathTimeout) {

      player.changePath(side)
      this.eventActionPressed()
      tutorialManager.inputsBlocked = false

    }

    if (this.activeEventType === TutorialEventType.forcedChangePath) {

      if (player.hillLinesManager.actualPathIndex === 3 && side !== Sides.LEFT) return
      if (player.hillLinesManager.actualPathIndex === 5 && side !== Sides.RIGHT) return

      player.changePath(side)
      this.eventActionPressed()
      tutorialManager.inputsBlocked = false

    }
    store.commit('TutorialState/SET_BUTTONS', {
      showButtonStart: false,
      showMovementButtons: false,
      glowHorizontal: false,
      glowVertical: false
    })

  }

  public eventActionTrigger(eventType: TutorialEventType): void {

    if (!modes.isTutorial()) return
    this.activeEventType = eventType

    if (eventType === TutorialEventType.failedStart) {

      if (disciplinePhasesManager.attempt > 1) {

        tutorialObjectives.failObjective(TutorialObjectiveIds.start as string)
        this.eventActionTrigger(TutorialEventType.goodStart)
        return

      }
      store.commit('TutorialState/SET_SHOW_BUTTON_START', true)
      timeManager.reset()
      disciplinePhasesManager.resetAttempt()
      disciplinePhasesManager.startDisciplinePhase(DisciplinePhases.start)

      this.resetTypeWrite()
      game.pauseGame()
      tutorialUIChange.setMessage(
        true,
        'tutorialText8-3',
        undefined,
        undefined,
        MobileDetector.isMobile()
      )
      tutorialUIChange.setAnne(true)

    }
    if (eventType === TutorialEventType.goodStart) {

      this.resetTypeWrite()
      game.pauseGame()
      store.commit('TutorialState/SET_BUTTONS', {
        showButtonStart: false,
        showMovementButtons: true,
        glowHorizontal: false,
        glowVertical: true
      })
      tutorialUIChange.setMessage(true, 'tutorialText8-4', undefined, undefined, true)
      tutorialUIChange.setAnne(true, false)

    }
    if (eventType === TutorialEventType.changePathSuccess) {

      this.changePathTween?.kill()
      if (this.firstTurnSuccess) {

        this.activeEventType = TutorialEventType.awaitingEvent
        return

      }
      this.firstTurnSuccess = true
      this.resetTypeWrite()
      return

    }
    if (eventType === TutorialEventType.changePathTimeout) {

      if (this.firstTurnSuccess) {

        this.activeEventType = TutorialEventType.awaitingEvent
        return

      }

      tutorialManager.inputsBlocked = true
      this.firstTurnSuccess = true
      this.resetTypeWrite()
      game.pauseGame()
      store.commit('TutorialState/SET_BUTTONS', {
        showButtonStart: false,
        showMovementButtons: true,
        glowHorizontal: true,
        glowVertical: false
      })
      const yellowTextSpecial = 'leftOrRightArrow'
      tutorialUIChange.setMessage(true, 'tutorialText8-6', undefined, yellowTextSpecial, true)
      tutorialUIChange.setAnne(true, true)

    }
    if (eventType === TutorialEventType.changePathDone) {

      if (this.secondTurn) {

        this.activeEventType = TutorialEventType.awaitingEvent
        return

      }

      if (this.returnTurnSuccess) {

        this.resetTypeWrite()
        game.pauseGame()
        tutorialObjectives.passObjective(TutorialObjectiveIds.changePath as string)
        tutorialUIChange.setMessage(true, 'tutorialText8-10', undefined, undefined, true)
        tutorialUIChange.setAnne(true, true)
        this.secondTurn = true
        return

      }
      this.changePathTween = gsap.to({}, {
        onComplete: () => {

          this.eventActionTrigger(TutorialEventType.forcedChangePath)

        },
        duration: 5
      })
      this.resetTypeWrite()
      game.pauseGame()
      store.commit('TutorialState/SET_BUTTONS', {
        showButtonStart: false,
        showMovementButtons: true,
        glowHorizontal: true,
        glowVertical: false
      })
      tutorialUIChange.setMessage(true, 'tutorialText8-7', undefined, undefined, true)
      tutorialUIChange.setAnne(true, true)
      this.returnTurnSuccess = true

    }
    if (eventType === TutorialEventType.forcedChangePath) {

      store.commit('TutorialState/SET_BUTTONS', {
        showButtonStart: false,
        showMovementButtons: true,
        glowHorizontal: true,
        glowVertical: false
      })
      tutorialManager.inputsBlocked = true
      this.resetTypeWrite()
      game.pauseGame()
      let yellowTextSpecial = 'leftArrow'
      if (player.hillLinesManager.actualPathIndex === 5) yellowTextSpecial = 'rightArrow'
      tutorialUIChange.setMessage(true, 'tutorialText8-9', undefined, yellowTextSpecial, true)
      tutorialUIChange.setAnne(true, true)

    }
    if (eventType === TutorialEventType.pathBlocked) {

      if (this.pathBlockedFirst) {

        this.activeEventType = TutorialEventType.awaitingEvent
        return

      }

      this.pathBlockedFirst = true
      this.resetTypeWrite()
      game.pauseGame()
      tutorialUIChange.setMessage(true, 'tutorialText8-8', undefined, undefined, true)
      tutorialUIChange.setAnne(true, true)

    }
    if (eventType === TutorialEventType.finish) {

      this.resetTypeWrite()
      game.pauseGame()
      let text = 'tutorialText8-12'
      let color = TutorialMessageColors.green
      if (
        !tutorialObjectives.checkIfObjectivePassed(tutorialObjectives.getObjectiveById(TutorialObjectiveIds.slipStream))
      ) {

        text = 'tutorialText8-11'
        color = TutorialMessageColors.red

      }
      tutorialUIChange.setMessage(true, text, color)
      tutorialUIChange.setAnne(true)

    }

  }

  /** Reset typewrite */
  private resetTypeWrite(): void {

    tutorialManager.setTypeWriting(false)
    store.commit('TutorialCoreState/SET_TYPE_WRITER', false)

  }

  public eventActionPressed(): void {

    if (game.paused) game.resumeGame()

    tutorialUIChange.setMessage(false, '')
    tutorialUIChange.setAnne(false)

    if (
      [
        TutorialEventType.changePathDone,
        TutorialEventType.changePathSuccess,
        TutorialEventType.changePathTimeout,
        TutorialEventType.forcedChangePath,
        TutorialEventType.pathBlocked,
        TutorialEventType.goodStart,
        TutorialEventType.failedStart
      ].includes(this.activeEventType)
    ) {

      store.commit('TutorialState/SET_BUTTONS', {
        showButtonStart: false,
        showMovementButtons: false,
        glowHorizontal: false,
        glowVertical: false
      })

      tutorialManager.blockInputsManually()

    }
    if (this.activeEventType === TutorialEventType.failedStart) {

      player.afterStartRunTween?.kill()
      this.failedStartCount += 1

      if (disciplinePhasesManager.attempt <= 1) {

        tutorialManager.setActualSectionId(2)

      } else {

        store.commit('TutorialState/SET_SHOW_BUTTON_START', false)
        const startPhaseManager = disciplinePhasesManager
          .getDisciplinePhaseManager(DisciplinePhases.start) as StartPhaseManager
        startPhaseManager.preparePhase()

      }

    }
    if (this.activeEventType === TutorialEventType.goodStart) {

      tutorialManager.nextSection()
      tutorialManager.blockInputsManually()

    }
    if (this.activeEventType === TutorialEventType.finish) {

      game.prematureFinishGame(disciplinePhasesManager.disciplinePrematureEnd)
      tutorialManager.nextSection()
      tutorialManager.inputsBlocked = true

    }

    this.activeEventType = TutorialEventType.awaitingEvent

  }

  /**
   * Hrac vosiel do slipStreamu
   */
  public inSlipStream(): void {

    if (!modes.isTutorial() || !this.returnTurnSuccess) return

    this.slipStreamFrames += 1
    store.commit(
      'TutorialState/SET_SLIP_STREAM',
      Math.floor((this.slipStreamFrames / fpsManager.fpsLimit)).toFixed(0)
    )
    const seconds = Math.floor(this.slipStreamFrames / fpsManager.fpsLimit * 10) / 10
    store.commit(
      'UiState/SET_SLIP_STREAM_SECONDS',
      seconds.toFixed(1)
    )
    if (this.slipStreamFrames >= 5 * fpsManager.fpsLimit) {

      tutorialObjectives.passObjective(TutorialObjectiveIds.slipStream as string)
      this.eventActionTrigger(TutorialEventType.finish)

    }

  }

}

export const tutorialFlow = new TutorialFlow()
