import type { StartPositionsDataObject } from '@/app/types'
import {
  modes,
  playersManager,
  type PlayerInfo
} from '@powerplay/core-minigames'
import { Opponent } from './Opponent'
import {
  dailyLeagueOpponentsAttributeStrengths,
  fakeOpponents,
  trainingOpponentsAttributeStrengths
} from '@/app/modes/training'
import { disciplinePhasesManager } from '@/app/phases/DisciplinePhasesManager'
import { player } from '../player'

/** Trieda pre spravu protihracov */
export class OpponentsManager {

  /** Pole superov */
  private opponents: Opponent[] = []

  /** pocitadlo framov */
  private frameCounter = 0

  /** true ak v danom mode su opponenti */
  private isActive = true

  /** data z initu pri dennej lige */
  private dailyLeagueFinishData: PlayerInfo[] = []

  /**
   * Prepisanie dat superov
   */
  public rewriteOpponentsData(): void {

    if (!modes.isTutorial() && !modes.isTrainingMode() && !modes.isDailyLeague()) return

    this.setFakeOpponentsData()

  }

  /**
   * Vytvorenie superov
   */
  public create(startData: StartPositionsDataObject): void {

    /*
     * nastavime si, kedy su superi neaktivni
     * if (modes.isDailyLeague()) {
     */

    //     this.isActive = false

    // }

    if (!this.isActive) return

    const opponents = playersManager.players.filter((playerInfo: PlayerInfo, index: number) => {

      return !playerInfo.playable && index <= 7

    })

    // v tutoriali si musime nafakeovat data
    if (modes.isTutorial()) {

      const startDataTutorial = [
        { row: 1,
          pathIndex: 0 },
        { row: 1,
          pathIndex: 2 },
        { row: 1,
          pathIndex: 6 }
      ]
      for (let i = 0; i < 3; i += 1) {

        const playerInfo = opponents[i]
        this.opponents[i] = new Opponent(playerInfo.uuid, i + 1)
        this.opponents[i].create(startDataTutorial[i])

      }
      return

    }
    if (modes.isEventBossFight()) {

      const bossOpponent = opponents[0]
      const { dataForPlayersStartPositions } = disciplinePhasesManager
      const playerPathIndex = dataForPlayersStartPositions[player.uuid].pathIndex
      dataForPlayersStartPositions[bossOpponent.uuid] = {
        row: 1,
        pathIndex: playerPathIndex === 0 ? 2 : 0
      }
      this.opponents[0] = new Opponent(bossOpponent.uuid, 1)
      this.opponents[0].create(dataForPlayersStartPositions[bossOpponent.uuid])
      return

    }

    opponents.forEach((playerInfo: PlayerInfo, index: number) => {

      this.opponents[index] = new Opponent(playerInfo.uuid, index + 1)
      if (modes.isTrainingMode()) {

        this.opponents[index].setAttributeStrength(trainingOpponentsAttributeStrengths[index])

      }
      if (modes.isDailyLeague()) {

        this.opponents[index].setAttributeStrength(dailyLeagueOpponentsAttributeStrengths[index])

      }
      this.opponents[index].create(startData[playerInfo.uuid])

    })

  }

  /**
   * Nastavenie fake dat pre tutorial a trening pre superov
   */
  private setFakeOpponentsData(): void {

    if (modes.isDailyLeague()) {

      for (let i = 1; i <= 7; i += 1) {

        this.dailyLeagueFinishData[i] = playersManager.players[i]

      }

    }

    this.setFakeData()

  }

  /**
   * Vratenie fake dat hracov
   */
  public setFakeData(): void {

    for (let i = 1; i <= 7; i += 1) {

      playersManager.players[i] = fakeOpponents[i - 1]

    }

  }

  /**
   * Vratenie originalnych dat realnych hracov
   */
  public setOriginalData(): void {

    for (let i = 1; i <= 7; i += 1) {

      playersManager.players[i] = this.dailyLeagueFinishData[i]

    }

  }

  /**
   * aktualizovanie superov pred fyzikou
   */
  public updateBeforePhysics(): void {

    if (!this.isActive) return

    this.frameCounter += 1

    this.opponents.forEach((opponent) => {

      opponent.updateBeforePhysics(this.frameCounter)

    })

  }

  /**
   * aktualizovanie superov po fyzikou
   */
  public updateAfterPhysics(): void {

    if (!this.isActive) return

    this.opponents.forEach((opponent) => {

      opponent.updateAfterPhysics()

    })

  }

  /**
   * Odstartovanie vsetkych superov
   */
  public start(): void {

    if (!this.isActive) return

    this.frameCounter = 0

    this.opponents.forEach((opponent) => {

      opponent.setDelayedStartFrames()

    })



  }

  /**
   * Aktualizovanie animacii superov
   * @param delta - delta pre animacie
   */
  public updateAnimations(delta: number): void {

    if (!this.isActive) return

    this.opponents.forEach((opponent) => {

      opponent.updateAnimations(delta)

    })

  }

  /**
   * Getter
   * @returns opponents
   */
  public getOpponents(): Opponent[] {

    return this.opponents

  }

  /**
   * Getter
   * @returns uuids
   */
  public getOpponentsIds(): string[] {

    const uuids = [] as string[]
    playersManager.players.filter((playerInfo: PlayerInfo, index: number) => {

      return !playerInfo.playable && index <= 7

    }).forEach((playerInfo: PlayerInfo) => {

      uuids.push(playerInfo.uuid)

    })
    return uuids

  }

  /**
   * Resetovanie veci
   * @param startData - data pre pozicie opponentov
   */
  public reset(startData: StartPositionsDataObject): void {

    this.frameCounter = 0

    this.opponents.forEach((opponent) => {

      opponent.reset(startData[opponent.uuid])

    })

  }

}

export const opponentsManager = new OpponentsManager()
