<template>
  <biathlon-split-times
    v-if="splitTimeData.length > 0"
    :key="rerenderKey"
    :row-data="splitTimeData"
    :show-player-time="showPlayerTime"
    style="top: 1%; margin: 0.25em 0px;"
  />
</template>

<script lang="ts">
import { BiathlonSplitTimes } from '@powerplay/core-minigames-ui'
import { mapGetters } from 'vuex'
import { modes } from '@powerplay/core-minigames'

import { defineComponent } from 'vue'

export default defineComponent({
  components: {
    BiathlonSplitTimes
  },
  data() {

    return {
      rowData: []
    }

  },
  computed: {
    ...mapGetters({
      splitTimes: 'SplitTimeState/getSplitTimes',
      actualTimeDebug: 'SplitTimeState/getActualTimeDebug',
      splitTimeData: 'SplitTimeState/getSplitTimeData',
      rerenderKey: 'SplitTimeState/getRerenderKey',
      showPlayerTime: 'SplitTimeState/getShowPlayerTime'
    }),
    showSplitTimes() {

      return !modes.isTutorial()

    }
  },
  watch: {
    actualTimeDebug(newVal) {

      console.log('Realny medzicas', newVal)

    }
  },
})

</script>

<style scoped lang="less">
</style>
