import { Sides } from '@/app/types'
import type { Module } from 'vuex'
import type { RootState } from '../index'

export interface BlockingState {
  side: Sides | undefined,
  isVisible: boolean
}

const initialState = () => ({
  side: Sides.LEFT,
  isVisible: false
})

const blockingState: Module<BlockingState, RootState> = {
  state: initialState(),
  getters: {
    getBlockingState: (moduleState: BlockingState) => moduleState
  },
  mutations: {
    RESET: (moduleState: BlockingState) => {

      Object.assign(moduleState, initialState())

    },
    SET_STATE: (moduleState: BlockingState, newState: BlockingState) => {

      moduleState.side = newState.side
      moduleState.isVisible = newState.isVisible

    }
  },
  namespaced: true
}

export default blockingState
