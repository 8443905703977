import {
  fpsManager,
  THREE
} from '@powerplay/core-minigames'

/**
 * Manager rychlosti hraca
 */
export class SpeedManager {

  /** ci je speedManager aktivny */
  private active = false

  /** momentalna rychlost v m/s */
  private actualSpeed = THREE.MathUtils.randFloat(4, 6)

  /** min speed na konci rozbehu, pod tuto rychlost nepojde pocas korculovania */
  private readonly MIN_SPEED = 0

  /**
   * nastavime aktualnu rychlost
   * @param speed - Rychlost
   * @param canGoToZero - Ci moze rychlost klesnut na 0
   */
  public setActualSpeed(speed: number, canGoToZero = false): void {

    this.actualSpeed = speed
    if (canGoToZero) {

      if (this.actualSpeed < 0) this.actualSpeed = 0

    } else if (this.actualSpeed < this.MIN_SPEED) {

      this.actualSpeed = this.MIN_SPEED

    }

    // this.percentSpeed = this.actualSpeed / this.TOP_SPEED

  }

  /**
   * Zmena aktualnej rychlosti
   * @param speed - rychlost o ktoru sa zmeni aktualna
   */
  public changeSpeed(speed: number): void {

    this.setActualSpeed(this.actualSpeed + speed)

  }

  /**
   * Vracia rychlost v metroch za sekundu
   * @returns actualSpeed m/s
   */
  public getActualSpeed(): number {

    return this.actualSpeed

  }

  /**
   * Vracia rychlost v metroch za frame
   * @returns actualSpeedPerFrame m/f
   */
  public getActualSpeedPerFrame(): number {

    return this.actualSpeed / fpsManager.fpsLimit

  }

  /**
   * Setter active
   * @param active - active
   */
  public setActive(active: boolean): void {

    this.active = active

  }

  /**
   * Vratenie, ci je aktivna rychlost
   * @returns True, ak je aktivna
   */
  public isActive(): boolean {

    return this.active

  }

  /** reset */
  public reset(): void {

    this.actualSpeed = 0
    this.active = false

  }

}
