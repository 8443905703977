import type { Module } from 'vuex'
import type { RootState } from '../index'


export interface UiState {
  showTimeKeeper: boolean,
  showSplitTimes: boolean,
  showFinishTopBox: boolean,
  showTrainingLayout: boolean,
  isTraining: boolean,
  slipStreamSeconds: string,
  inSlipStream: boolean,
  hideSlipStreamWarning: boolean
}

const initialState = () => ({
  showTimeKeeper: false,
  showSplitTimes: false,
  showFinishTopBox: false,
  showTrainingLayout: false,
  isTraining: false,
  slipStreamSeconds: '0.0',
  inSlipStream: false,
  hideSlipStreamWarning: false
})

const uiState: Module<UiState, RootState> = {
  state: initialState(),

  namespaced: true,

  getters: {
    getUiState: (moduleState: UiState) => moduleState,
    getShowTimeKeeper: (moduleState: UiState) => moduleState.showTimeKeeper,
    getShowSplitTimes: (moduleState: UiState) => moduleState.showSplitTimes,
    getSlipStreamSeconds: (moduleState: UiState) => moduleState.slipStreamSeconds,
    getInSlipStream: (moduleState: UiState) => moduleState.inSlipStream,
    getHideSlipStreamWarning: (moduleState: UiState) => moduleState.hideSlipStreamWarning
  },

  mutations: {
    RESET: (moduleState: UiState) => {

      Object.assign(moduleState, initialState())

    },
    SET_STATE: (moduleState: UiState, newState: UiState) => {

      if (moduleState.showTimeKeeper !== undefined) {

        moduleState.showTimeKeeper = newState.showTimeKeeper

      }

      if (moduleState.showSplitTimes !== undefined) {

        moduleState.showSplitTimes = newState.showSplitTimes

      }

      if (moduleState.showFinishTopBox !== undefined) {

        moduleState.showFinishTopBox = newState.showFinishTopBox

      }

      if (moduleState.showTrainingLayout !== undefined) {

        moduleState.showTrainingLayout = newState.showTrainingLayout

      }
      if (moduleState.isTraining !== undefined) {

        moduleState.isTraining = newState.isTraining

      }

    },
    SET_FINISH_TOP_BOX_VISIBILITY: (moduleState: UiState, visible: boolean) => {

      moduleState.showFinishTopBox = visible

    },
    SET_TIME_VISIBILITY: (moduleState: UiState, showTimeKeeper: boolean) => {

      moduleState.showTimeKeeper = showTimeKeeper

    },
    SET_SLIP_STREAM_SECONDS: (moduleState: UiState, slipStreamSeconds: string) => {

      moduleState.slipStreamSeconds = slipStreamSeconds

    },
    SET_IN_SLIP_STREAM: (moduleState: UiState, inSlipStream: boolean) => {

      moduleState.inSlipStream = inSlipStream

    },
    SET_HIDE_SLIP_STREAM_WARNING: (moduleState: UiState, hideSlipStreamWarning: boolean) => {

      moduleState.hideSlipStreamWarning = hideSlipStreamWarning

    }
  }
}

export default uiState
