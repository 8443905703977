<template>
  <div class="hud">
    <div class="info">
      <div>Max speed: {{ mainState.maxSpeed.toFixed(2) }}</div>
      <div>Speed: {{ mainState.speed.toFixed(4) }}</div>
      <div>Gradient: {{ mainState.gradient.toFixed(4) }}</div>
      <div>Last sprint bonus: {{ mainState.sprintBonus.toFixed(4) }}</div>
      <div>Forces:</div>
      <div>Forward force: {{ mainState.forces.forwardForce.toFixed(4) }}</div>
      <div>Forward force tuck: {{ mainState.forces.forwardForceTuck.toFixed(4) }}</div>
      <div>Forward force sprint: {{ mainState.forces.forwardForceSprint.toFixed(4) }}</div>
      <div>Backward force: {{ mainState.forces.backwardForce.toFixed(4) }}</div>
      <div>BPMBase: {{ mainState.BPMBase.toFixed(4) }}</div>
    </div>
  </div>
</template>

<script lang="ts">
import { mapGetters } from 'vuex'

import { defineComponent } from 'vue'

export default defineComponent({
  computed: {
    ...mapGetters({
      mainState: 'MainState/getMainState',
      gatesState: 'GatesState/getGateState'
    })
  }
})

</script>

<style lang="less" scoped>
.hud {
  display: flex;
  align-items: center;
  justify-content: center;

  .info {
     display: flex;
     flex-direction: column;
  background: black;
  color: white;
  width:  16.666667%;
  }
}
</style>
