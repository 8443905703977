import {
  game,
  modes,
  THREE,
  gsap,
  audioManager,
  errorManager
} from '@powerplay/core-minigames'
import { trainingTasks } from './TrainingTasks'
import {
  Tasks,
  AudioNames
} from '../../types'
import store from '@/store'
import { trainingConfig } from '@/app/config'
import { linesManager } from '@/app/entities/hill/LinesManager'
import { player } from '@/app/entities/player'

/**
 * Trieda pre treningove checkpointy
 */
export class CheckPointsManager {

  /** Mesh checkpoitov */
  private checkPointsMesh: THREE.Mesh[] = []

  /** Mesh glowov checkpoitov */
  private checkPointsGlowMesh: THREE.Mesh[] = []

  /** Pole indexov drah checkpointov */
  private pathIndexes: number[] = []

  /** Pole prejdenych checkpointov */
  private triggeredCheckpoints: number[] = []

  /** Skupina meshov pre checkpointy */
  private meshesGroup = new THREE.Group()

  /** Prefix pre nazov meshu */
  private readonly MESH_NAME_PREFIX = 'checkpoint-'

  /** Nazov meshu pre checkpoint */
  public readonly CHECKPOINT_MESH_NAME = 'CheckPointStar003'

  /** nazov meshu pre glow checkpointu */
  public readonly CHECKPOINT_GLOW_MESH_NAME = 'CheckpointGlow'

  /**
   * Vytvorenie kopca
   */
  public create(): void {

    if (!modes.isTrainingMode()) return

    console.log('VYTVARAM CHECKPOINTY....')

    this.createCheckpoints()

    console.log('CHECKPOINTY vytvorene....')

  }

  /**
   * Vygenerovanie indexov drah, kde budu checkpointy
   * @returns Pole indexov drah
   */
  private generatePathIndexes(): number[] {

    const indexes = []

    for (let i = 0; i < trainingConfig.checkpointPositions.length / 2; i += 1) {

      // vyberame iba drahy, nie medzi drahami
      const random = THREE.MathUtils.randInt(0, 3) * 2
      indexes.push(random, random)

    }

    console.log('generated path indexes', indexes)
    return indexes

  }

  /**
   * Vytvorenie jedneho konkretneho checkpointu
   * @param mesh - Mesh hviezdy
   * @param glowMesh - Mesh ziary
   * @param pathIndex - Index trate
   * @param index - Index poradia
   */
  private createOneCheckpoint(
    mesh: THREE.Mesh,
    glowMesh: THREE.Mesh,
    pathIndex: number,
    index: number
  ): void {

    mesh.name = `${this.MESH_NAME_PREFIX + index}`
    glowMesh.name = `${this.MESH_NAME_PREFIX + index}Glow`

    const path = linesManager.getPath(pathIndex)
    if (path === undefined) throw new Error(errorManager.showBox(`checkpoint ${index} wrong path`))

    const point = path.getPointAt(trainingConfig.checkpointPositions[index])

    mesh.position.set(point.x, point.y + 1, point.z)
    glowMesh.position.set(point.x, point.y - 1, point.z)
    mesh.scale.set(0.75, 0.75, 0.75)
    glowMesh.scale.set(1.25, 1, 1.25)
    mesh.updateMatrix()
    glowMesh.updateMatrix()

    this.checkPointsMesh.push(mesh)
    this.checkPointsGlowMesh.push(glowMesh)

  }

  /**
   * Vytvorenie checkpointov
   */
  private createCheckpoints(): void {

    const checkPointMesh = game.getMesh(this.CHECKPOINT_MESH_NAME)
    const checkPointGlowMesh = game.getMesh(this.CHECKPOINT_GLOW_MESH_NAME)

    this.pathIndexes = this.generatePathIndexes()

    this.pathIndexes.forEach((value, key) => {

      this.createOneCheckpoint(checkPointMesh.clone(), checkPointGlowMesh.clone(), value, key)

    })

    this.addMeshesInGroup()

    checkPointMesh.visible = false
    checkPointGlowMesh.visible = false

  }

  /**
   * Skontrolovanie, ci hrac triggerol checkpoint
   */
  public checkIfTriggered(): void {

    // const actualPercent = player.hillLinesManager.getActualPercent()

    const actualPercent = player.hillLinesManager.getActualPercent()
    const actualPercents = linesManager.getPercentsOnAllPathsFromOne(
      actualPercent,
      player.hillLinesManager.actualPathIndex
    )

    const pathIndex = player.hillLinesManager.actualPathIndex

    trainingConfig.checkpointPositions.forEach((value, key) => {

      // ak uz triggerol dany checkpoint alebo je na inej drahe, tak ideme dalej
      if (
        this.triggeredCheckpoints.includes(key) ||
        ![pathIndex, pathIndex + 1, pathIndex - 1].includes(this.pathIndexes[key])
      ) return

      const diff = Math.abs(actualPercents[this.pathIndexes[key]] - value)
      if (diff - trainingConfig.checkpointTriggeredOffset < 0) this.triggerCheckpoint(key)

    })

  }

  /**
   * Schovanie checkpointu po prejdeni
   * @param index - Index checkpointu
   */
  private hideObjectAfterCollect(index: number) {

    const mesh1 = this.meshesGroup.getObjectByName(this.MESH_NAME_PREFIX + index)
    const mesh2 = this.meshesGroup.getObjectByName(`${this.MESH_NAME_PREFIX + index}Glow`)
    if (mesh1) mesh1.visible = false // lepsie riesenie
    if (mesh2) mesh2.visible = false // lepsie riesenie

  }

  /**
   * Pridanie meshov do skupiny
   */
  private addMeshesInGroup(): void {

    this.meshesGroup = new THREE.Group()
    this.meshesGroup.name = 'TrackMarkerMeshes'

    this.checkPointsMesh.forEach(mesh => {

      this.meshesGroup.add(mesh)

    })

    this.checkPointsGlowMesh.forEach(mesh => {

      this.meshesGroup.add(mesh)

    })

    game.scene.add(this.meshesGroup)

  }

  /**
   * Prejdenie checkpointom
   * @param index - Index checkpointu
   */
  private triggerCheckpoint(index: number): void {

    this.triggeredCheckpoints.push(index)
    trainingTasks.countTaskValue(Tasks.checkpoints, this.triggeredCheckpoints.length)

    audioManager.play(AudioNames.checkpointGood)
    store.commit('ShinyTextsState/SET_TEXT_BY_NAME', { name: 'plus-10-pc',
      active: true })

    gsap.to({}, {
      onComplete: () => {

        store.commit('ShinyTextsState/SET_TEXT_BY_NAME', { name: 'plus-10-pc',
          active: false })

      },
      duration: 1
    })

    this.hideObjectAfterCollect(index)

  }

  /**
   * Reset checkpointov
   */
  public resetCheckpoints(): void {

    const trackMarkerMeshes = game.scene.getObjectByName('TrackMarkerMeshes')
    if (trackMarkerMeshes) {

      game.scene.remove(trackMarkerMeshes)

    }
    this.checkPointsGlowMesh = []
    this.checkPointsMesh = []
    this.triggeredCheckpoints = []

    this.createCheckpoints()

    this.checkPointsMesh.forEach(mesh => {

      mesh.visible = true
      mesh.matrixAutoUpdate = true

    })

    this.checkPointsGlowMesh.forEach(mesh => {

      mesh.visible = true
      mesh.matrixAutoUpdate = true

    })

  }

}

export const checkpointManager = new CheckPointsManager()
