import { audioManager } from '@powerplay/core-minigames'
import { audioGameConfig } from '../config'
import { player } from '../entities/player'
import {
  AudioNames,
  PlayerAnimationsNames,
  type ObjectWithPlayerAnimationNames,
  Sides,
  type OpponentAudioDistanceData
} from '../types'
import type { Opponent } from '../entities/opponent/Opponent'
import { opponentsManager } from '../entities/opponent/OpponentsManager'

/**
 * metody ktore pomahaju pri spustani zvukov
 */
export class AudioHelper {

  /** Aktualny idnex pre povbudzovanie */
  private actualCheeringIndex = 0

  /** Ci uz boli prvykrat spustene zvuky */
  private playedSoundsFirstTime = false

  /** Aktualna strana zapichnutia palice */
  private stickOnSnowSide = Sides.LEFT

  /** Aktualna strana zapichnutia palice supera */
  private stickOnSnowSideOpponent = Sides.LEFT

  /** Posledna animacia */
  private lastAnimation = PlayerAnimationsNames.happy

  /** Posledna animacia supera */
  private lastAnimationOpponent = PlayerAnimationsNames.happy

  /** Posledne data pre sync zvukov supera podla vzdialenosti */
  private lastOpponentAudioDistanceData?: OpponentAudioDistanceData

  /** Pocitadlo frameov */
  private framesCounter = 0

  /** Posledny cas animacie */
  private lastAnimationTime = -1

  /** synchronizacia animacii a zvukov zapichovania */
  private readonly animationTimesForStickSounds: ObjectWithPlayerAnimationNames = {
    [PlayerAnimationsNames.doublePoling]: {
      first: 0.16,
    },
    [PlayerAnimationsNames.glidePoling]: {
      first: 0.16,
      second: 0.6,
    },
    [PlayerAnimationsNames.strideGlide]: {
      first: 0.13,
      second: 0.36,
    },
    [PlayerAnimationsNames.startRun]: {
      first: 0.06,
      second: 0.21,
    },
    [PlayerAnimationsNames.hillRun]: {
      first: 0.08,
      second: 0.23,
    },
    [PlayerAnimationsNames.changeL]: {
      first: 0,
    },
    [PlayerAnimationsNames.changeR]: {
      first: 0,
    },
  }

  /**
   * pustime movemement audio
   * @param name - movement audio name
   */
  public playMovementAudio(name: AudioNames): void {

    audioManager.play(name)
    this.stopAllMovementAudio(name)

  }

  /**
   * zastavime vsetko audio okrem vynimky
   * @param except - ktore audio nemame zastavovat
   */
  public stopAllMovementAudio(except?: AudioNames): void {

    // if (except !== AudioNames.runSlowpace) {

    //     audioManager.stopAudioByName(AudioNames.runSlowpace)

    // }

    // if (except !== AudioNames.runFastpace) {

    //     audioManager.stopAudioByName(AudioNames.runFastpace)

    // }

    if (except !== AudioNames.skiingGlide) {

      audioManager.stopAudioByName(AudioNames.skiingGlide)

    }

    if (except !== AudioNames.skiingBreak) {

      audioManager.stopAudioByName(AudioNames.skiingBreak)

    }

  }

  /**
   * Aktualizacia hlasitosti divakov
   */
  private updateAudienceVolume(): void {

    const { min, max, decreaseAfterStart, increaseBeforeStart } = audioGameConfig.audienceVolume

    let newVolume = max // sme na zaciatku trate este na stadione, pojde plnou hlasitostou
    const percent = player.hillLinesManager.getActualPercent()

    if (percent >= decreaseAfterStart.start && percent <= decreaseAfterStart.stop) {

      // sme v casti, kde sa znizuje hlasitost
      const percentTemp = (percent - decreaseAfterStart.start) /
                (decreaseAfterStart.stop - decreaseAfterStart.start)
      newVolume = max - (percentTemp * (max - min))

    } else if (percent > decreaseAfterStart.stop && percent < increaseBeforeStart.start) {

      // sme v casti, kde je najnizsia hlasitost
      newVolume = min

    } else if (percent >= increaseBeforeStart.start && percent <= increaseBeforeStart.stop) {

      // sme v casti, kde sa zvysuje hlasitost
      const percentTemp = (percent - increaseBeforeStart.start) /
                (increaseBeforeStart.stop - increaseBeforeStart.start)
      newVolume = min + (percentTemp * (max - min))

    }

    audioManager.changeAudioVolume(AudioNames.audienceNoise, newVolume)

  }

  /**
   * Najdenie najblizsieho supera
   * @returns Najblizsi super
   */
  private getClosestOpponent(): OpponentAudioDistanceData {

    const position = player.getPosition()
    let closestOpponent: Opponent | undefined
    let minDistance = 9999999999
    const opponents = opponentsManager.getOpponents()

    opponents.forEach((opponent) => {

      const oppPos = opponent.getPosition().distanceTo(position)
      if (minDistance > oppPos) {

        minDistance = oppPos
        closestOpponent = opponent

      }

    })

    const opponent = closestOpponent ?? opponents[0]

    return {
      opponent,
      distance: minDistance
    }

  }

  /**
   * Zistenie hlasitosti pre superov pohyb
   * @param distance - Vzdialenost od hraca
   * @returns Hlasitost
   */
  private getVolumeOpponentMovement(distance: number): number {

    const { maxDistance, minDistance, maxVolume } = audioGameConfig.opponentMovement

    let volume = maxVolume

    if (distance > maxDistance) {

      volume = 0

    } else if (distance > minDistance) {

      volume = (1 - ((distance - minDistance) / (maxDistance - minDistance))) * maxVolume

    }

    // console.log('hlasitost supera', volume, distance)
    return volume

  }

  /**
   * Skontrolovanie a poriesenie zvukov pre movement supera
   */
  private checkOpponentMovementAudio(): void {

    // najskor si najdeme najblizsie supera raz za X frameov
    if (
      this.framesCounter % audioGameConfig.opponentMovement.freezeOneOpponentFrames === 0 ||
            this.lastOpponentAudioDistanceData === undefined
    ) {

      this.lastOpponentAudioDistanceData = this.getClosestOpponent()

    } else {

      // iba aktualizujeme distance
      this.lastOpponentAudioDistanceData.distance = this.lastOpponentAudioDistanceData.
        opponent.getPosition().distanceTo(player.getPosition())

    }

    const { opponent, distance } = this.lastOpponentAudioDistanceData
    const animation = opponent.animationsManager.getActualAnimation() as PlayerAnimationsNames
    const volume = this.getVolumeOpponentMovement(distance)

    // musime skontrolovat, ci sa nezmenila animacia, potom davame naspat na LEFT
    if (animation !== this.lastAnimationOpponent) this.stickOnSnowSideOpponent = Sides.LEFT

    const times = this.animationTimesForStickSounds[animation]
    if (times === undefined) return

    const time = opponent.animationsManager.getAnimationActualTime(animation)

    if (times.second && time > times.second && this.stickOnSnowSideOpponent === Sides.RIGHT) {

      audioManager.stopAudioByName(AudioNames.stickOnSnowOpponent)
      audioManager.play(AudioNames.stickOnSnowOpponent)
      this.stickOnSnowSideOpponent = Sides.LEFT

    } else if (
      time > times.first &&
      (!times.second || time < times.second) &&
      this.stickOnSnowSideOpponent === Sides.LEFT
    ) {

      audioManager.stopAudioByName(AudioNames.stickOnSnowOpponent)
      audioManager.play(AudioNames.stickOnSnowOpponent)
      this.stickOnSnowSideOpponent = Sides.RIGHT

    }

    // zmenime hlasitost podla vzdialenosti
    audioManager.changeAudioVolume(AudioNames.stickOnSnowOpponent, volume)

    this.lastAnimationOpponent = animation

  }

  /**
   * Skontrolovanie a poriesenie zvukov pre movement
   */
  private checkMovementAudio(): void {

    const animation = player.animationsManager.getActualAnimation() as PlayerAnimationsNames

    // musime skontrolovat, ci sa nezmenila animacia, potom davame naspat na LEFT
    if (animation !== this.lastAnimation) this.stickOnSnowSide = Sides.LEFT

    const times = this.animationTimesForStickSounds[animation]

    if (times === undefined) return

    const time = player.animationsManager.getAnimationActualTime(animation)

    // ked dana animacia ma iba prvu cast pre zvuk, musime to prepnut naspat po dokonceni loopu
    if (
      time < this.lastAnimationTime &&
            this.stickOnSnowSide === Sides.RIGHT &&
            times.second === undefined
    ) {

      this.stickOnSnowSide = Sides.LEFT

    }

    // riesenie zmeny kontroly na dalsiu cast animacie
    if (times.second && time > times.second && this.stickOnSnowSide === Sides.RIGHT) {

      audioManager.stopAudioByName(AudioNames.stickOnSnowPlayer)
      audioManager.play(AudioNames.stickOnSnowPlayer)
      this.stickOnSnowSide = Sides.LEFT

    } else if (
      time > times.first &&
      (!times.second || time < times.second) &&
      this.stickOnSnowSide === Sides.LEFT
    ) {

      audioManager.stopAudioByName(AudioNames.stickOnSnowPlayer)
      audioManager.play(AudioNames.stickOnSnowPlayer)
      this.stickOnSnowSide = Sides.RIGHT

    }

    this.lastAnimationTime = time
    this.lastAnimation = animation

  }

  /**
   * Aktualizovanie zvukov - divaci, povzbudzovania a pod
   */
  public update(): void {

    this.checkMovementAudio()
    this.checkOpponentMovementAudio()
    this.updateAudienceVolume()
    this.updateCheerings()

    this.framesCounter += 1

  }

  /**
   * Riesenie povzbudzovania
   */
  private updateCheerings(): void {

    // ak sme uz po poslednom povzbudzovani, tak sa neriesi nic a vsetky zvuky ostavaju na 0
    if (this.actualCheeringIndex >= audioGameConfig.cheerings.length) return

    let volume = 0
    const percent = player.hillLinesManager.getActualPercent()

    const cheeringData = audioGameConfig.cheerings[this.actualCheeringIndex]

    // zvysujeme hlasitost
    if (percent >= cheeringData.preStart && percent < cheeringData.start) {

      if (!this.playedSoundsFirstTime) {

        this.playedSoundsFirstTime = true
        audioManager.play(AudioNames.audienceHype)
        audioManager.play(AudioNames.audienceBells)

      }

      volume = (percent - cheeringData.preStart) / (cheeringData.start - cheeringData.preStart)

    }

    // nizsie veci by sa riesili zbytocne, pokial este nemame ani pustene zvuky
    if (!this.playedSoundsFirstTime) return

    // udrziavame top hlasitost
    if (percent >= cheeringData.start && percent <= cheeringData.stop) {

      volume = 1

    }

    // znizujeme hlasitost
    if (percent > cheeringData.stop && percent <= cheeringData.postStop) {

      volume = 1 - ((percent - cheeringData.stop) / (cheeringData.postStop - cheeringData.stop))

    }

    // sme uz na konci, takze davame na dalsi index, aby sme zbytocne nekontrolovali toto
    if (percent > cheeringData.postStop) this.actualCheeringIndex += 1

    audioManager.changeAudioVolume(AudioNames.audienceHype, volume)
    audioManager.changeAudioVolume(AudioNames.audienceBells, volume)

  }

  /**
   * Resetovanie veci
   */
  public reset(): void {

    this.actualCheeringIndex = 0
    this.playedSoundsFirstTime = false
    this.stickOnSnowSide = Sides.LEFT
    this.stickOnSnowSideOpponent = Sides.LEFT
    this.lastAnimation = PlayerAnimationsNames.happy
    this.lastAnimationOpponent = PlayerAnimationsNames.happy
    this.lastOpponentAudioDistanceData = undefined
    this.framesCounter = 0
    this.lastAnimationTime = -1

  }

}

export const audioHelper = new AudioHelper()
