/** Mena vsetkych modelov, ktore sa pouzivaju v minihre */
export enum ModelsNames {
  skier = 'skier',
  hill = 'hill',
  trackLines = 'TrackLines',
  cameraBoxes = 'cameraBoxesCC',
  checkpoint = 'marker'
}

/** Mena vsetkych textur, ktore sa pouzivaju v minihre */
export enum TexturesNames {
  skierRacePrefix = 'skier_race_',
  skierRaceWhiteMan = 'male/skier_race_white',
  skierRaceMulattoMan = 'male/skier_race_mulatto',
  skierRaceBlackMan = 'male/skier_race_black',
  skierRaceWhiteWoman = 'female/skier_race_white',
  skierRaceMulattoWoman = 'female/skier_race_mulatto',
  skierRaceBlackWoman = 'female/skier_race_black',
  skierClothes = 'skier_clothes',
  skierClothesOpponent1 = 'skier_clothes_opponent_1',
  skierClothesOpponent2 = 'skier_clothes_opponent_2',
  skierClothesOpponent3 = 'skier_clothes_opponent_3',
  skierClothesOpponent4 = 'skier_clothes_opponent_4',
  skierClothesOpponent5 = 'skier_clothes_opponent_5',
  skierClothesOpponent6 = 'skier_clothes_opponent_6',
  skierClothesOpponent7 = 'skier_clothes_opponent_7',
  hill = 'Atlas',
  transparent = 'TransparentTexture',
  mountain = 'mountain',
  skybox = 'Skybox',
  ads = 'logo',
  flags = 'flags',
  track = 'track',
  people = 'People',
  lightmapHill = 'LightmapHill',
  staticPeople = 'StaticPeople',
  rocks = 'Rock',
  startAds = 'logoTransparent',
  snowParticle = 'snowSSbase',
  screen = 'Screen',
  marker = 'marker'
}

/** Mena vsetkych materialov, ktore sa pouzivaju v minihre */
export enum MaterialsNames {
  skier = 'skier',
  skierOpponentPrefix = 'skier_opponent_',
  skierOpponent1 = 'skier_opponent_1',
  skierOpponent2 = 'skier_opponent_2',
  skierOpponent3 = 'skier_opponent_3',
  skierOpponent4 = 'skier_opponent_4',
  skierOpponent5 = 'skier_opponent_5',
  skierOpponent6 = 'skier_opponent_6',
  skierOpponent7 = 'skier_opponent_7',
  hill = 'Atlas1',
  transparent = 'TransparentTexture',
  mountain = 'mountain',
  ads = 'ads',
  flags = 'flags',
  track = 'track',
  people = 'People',
  staticPeople = 'StaticPeople',
  onlyVertexColor = 'OnlyVertexColor',
  rocks = 'rocks',
  startAds = 'StartAds',
  screen = 'Screen',
  checkpointGlow = 'CheckpointGlow'
}

/** Mena vsetkych animacii hraca */
export enum PlayerAnimationsNames {
  lunge = 'lunge',
  lungeToEmotion = 'lunge to emotion',
  prepare = 'prepare',
  prepare2 = 'prepare 2',
  startPosition = 'start position',
  start = 'start',
  strideGlide = 'stride glide',
  doublePoling = 'double poling',
  glidePoling = 'glide poling',
  stop1 = 'stop 1',
  stop2 = 'stop 2',
  downhillLeft = 'zjazd L',
  downhillRight = 'zjazd R',
  downhill = 'zjazd',
  neutral = 'neutral',
  happy = 'happy',
  startRun = 'start run R',
  changeL = 'change L',
  changeR = 'change R',
  hillRun = 'hill run R',
  final1 = 'final 1',
  final2 = 'final 2'
}

/** Konfig pre zakladne fyzicke veci */
export interface GameConfig {
  linearDamping: number
  playerMass: number
  frictionHillPlayer: number
  restitutionHillPlayer: number
  frictionEquationRelaxationHillPlayer: number
  frictionEquationStiffnessHillPlayer: number
  contactEquationRelaxationHillPlayer: number
  contactEquationStiffnessHillPlayer: number
}

/**
 * Specialne data z init requestu
 */
export interface SpecialDataFromInit {

  split: number[]

}
