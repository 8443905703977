import type { AnimationsSettingRepository } from '@powerplay/core-minigames'
import { PlayerAnimationsNames } from '../types'
/**
 * Konfig pre animacie
 */
export const animationsConfig: AnimationsSettingRepository = {

  [PlayerAnimationsNames.lunge]: {
    loop: false,
    starting: false
  },
  [PlayerAnimationsNames.lungeToEmotion]: {
    loop: false,
    starting: false
  },
  [PlayerAnimationsNames.strideGlide]: {
    loop: true,
    starting: false
  },
  [PlayerAnimationsNames.doublePoling]: {
    loop: true,
    starting: false
  },
  [PlayerAnimationsNames.prepare]: {
    loop: true,
    starting: true
  },
  [PlayerAnimationsNames.prepare2]: {
    loop: true,
    starting: false
  },
  [PlayerAnimationsNames.glidePoling]: {
    loop: true,
    starting: false
  },
  [PlayerAnimationsNames.downhillLeft]: {
    loop: true,
    starting: false
  },
  [PlayerAnimationsNames.start]: {
    loop: false,
    starting: false
  },
  [PlayerAnimationsNames.downhillRight]: {
    loop: true,
    starting: false
  },
  [PlayerAnimationsNames.downhill]: {
    loop: true,
    starting: false
  },
  [PlayerAnimationsNames.neutral]: {
    loop: false,
    starting: false
  },
  [PlayerAnimationsNames.happy]: {
    loop: false,
    starting: false
  },
  [PlayerAnimationsNames.startPosition]: {
    loop: true,
    starting: false
  },
  [PlayerAnimationsNames.stop1]: {
    loop: true,
    starting: false
  },
  [PlayerAnimationsNames.stop2]: {
    loop: true,
    starting: false
  },
  [PlayerAnimationsNames.startRun]: {
    loop: true,
    starting: false
  },
  [PlayerAnimationsNames.hillRun]: {
    loop: true,
    starting: false
  },
  [PlayerAnimationsNames.changeL]: {
    loop: true,
    starting: false
  },
  [PlayerAnimationsNames.changeR]: {
    loop: true,
    starting: false
  },
  [PlayerAnimationsNames.final1]: {
    loop: true,
    starting: false
  },
  [PlayerAnimationsNames.final2]: {
    loop: true,
    starting: false
  }
}
