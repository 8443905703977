<template>
  <game-cross-country-component />
</template>

<script lang="ts">

import { defineComponent } from 'vue'
import GameCrossCountryComponent from './components/GameCrossCountryComponent.vue'

export default defineComponent({
  components: {
    GameCrossCountryComponent
  },
})

</script>
