/**
 * Konfig pre opponenta/ov
 */
export const opponentConfig = {

  /** defaultMaxSpeedBar */
  defaultMaxSpeedBarValue: 100,

  /** kolko musi byt naplneny sprint bar aby sa zapol sprint */
  sprintTurnOnValue: 95,

  /** kolko musi byt naplneny sprint bar aby sa zapol sprint pred cielom */
  sprintTurnOnValueFinish: 10,

  /** kolko metrov pred koncom sa zapina sprint */
  beforeFinishSprintMeters: 50,

  /** kolko metrov pred koncom sa zapina zmena drahy v celej drahe */
  beforeFinishChangePathMeters: 100,

  /** pocty framov medzi ktorymi je random delay startu opponenta */
  startFrames: {

    from: 8,
    to: 10

  },

  /** Kazdych X frameov bude davat opponent input pri rozbehu - random v rozmedzi */
  startRunAutoInputsFrames: {
    min: 4,
    max: 7
  },

  /** kedy vypiname sprint pred cielom */
  sprintOffTresholdFinish: 0,

  /** kedy vypiname sprint */
  sprintOffTreshold: 15,

  /** kolko framov po zmene drahy cakat */
  changePathFramesCondition: 60,

  /** kolko musi byt opponent od hraca, aby sa aplikovala ina yCorrection */
  yPossitionCorrectionDistance: 10,

  /** yCorrection pre protihraca */
  yOpponentFarPosition: 0.02,

  /** divider na fake casy v cieli */
  fakeTimeMetersDivider: 8,

  /** aka rychlost je potrebna na zacatie zjazdu */
  tuckStartSpeed: 6,

  /** pri akej rychlosti vypnut zjazd */
  smallestSpeedToTuck: 7,

  /** hodnoty pre random generevanie speedBarModifier */
  speedBarModifierRange: [
    { min: 9,
      max: 11 },
    { min: 5,
      max: 8 },
    { min: 0,
      max: 4 },
  ],

  /** pocet framov na sprint cooldown */
  sprintCooldownFrames: 60

}
