import {
  timeManager,
  audioManager,
  CustomEvents,
  corePhasesManager,
  playersManager,
  gsap,
  modes,
  TimesTypes,
  fpsManager,
  type PlayerInfo,
  THREE
} from '@powerplay/core-minigames'
import {
  type DisciplinePhaseManager,
  AudioNames,
  DisciplinePhases,
  type StartPositionsDataObject
} from '../types'
import { StartPhaseManager } from './StartPhase/StartPhase'
import { FinishPhaseManager } from './FinishPhase/FinishPhase'
import { player } from '../entities/player'
import { gameConfig } from '../config'
import { RunningPhase } from './RunningPhase/RunningPhase'
import store from '@/store'
import { endManager } from '../EndManager'
import { trainingTasks } from '../modes/training'
import { opponentsManager } from '../entities/opponent/OpponentsManager'

/**
 * Trieda pre spravu faz
 */
export class DisciplinePhasesManager {

  /** aktualna faza */
  public actualPhase = 0

  /** pokus */
  public attempt = 1

  /** pole vytvorenych faze managerov */
  private phaseManagers: DisciplinePhaseManager[] = []

  /** distance between start and end */
  private distanceToEnd = 0

  /** tween na nastartovanie fazoveho managera */
  private startDisciplineTween!: gsap.core.Tween

  /** hlasitost pre divakov */
  public audienceSoundVolume = { value: 1 }

  /** ci bola hra ukoncena predcasne */
  public prematureEnded = false

  /** Data pre startovacie pozicie */
  public dataForPlayersStartPositions: StartPositionsDataObject = {}

  /**
   * Vytvorenie a nastavenie veci
   */
  public create(): void {

    this.createAllPhases()

  }

  /**
   * Vytvorenie menegerov faz
   */
  public createAllPhases(): void {

    this.phaseManagers[DisciplinePhases.start] = new StartPhaseManager(() => {

      this.startDisciplinePhase(DisciplinePhases.running)
      timeManager.setActive(TimesTypes.game, true)
      timeManager.changeTimeSpeed(gameConfig.timeSpeedRunning)

    })

    this.phaseManagers[DisciplinePhases.running] = new RunningPhase(() => {

      this.startDisciplinePhase(DisciplinePhases.finish)

    })

    this.phaseManagers[DisciplinePhases.finish] = new FinishPhaseManager(() => {

      this.actualPhase++
      console.log('dispatch end')
      if (DisciplinePhases[this.actualPhase]) {

        console.log('dispatch end')
        window.dispatchEvent(new CustomEvent(CustomEvents.finishDisciplinePhase))

        store.commit('WaitingState/SET_STATE', {
          isWaiting: true
        })

      }

    })

  }

  /**
   * Zistenie, ci jedna z faza je aktualna faza
   * @param phase - Pole faz na skontrolovanie
   * @returns True, ak je jedna z faz aktualna
   */
  public oneOfPhaseIsActual(phases: DisciplinePhases[]): boolean {

    return phases.includes(this.actualPhase)

  }

  public getActualPhase(): DisciplinePhases {

    return this.actualPhase

  }

  /**
   * Vratenie konkretneho fazoveho menezera
   * @param phase - Faza
   * @returns Fazovy menezer
   */
  public getDisciplinePhaseManager(phase: DisciplinePhases): DisciplinePhaseManager {

    return this.phaseManagers[phase]

  }

  /**
   * Spustenie fazy
   * @param phase - Cislo fazy
   */
  public startDisciplinePhase(phase: DisciplinePhases): void {

    this.actualPhase = phase
    this.phaseManagers[phase].startPhase()

  }

  /**
   * Update aktualnej fazy kazdy frame
   */
  public update(): void {

    this.updateAudienceSound()

    this.phaseManagers[this.actualPhase]?.update()

  }

  /**
   * zmen hlasitost divakov podla vzdialenosti
   */
  private updateAudienceSound(): void {

    // audioManager.changeAudioVolume(AudioNames.audienceNoise, this.audienceSoundVolume.value)

  }

  /**
   * resetovanie hry
   */
  public resetAttempt(): void {

    if (modes.isTutorial()) this.attempt += 1

    player.reset(this.dataForPlayersStartPositions[playersManager.getPlayer().uuid])
    opponentsManager.reset(this.dataForPlayersStartPositions)

    this.actualPhase = 0
    this.distanceToEnd = 0

  }

  /**
   * rekurzivne ukoncime vsetky fazy
   */
  public disciplinePrematureEnd = async (): Promise<void> => {

    this.actualPhase = DisciplinePhases.end

    audioManager.stopAllAudio()
    audioManager.play(AudioNames.audienceNoise, undefined, undefined, 1)

    corePhasesManager.disciplineActualAttempt = corePhasesManager.disciplineAttemptsCount
    // kvoli dennej lige musime dat naspat originalnych superov, aby sa zobrazili v konecnej listine
    if (modes.isDailyLeague()) opponentsManager.setOriginalData()

    playersManager.setStandings(1)
    console.log('STANDINGS', playersManager.getStandings())

    fpsManager.pauseCounting()

    const isFinished = false

    // pri treningu musime dokoncit udaje
    trainingTasks.saveLastTasksValues()

    // posleme udaje
    endManager.sendLogEnd()
    endManager.sendSaveResult()

    // reset states
    await store.dispatch('clearStateAll')

    store.commit('WaitingState/SET_STATE', {
      isWaiting: true
    })
    if (!isFinished || corePhasesManager.firstInstructions) {

      store.commit('TrainingResultsState/SET_TRAIN_AGAIN_DISABLED', true)

    }
    // stopneme vsetky animacne callbacky
    if (player.animationsManager) player.animationsManager.removeCallbacksFromAllAnimations()

  }

  /**
   * Nastartovanie disciplinoveho fazoveho managera
   */
  public setStartPhase = (): void => {

    // musime tu dat mensi delay, lebo mozeme skipovat este nejake fazy predtym
    this.startDisciplineTween = gsap.to({}, {
      duration: modes.isTutorial() ? 0 : 0.2,
      onComplete: () => {

        const phase = DisciplinePhases.start
        this.startDisciplinePhase(phase)

      }
    })

  }

  /**
   * Nastavenie startovacich pozicii pre vsetkych hracov
   */
  public setStartPositionsForPlayers(): void {

    // musime si presunut data do noveho pola, aby sa neprepisovali hodnoty z packages
    const players: PlayerInfo[] = []
    players.push(...playersManager.players.filter((_playerInfo: PlayerInfo, index: number) => {

      return index <= 7

    }).map((val) => val))

    const playerRankingPoints = playersManager.getPlayer().rankingPoints || 0

    // randomizovanie pozicii v treningu
    if (modes.isTrainingMode() || modes.isDailyLeague()) {

      players.forEach((playerInfo) => {

        playerInfo.rankingPoints = playerInfo.playable ?
          playerRankingPoints :
          THREE.MathUtils.randInt(playerRankingPoints - 10, playerRankingPoints + 10)

      })

    }

    if (modes.isBossCompetition()) {

      // zoradime si hracov podla AP a prejdeme si vsetkych hracov a podla uuid nastavime pole
      players.sort((a: PlayerInfo, b: PlayerInfo): number => {

        if (a.attribute.total === undefined || b.attribute.total === undefined) return -1

        return b.attribute.total - a.attribute.total

      })

    } else {

      // zoradime si hracov podla RP a prejdeme si vsetkych hracov a podla uuid nastavime pole
      players.sort((a: PlayerInfo, b: PlayerInfo): number => {

        if (a.rankingPoints === undefined || b.rankingPoints === undefined) return -1

        return b.rankingPoints - a.rankingPoints

      })

    }

    players.forEach((playerInfo: PlayerInfo, index: number) => {

      const position = index + 1
      // normalizovana pozicia je kvoli 2 radom
      const positionNormalized = position > 4 ? position - 4 : position
      this.dataForPlayersStartPositions[playerInfo.uuid] = {
        row: position < 5 ? 1 : 2,
        pathIndex: 2 * (positionNormalized - 1)
      }

    })

    if (modes.isEventBossFight()) {

      const randomForPosition = Math.random()
      const startDataPlayer = { row: 1,
        pathIndex: randomForPosition > 0.5 ? 0 : 2 }

      this.dataForPlayersStartPositions[playersManager.getPlayer().uuid] = startDataPlayer

    }

    if (
      modes.isTrainingMode() &&
      this.dataForPlayersStartPositions[playersManager.getPlayer().uuid].row === 1
    ) {

      const opponentUuid = (Object.keys(this.dataForPlayersStartPositions) as
        (keyof typeof this.dataForPlayersStartPositions)[]).find((key) => {

        return key !== playersManager.getPlayer().uuid &&
          this.dataForPlayersStartPositions[key].pathIndex ===
          this.dataForPlayersStartPositions[playersManager.getPlayer().uuid].pathIndex

      })
      this.dataForPlayersStartPositions[playersManager.getPlayer().uuid].row = 2
      if (opponentUuid) {

        this.dataForPlayersStartPositions[opponentUuid].row = 1

      }

    }

  }

  /**
   * Reinstancovanie manazerov
   */
  public reset(): void {

    this.dataForPlayersStartPositions = {}
    this.setStartPositionsForPlayers()
    this.createAllPhases()
    this.resetAttempt()
    this.prematureEnded = false

  }

}

export const disciplinePhasesManager = new DisciplinePhasesManager()
