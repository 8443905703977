import type { Opponent } from '../entities/opponent/Opponent'
import type { PlayerAnimationsNames } from './config'

/** Nazvy zvukov */
export enum AudioNames {

  spriteCommentators = 'commentators',
  spriteLooped = 'looped',
  spriteNotLooped = 'notLooped',
  spriteAudienceSpecial = 'audienceSpecial',

  audienceHype = 'audience_hype',
  audienceNoise = 'audience_noise_var_02',
  audienceSad = 'audience_sad2',
  audienceYay = 'audience_yay3',
  audienceBells = 'bells_var02',
  audienceBells1 = 'bells_var021',

  ready = 'vo_ready',
  startShot = 'start_shot',
  heartbeat = 'BI_heartbeat',
  heavyBreathing = 'BI_heavy_breathing',
  checkpointGood = 'DH_checkpoint_good',

  skiingBreak = 'BI_skiing_break',
  skiingGlide = 'BI_skiing_glide2',
  stickOnSnowPlayer = 'stick_pole_on_snow_soft_player4',
  stickOnSnowOpponent = 'stick_pole_on_snow_soft_opponent4',

  commentBeforeFinish = 'before_finish',
  commentBeforeFinish2 = 'before_finish2',
  commentAfterStart = 'after_start',
  commentAfterStart2 = 'after_start2',
  commentFinalResults = 'final_results',
  commentFinalResults2 = 'final_results2',
  commentFinish1 = 'finish1',
  commentFinish2 = 'finish2',
  commentFinish3 = 'finish3',
  commentFinish4 = 'finish4',
  commentIntro = 'intro',
  commentIntro2 = 'intro2',
  commentSplitTimes1Rank1 = 'split_times_1_rank_1',
  commentSplitTimes1Rank23 = 'split_times_1_rank_2_3',
  commentSplitTimes1Rank45 = 'split_times_1_rank_4_5',
  commentSplitTimes1Rank6 = 'split_times_1_rank_6',
  commentSplitTimes2Rank1 = 'split_times_2_rank_1',
  commentSplitTimes2Rank23 = 'split_times_2_rank_2_3',
  commentSplitTimes2Rank45 = 'split_times_2_rank_4_5',
  commentSplitTimes2Rank6 = 'split_times_2_rank_6'

}

/** Kategorie zvukov */
export enum AudioCategories {

  misc = 'misc',
  audience = 'audience',
  countdown = 'countdown',
  character = 'character',
  running = 'running',
  commentators = 'commentators',
  startend = 'startend',
  checkpoint = 'checkpoint',
  sprite = 'sprites'

}

/** skupiny zvukov */
export enum AudioGroups {

  commentators = 'commentators'

}

/** Typy pre zmenu hlasitosti divakov */
export enum AudienceChangeVolumePlaceTypes {

  start = 'start',
  stop = 'stop'

}

/** Typy pre zmenu hlasitosti divakov */
export enum AudienceChangeVolumeTypes {

  increase = 'increase',
  decrease = 'decrease'

}

/** Typy sektorov pre hlasitost */
export enum AudienceVolumeSectorTypes {

  full = 'full',
  graduallyDecrease = 'graduallyDecrease',
  graduallyIncrease = 'graduallyIncrease',
  min = 'min'

}

/** Objekt s datami pre synchronizaciu zvukov s animaciami */
export interface AnimationSoundData {

  first: number,
  firstEnd?: number,
  second?: number,
  secondEnd?: number

}

/** Objekt naplneny datami pre synchronizaciu zvukov a animacii */
export type ObjectWithPlayerAnimationNames =
    { [key in PlayerAnimationsNames]?: AnimationSoundData }

/** Data pre pocitanie hlasitosti pohybu supera vzhladom na vzdialenost */
export interface OpponentAudioDistanceData {

  opponent: Opponent,
  distance: number

}

