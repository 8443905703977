import {
  CameraStates,
  THREE
} from '@powerplay/core-minigames'

/**
 * Konfig pre kameru pre training
 */
export const trainingConfig = {

  /** Nastavenie pozicii a pohybov kamery pre vsetky stavy */
  tweenSettingsForCameraStates: {
    [CameraStates.intro]: {
      startPosition: new THREE.Vector3(20.1984329, 10.342081, -42.3437538),
      endPosition: new THREE.Vector3(-7.1556477, 6.1026368, -33.79144668),
      startRotation: new THREE.Vector3(1.721604953, 0.20508, -2.78302),
      endRotation: new THREE.Vector3(1.5835426, -0.186939, 2.67015444),
      duration: 5,
      notSkippableFrames: 20,
      fov: 90
    },
    [CameraStates.intro2]: undefined,
    [CameraStates.table]: {
      startPosition: new THREE.Vector3(100, 55, -10),
      startLookAt: new THREE.Vector3(-25, 1, 50),
      endPosition: new THREE.Vector3(101, 56, -11),
      duration: 5
    },
    [CameraStates.disciplineIntro]: undefined,
    [CameraStates.disciplineIntroSecond]: undefined,
    [CameraStates.discipline]: undefined,
    [CameraStates.disciplineOutro]: {
      startPosition: new THREE.Vector3(17, 1.5, -25),
      endPosition: new THREE.Vector3(17, 1.5, -44),
      duration: 4,
      durationZoom: 3,
      zoom: 1.5,
      zoomEnd: 1.5,
      fov: 40,
      offsetCamera: false
    },
    [CameraStates.static]: undefined
  },
  distanceFromGround: 1.5,

  /** Max pocet frameov pre 100% ciel treningu so zavetrim */
  maxFramesSlipstream: 300,

  /** Percenta za poziciu */
  percentPerPosition: [1, 0.9, 0.75, 0.6, 0.45, 0.3, 0.15, 0],

  /** Kde sa budu nachadzat checkpointy v percentach trate */
  checkpointPositions: [0.16, 0.19, 0.28, 0.31, 0.4, 0.43, 0.57, 0.6, 0.82, 0.85],

  /** Offset pre zobratie checkpointu v percentach trate */
  checkpointTriggeredOffset: 0.002

}
