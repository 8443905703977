import {
  finishPhaseConfig,
  gameConfig,
  opponentConfig,
  pathsConfig,
  velocityConfig
} from '@/app/config'
import { endManager } from '@/app/EndManager'
import {
  AudioNames,
  type StartPositionsData
} from '@/app/types'
import {
  audioManager,
  game,
  modes,
  THREE
} from '@powerplay/core-minigames'
import type { Athlete } from '../athlete'
import { TriggersManager } from '../athlete/triggers/TriggersManager'
import { opponentsManager } from '../opponent/OpponentsManager'
import { player } from '../player'
import { linesManager } from './LinesManager'

/**
 * Metoda na vytvorenie ciary s ktorou sa neskor bude manipulovat na jazdenie
 */
export class HillLinesCreator {

  /** Object 3d na zmenu jazdy a smeru */
  private object3d = new THREE.Object3D()

  /** vektor na ktory sa object3d pozera */
  private lookingAt = new THREE.Vector3()

  /** Percento kde je aktualne hrac */
  private actualPercent = 0

  /** Percento kde bol naposledy hrac */
  private lastPercent = 0

  /** Percento kde bol v cieli hrac */
  private finishPercent = 0

  /** Kolko percent je 1 meter na krivke */
  public oneMeterInPercent = 0

  /** trat hraca */
  public playerPath?: THREE.CurvePath<THREE.Vector3>

  /** index aktualnej trate */
  public actualPathIndex = 0

  /** aktivna cesta hraca */
  public finishPhaseStarted = false

  /** Ci uz bola prehrata animacia lunge alebo nie */
  private lungeStarted = false

  /** Cislo radu, kde zacina hrac */
  private rowStart = 0

  /** Index drahy, v ktorej zacina hrac */
  private pathIndexStart = 0

  /** callback pre lunge */
  private callbackLunge: () => unknown
  /** raycast z hraca */
  private playerRaycast = new THREE.Raycaster()

  /** vektor osi Y pre vypocty */
  private readonly axisY = new THREE.Vector3(0, 1, 0 )

  /** pocitadlo framov prechodu drahy */
  public changePathTransitionFrames = 0

  /** percento pozicie v starej drahe */
  private oldActualPercent = 0

  /** stara draha z ktorej prechadzame */
  private oldPlayerPath?: THREE.CurvePath<THREE.Vector3>

  /** triggers manager */
  private triggersManager = new TriggersManager()

  /** ci uz bol aktivovany audience hype pred cielom */
  private hypeActivatedBeforeFinish = false

  /**
   * Konstruktor
   * @param pathIndex - index pre trat
   * @param rowStart - cislo radu, kde zacina
   * @param callbackLunge - callback pre lunge
   * @param athlete - athlete
   */
  public constructor(
    pathIndex: number,
    rowStart: number,
    callbackLunge: () => unknown,
    private athlete: Athlete
  ) {

    this.actualPathIndex = pathIndex
    this.playerPath = linesManager.getPath(pathIndex)
    this.rowStart = rowStart
    this.pathIndexStart = pathIndex

    this.setActualPercentOnStart()
    this.callbackLunge = callbackLunge
    this.calculateLineInfo()
    this.setupObject3d()
    this.createAIDebugFinish()

  }

  /**
   * Vytovrenie debug bodov pred cielom
   */
  private createAIDebugFinish() {

    if (gameConfig.debugAI) {

      const geometrySphere = new THREE.SphereGeometry(0.1, 0.1, 0.1)
      const materialSphere = new THREE.MeshBasicMaterial({ color: Math.random() * 0xffffff })
      const meshSphere = new THREE.Mesh(geometrySphere, materialSphere)

      if (this.playerPath === undefined) return
      const position = this.playerPath.getPointAt(finishPhaseConfig.finishPhaseStartPercent[this.actualPathIndex] -
                (this.oneMeterInPercent * opponentConfig.beforeFinishSprintMeters))
      meshSphere.position.set(position.x, position.y + this.getYCorrection(), position.z)
      meshSphere.name = `${this.actualPathIndex} _finish_debug`
      game.scene.add(meshSphere)

    }

  }

  /**
   * Vratenie aktualnych percent na trati
   * @returns hodnota % na trati
   */
  public getActualPercent(): number {

    return this.actualPercent

  }

  /**
   * Vratenie poslednych percent na trati
   * @returns hodnota % na trati
   */
  public getLastPercent(): number {

    return this.lastPercent

  }

  /**
   * Vratenie cielovych percent na trati
   * @returns hodnota % na trati
   */
  public getFinishPercent(): number {

    return this.finishPercent

  }

  /**
   * Nastavenie pociatocnej pozicie
   */
  private setActualPercentOnStart(): void {

    const percentsArr = this.rowStart === 1 ?
      gameConfig.startPercentsOnCurve :
      gameConfig.startPercentsOnCurveSecondRow

    this.actualPercent = percentsArr[this.pathIndexStart]

    // ked potrebujeme skip do ciela
    if (gameConfig.skipToFinish.active) this.actualPercent = gameConfig.skipToFinish.percent

  }

  /**
   * Skontrolovanie, ci sa ma dat lunge alebo nie
   */
  private checkLunge(): void {

    if (
      this.actualPercent < finishPhaseConfig.lungeStartPercent[this.actualPathIndex] ||
            this.lungeStarted
    ) return

    this.lungeStarted = true
    this.callbackLunge()

  }

  /**
   * Skontrolovanie, ci sa ma dat finish alebo nie
   * @param athlete - Atlet
   */
  private checkFinish(athlete: Athlete): void {

    if (
      this.actualPercent < finishPhaseConfig.finishPhaseStartPercent[
        this.actualPathIndex
      ] ||
            this.finishPhaseStarted
    ) return

    this.finishPercent = this.actualPercent
    // this.actualPercent = 0
    this.finishPhaseStarted = true
    this.calculateLineInfo()

    athlete.finishReached()


  }

  /**
   * Update funkcia
   * @returns - Novy object 3D
   */
  public update(): THREE.Object3D {

    if (this.athlete.speedManager.isActive()) {

      if (this.athlete.playable && !this.athlete.isEnd) {

        endManager.pathSpentFrames[this.actualPathIndex] += 1

      }
      const endPercent = this.actualPathIndex % 2 === 0 ?
        1 :
        1 - (velocityConfig.safeZoneBehindEnd / 2 * this.oneMeterInPercent)
      if (
        this.actualPercent >= endPercent ||
                (
                  this.athlete.isEnd
                )
      ) {

        if (this.isAthleteBehindStoppedAthlete(this.athlete, endPercent)) {

          return this.object3d

        } else if (this.athlete.inSafeZoneOfAthlete !== undefined) {

          this.athlete.speedManager.setActualSpeed(this.athlete.speedManager.getActualSpeed() / 1.2)

        }


      }

      // rychlost v m/frame
      const actualSpeed = this.athlete.speedManager.getActualSpeedPerFrame()

      // rychlost v %/frame
      const actualPercentSpeed = this.oneMeterInPercent * actualSpeed

      // musime si zapamatat posledne percento
      this.lastPercent = this.actualPercent

      // pridavame aktualnu rychlost v %/frame
      this.actualPercent += actualPercentSpeed
      this.oldActualPercent += actualPercentSpeed

      // kontrola veci - triggerov
      this.checkLunge()

      this.triggersManager.update(
        this.actualPercent,
        this.lastPercent,
        this.actualPathIndex,
        this.athlete
      )
      this.checkFinish(this.athlete)

      /*
       * triggersManager.checkActualTrigger(
       *     this.actualPercent,
       *     this.lastPercent,
       *     this.oneMeterInPercent
       * )
       */

      this.setupObject3d()

      // skontrolujeme este cielovu rovinku pre zvuk hype divakov
      if (this.athlete.playable && !this.hypeActivatedBeforeFinish && this.isBeforeFinish()) {

        this.hypeActivatedBeforeFinish = true
        audioManager.stopAudioByName(AudioNames.audienceBells)
        audioManager.stopAudioByName(AudioNames.audienceBells1)
        audioManager.stopAudioByName(AudioNames.audienceNoise)
        // audioManager.play(AudioNames.audienceHype)
        audioManager.changeAudioVolume(AudioNames.audienceHype, 1)

      }

      // skontrolujeme este cielovu rovinku pre komentatora
      if (this.isBeforeFinish() && !endManager.firstPlayerBeforeFinish) {

        audioManager.play(AudioNames.commentBeforeFinish)
        endManager.firstPlayerBeforeFinish = true

      }

    }

    return this.object3d

  }

  /**
   * Ci sa atlet nachadza za athletom, ktory uz zastavil
   * @param athlete - athlete
   * @param endPercent - percento kde konci draha
   * @returns isAthleteBehindStoppedAthlete
   */
  public isAthleteBehindStoppedAthlete(athlete: Athlete, endPercent: number): boolean {

    if (athlete.hillLinesManager.actualPercent >= endPercent) return true
    if (athlete.inSafeZoneOfAthlete === undefined) return false

    return this.isAthleteBehindStoppedAthlete(athlete.inSafeZoneOfAthlete, endPercent)

  }

  /**
   * Prechod do inej drahy podla indexu
   * @param pathIndex - index novej drahy
   * @returns true ak sa podarila zmena
   */
  public changePath(pathIndex: number): boolean {

    const newPath = linesManager.getPath(pathIndex)

    if (newPath === undefined) return false

    this.calculateLineInfo()

    if (!this.calculateChange(pathIndex)) return false

    this.oldPlayerPath = this.playerPath
    this.playerPath = newPath

    this.actualPathIndex = pathIndex

    return true

  }

  /**
   * Vypocet a nastavenie noveho actualPercent pri presune do vedlajsej drahy
   * @param pathIndex - pathIndex noveh drahy
   * @param doChange - ci sa ma aj vykonat presun, alebo iba checky
   * @returns true, ak sa podarilo najst miesto v novej drahe
   */
  public calculateChange(pathIndex: number, doChange = true): boolean {

    if (this.playerPath === undefined) return false

    const toLeft = this.actualPathIndex < pathIndex

    const lookAt = this.lookingAt.clone()
    const vector = new THREE.Vector3()
    const playerPositionOnPath = this.playerPath.getPointAt(this.actualPercent)
    if (!playerPositionOnPath) return false
    playerPositionOnPath.y += this.getYCorrection()

    lookAt.setY(playerPositionOnPath.y)
    vector.subVectors(lookAt, playerPositionOnPath).normalize()

    let angle = Math.PI / 2
    if (!toLeft) angle *= -1

    vector.applyAxisAngle(this.axisY, angle)

    this.playerRaycast.set(playerPositionOnPath, vector)
    /*
     * const debugArrow =  new THREE.ArrowHelper(
     *     this.playerRaycast.ray.direction,
     *     this.playerRaycast.ray.origin,
     *     2,
     *     Math.random() * 0xffffff
     * )
     * game.scene.add(debugArrow)
     */

    const indexDiff = toLeft ? 1 : -1
    const trackNumber = pathsConfig.trackNumbers[this.actualPathIndex + indexDiff]

    // zoberiem si bod, kde sa pretal s krivkou kde chcem ist
    const intersectionPoint = this.playerRaycast
      .intersectObject(game.getObject3D(`TrackGuide${trackNumber}`))?.[0]?.point

    if (intersectionPoint === undefined) return false
    intersectionPoint.setY(0)

    // zoberiem si z tej krivky x bodov pred aktualnym percentom a x bodov po aktualnom percente
    const pointsToSearch = this.getPointsToSearch(this.actualPathIndex + indexDiff)

    // prejdem tento zoznam a najdem najblizsi bod,
    let distance: number | undefined = undefined
    let lowestDistancePointIndex = 0
    pointsToSearch.forEach((point, index) => {

      const newDistance = point.distanceTo(intersectionPoint)
      if (distance === undefined || newDistance < distance) {

        distance = newDistance
        lowestDistancePointIndex = index

      }

    })
    const { percentDiff, pointsPerPercent } = pathsConfig.changePathConfig
    const newPercent = this.actualPercent +
            (lowestDistancePointIndex / pointsPerPercent - percentDiff)

    const isOpponentBlocking = this.isOpponentBlockingChange(pathIndex, newPercent)
    if (isOpponentBlocking) return false
    if (!doChange) return !isOpponentBlocking

    // podla indexu v zozname bodov urcim nove percento
    this.oldActualPercent = this.actualPercent
    this.actualPercent = newPercent
    this.setupObject3d()
    // optional improvement: prepocitat podla oneMeter konstanty danej krivky

    this.changePathTransitionFrames = pathsConfig.changePathConfig.framesToChange

    return true

  }

  /**
   * Zistime, ci niekto blokuje drahu do ktorej chceme ist
   */
  private isOpponentBlockingChange(pathIndex: number, newPercent: number): boolean {

    const { blockingOffset } = pathsConfig.changePathConfig
    const opponents = [...opponentsManager.getOpponents(), player]
    const opponentsPercentInDesiredPath = opponents.filter(opponent => {

      return opponent.hillLinesManager.actualPathIndex === pathIndex

    }).filter(opponent => {

      const blockingOffsetInPercent = blockingOffset * this.oneMeterInPercent
      return opponent.hillLinesManager.actualPercent < newPercent + blockingOffsetInPercent &&
                opponent.hillLinesManager.actualPercent > newPercent - blockingOffsetInPercent

    })

    return opponentsPercentInDesiredPath.length > 0

  }

  /**
   * Vyberie body vyseku drahy s frekvenciou podla configu
   * @param newTrackId - id novej trate
   * @returns pointsToSearch - body na vyseku drahy
   */
  private getPointsToSearch(newTrackId: number): THREE.Vector3[] {

    const newPath = linesManager.getPath(newTrackId)
    const pointsToSearch: THREE.Vector3[] = []
    const { percentDiff, pointsPerPercent } = pathsConfig.changePathConfig

    for (let i = 0; i < percentDiff * 2 * pointsPerPercent; i++) {

      const point = newPath?.getPointAt(this.actualPercent + (i / pointsPerPercent - percentDiff))
      if (point) {

        point.setY(0)
        pointsToSearch.push(point)

      } else {

        pointsToSearch.push(new THREE.Vector3())

      }

    }
    return pointsToSearch

  }

  /**
   * Ci sa nachadzame x m pred cielom
   * @param distanceToFinish - m do ciela
   * @returns boolean
   */
  public isBeforeFinish(distanceToFinish = opponentConfig.beforeFinishSprintMeters): boolean {

    return this.actualPercent >
            finishPhaseConfig.finishPhaseStartPercent[this.actualPathIndex] -
            this.oneMeterInPercent * distanceToFinish

  }

  /**
   * Vyratanie dlzky ciary a jedneho metra na ciare
   */
  private calculateLineInfo(): void {

    const totalPlayerPathLength = this.playerPath?.getLength() || 0

    // takisto este potrebujeme 1m kolko je %
    this.oneMeterInPercent = 1 / totalPlayerPathLength

  }

  /**
   * nastavenie object3d na zaciatku
   */
  private setupObject3d(): void {

    if (this.playerPath === undefined) return

    const point = this.getNewPoint()

    const pointToLookAt = this.getNewPoint(0.0016)
    if (point) {

      this.object3d.position.set(point.x, point.y + this.getYCorrection(), point.z)
      if (pointToLookAt) pointToLookAt.y += this.getYCorrection()

    }

    if (pointToLookAt) {

      this.object3d.lookAt(pointToLookAt)
      this.lookingAt = pointToLookAt

    }

  }

  /**
   * Vypocitanie noveho bodu pre hraca
   * @returns novy bod alebo undefined ak sa nieco pokazilo
   */
  private getNewPoint(offset = 0): THREE.Vector3 | undefined {

    let point = this.playerPath?.getPointAt(this.actualPercent + offset)
    if (point === undefined) return

    if (this.changePathTransitionFrames > 0) {

      const { framesToChange } = pathsConfig.changePathConfig

      this.changePathTransitionFrames -= 1

      const oldPoint = this.oldPlayerPath?.getPointAt(this.oldActualPercent + offset)
      if (oldPoint === undefined) return

      const direction = new THREE.Vector3()
      direction.subVectors( point, oldPoint )
      const divider = (framesToChange - this.changePathTransitionFrames) / framesToChange
      const distance = direction.multiplyScalar(divider)

      point = oldPoint.add(distance)

    }

    return point

  }

  /**
   * Hodnota pre korekciu Y pozicie
   * @returns yCorrection
   */
  private getYCorrection(): number {

    let correction = gameConfig.yPlayerCorrection
    if (this.athlete.isEnd) return gameConfig.yPlayerCorrectionSnow
    if (!this.athlete.playable) {

      const playerPercent = player.hillLinesManager.actualPercent
      const correctionDistancePercent =
                opponentConfig.yPossitionCorrectionDistance * this.oneMeterInPercent
      if (
        this.actualPercent > playerPercent + correctionDistancePercent ||
                this.actualPercent < playerPercent - correctionDistancePercent
      ) {

        correction = opponentConfig.yOpponentFarPosition
        return correction

      }

    }
    if (this.changePathTransitionFrames !== 0 || this.actualPathIndex % 2 !== 0) {

      correction = gameConfig.yPlayerCorrectionSnow

    }

    return correction

  }

  /** reset */
  public reset(startData: StartPositionsData): void {

    this.lastPercent = 0
    this.finishPercent = 0
    this.oldActualPercent = 0
    this.oldPlayerPath = undefined
    this.lungeStarted = false
    this.changePathTransitionFrames = 0
    this.hypeActivatedBeforeFinish = false

    if (modes.isTrainingMode()) {

      this.pathIndexStart = startData.pathIndex
      this.rowStart = startData.row

    }

    this.playerPath = linesManager.getPath(this.pathIndexStart)
    this.actualPathIndex = this.pathIndexStart

    this.setActualPercentOnStart()
    this.lungeStarted = false
    this.finishPhaseStarted = false
    this.calculateLineInfo()
    this.setupObject3d()

    // this.actualPathIndex = 0

  }

}
