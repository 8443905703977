import {
  gameConfig,
  opponentConfig,
  velocityConfig
} from '@/app/config'
import { heartRateConfig } from '@/app/config/heartRateConfig'
import { Sides } from '@/app/types'
import {
  timeManager,
  THREE,
  modes
} from '@powerplay/core-minigames'
import type { Opponent } from './Opponent'
import { OpponentSprintBarManager } from './OpponentSprintBarManager'

/**
 * Trieda pre spravu rychlosti opponenta
 */
export class OpponentVelocityManager {

  /** Sila ktorou upravujeme rychlost */
  private speedPower = velocityConfig.startRun.startValue

  /** Trok na frame bez eventu */
  public frameWithoutEvent = 5

  /** Aktualna max rychlost lyziara */
  private maxSpeed = 0

  /** Posledny vypocitany gradient */
  public lastGradient = 0

  /** Ci bol stlaceny tuck - kvoli tutorialu */
  public tuckPressed = false

  /** Aktualna hodnota pre pocitanie gradientu kazdych x frameov */
  private actualGradientCounterFrames = 0

  /** Frame counter pre frame counting  */
  private frameCounter = 0

  /** opponentSprintBarManager */
  public opponentSprintBar = new OpponentSprintBarManager()

  /** Pocitadlo frameov pre rozbeh */
  private frameCounterStartRun = 0

  /** Pocitadlo pre vykonavanie inputov pri rozbehu */
  private framesCounterStartRunInputs = 0

  /** Kolko framov ubehlo od posledneho pokusu zmeny drahy */
  private framesFromLastChangePath = 0

  /** Aktualny pocet frameov na freeznutie po inpute */
  private freezeFrames = 0

  /** AKtualny pocet frameov na auto inputy podla randomu */
  private actualStartRunAutoInputsFrames = this.getRandomStartRunAutoInputsFrames()

  /** modifier na upravu speedPower */
  private speedBarModifier = 0

  /** pocet framov po sprinte */
  private sprintCooldownFrames = 0

  /**
   * Konstruktor
   * @param opponent - Atlet
   */
  public constructor(private opponent: Opponent) {}

  /**
   * Vratenie speed bar stavu
   * @returns hodnota speed baru
   */
  public getSpeedPowerState(): number {

    return this.speedPower

  }

  /**
   * Ziskanie max rychlosti
   * @returns Rychlost
   */
  public getMaxSpeed(): number {

    return this.maxSpeed

  }

  /**
   * Nastavenie pociatocnej hodnoty v speedbare po rozbehu, ak treba
   * @param uuid - UUID supera
   */
  public checkStartPowerAfterStartRun(uuid: string): void {

    const oldSpeedPower = this.speedPower
    this.checkAutoSpeedBarValue()
    console.log(`opponent ${uuid} prechadza z rozbehu ${oldSpeedPower} do behu ${
      this.speedPower
    }, aktualny speedBarModifier: ${this.speedBarModifier}`)

  }

  /**
   * Metoda zistujuca ci tychlost je nad povoleny limit a ci je spravny gradient
   */
  private isTuckingAvailable(): boolean {

    return this.opponent.speedManager.getActualSpeed() >= opponentConfig.smallestSpeedToTuck ||
            this.lastGradient <= velocityConfig.downhillGradientLimit

  }

  /**
   * Zrusenie tucku ak nie je povolene z dovodu pomalosti
   */
  private handleTuck() {

    if (!this.isTuckingAvailable()) this.opponent.isTuck = false

  }

  /**
   * Kontrola sprint baru a vykonanie veci na nom
   */
  private handleSprint(): void {

    if (this.sprintCooldownFrames > 0) {

      this.sprintCooldownFrames -= 1

    }
    this.opponentSprintBar.update(this.opponent, this.speedPower)

  }

  /**
   * ak je rychlost vacsia ako maximalna, spomalujeme cez backwardForce, inak zrychlujeme
   */
  private updateSpeed(): void {

    const {
      forwardForceSprint, forwardForceConst, forwardForceNormal,
      backwardForce, tuckCoef, tuckGradientThreshold,
      backwardForceConst, backwardForceCoef,
      slipstreamDownhillBonus, lanePenalty
    } = velocityConfig
    const gradient = this.getTrackActualGradient(true)

    let forwardForceTuck = -(backwardForceConst + gradient * backwardForceCoef)
    if (gradient <= tuckGradientThreshold) {

      forwardForceTuck = forwardForceConst * (1 - gradient * tuckCoef)

      if (this.opponent.inSafeZoneOfAthlete === undefined) {

        forwardForceTuck *= slipstreamDownhillBonus

      }
      if (this.opponent.hillLinesManager.actualPathIndex % 2 === 1) {

        forwardForceTuck *= lanePenalty

      }

    }

    if (this.opponent.speedManager.getActualSpeed() >= this.maxSpeed) {

      this.opponent.speedManager.changeSpeed(-backwardForce)
      return

    }

    if (this.opponent.isSprinting) {

      this.opponent.speedManager.changeSpeed(forwardForceSprint)

    } else if (this.opponent.isTuck) {

      this.opponent.speedManager.changeSpeed(forwardForceTuck)

    } else {

      this.opponent.speedManager.changeSpeed(forwardForceNormal)

    }

  }

  /**
   * Aktualizovanie maximalnej rychlosti
   */
  private updateMaxSpeed(): void {

    // ak je gradient mensi ako -3%, tak disablujeme sprint bar
    const gradient = this.getTrackActualGradient(true)
    const sprintAllowed = gradient >= velocityConfig.gradientForDisabledSprint

    if (this.opponent.isTuck) {

      this.maxSpeed = 9999999999
      return

    }

    const {
      gradientCoefNegative, gradientCoefPositive,
      speedConstForMaxSpeed, minMaxSpeedCoef, sprintCoef, sprintGradientCoef,
      minSprintBonus, slipStreamCoef, lanePenalty
    } = velocityConfig
    const gradientCoef = gradient < 0 ? gradientCoefNegative : gradientCoefPositive
    const attrStrength = this.opponent.attrStrength

    // ked nie je sprint dovoleny a je zapnuty, tak ho na drzovku vypiname
    if (!sprintAllowed && this.opponent.isSprinting) {

      this.opponent.isSprinting = false

    }

    const speedBar = this.opponent.isSprinting ?
      this.opponent.maxSpeedBarManager.getSpeedBarMaxValue() :
      this.getSpeedbarValue()
    const speedConst = speedConstForMaxSpeed

    if (this.opponent.isSprinting) {

      this.maxSpeed = attrStrength * speedBar / 100 * speedConst -
            gradient * gradientCoef


      let sprintBonus = sprintCoef * attrStrength
      if (gradient < 0) {

        sprintBonus = sprintCoef * attrStrength + gradient * sprintGradientCoef

      }
      if (sprintBonus < minSprintBonus) sprintBonus = minSprintBonus


      if (this.opponent.isInSlipStream) {

        this.maxSpeed *= slipStreamCoef

      }
      this.maxSpeed += sprintBonus

    } else {

      this.maxSpeed = attrStrength * speedBar / 100 * speedConst -
            gradient * gradientCoef
      if (this.opponent.isInSlipStream) {

        this.maxSpeed *= slipStreamCoef

      }

    }

    /*
     * console.log(
     *     `max speed => ${this.maxSpeed} = `,
     *     `(${attrStrength} * ${speedBar} * ${speedConst}) - ${gradient}`,
     *     `; actual speed ${speedManager.actualSpeed}`
     * )
     */

    if (this.opponent.hillLinesManager.actualPathIndex % 2 === 1) {

      this.maxSpeed *= lanePenalty

    }

    if (this.maxSpeed < minMaxSpeedCoef) this.maxSpeed = minMaxSpeedCoef

  }

  /**
   * Zistenie a vratenie gradientovej casti rise, pomocou ktorej sa pocita sklon v %
   * @param actualPositionY - aktualna pozicia na Y
   * @param lastPositionY - posledna pozicia na Y
   * @returns Hodnota rise
   */
  private getGradientRise(actualPositionY: number, lastPositionY: number): number {

    return actualPositionY - lastPositionY

  }

  /**
   * Ziskanie a vratenie gradientovej casti run, pomocou ktorej sa pocita sklon v %
   * @param actualPosition - aktualna pozicia
   * @param lastPosition - posledna pozicia
   * @param rise - Hodnota rise
   * @returns Hodnota run
   */
  private getGradientRun(
    actualPosition: THREE.Vector3,
    lastPosition: THREE.Vector3,
    rise: number
  ): number {

    const distance = lastPosition.distanceTo(actualPosition)
    return Math.sqrt(distance ** 2 - rise ** 2)

  }

  /**
   * Vratenie aktualneho gradientu kopca (sklon v %)
   * @param onlyCalculatedResult - vrati iba posledny result bez pocitania
   * @returns Aktualny gradient
   */
  public getTrackActualGradient(onlyCalculatedResult = false): number {

    if (onlyCalculatedResult) return this.lastGradient

    // az kazdy x-ty frame budeme menit hodnotu gradientu
    if (this.actualGradientCounterFrames % velocityConfig.changeGradientXFrames === 0) {

      const lastPosition = this.opponent.lastAthletePosition
      const actualPosition = this.opponent.getPosition()

      const rise = this.getGradientRise(actualPosition.y, lastPosition.y)
      const run = this.getGradientRun(actualPosition, lastPosition, rise)

      this.lastGradient = 0
      if (run !== 0) this.lastGradient = rise / run * 100

      this.opponent.lastAthletePosition.copy(actualPosition)

    }

    this.actualGradientCounterFrames++

    return this.lastGradient

  }

  /**
   * Vratenie specialnej hodnoty vypocitanej zo speedbaru na mensich hodnotach
   * @returns Hodnota zo speedbaru
   */
  private getSpeedbarValue(): number {

    return 50 + ((this.speedPower - 50) / 1)

  }

  /**
   * Checkuje ohnutie trate a automaticky stiahne speed bar hodnotu
   */
  private checkAutoSpeedBarValue(): void {

    this.speedPower = this.opponent.getSpeedBarMaxValue() - this.speedBarModifier

  }

  /**
   * Generovanie speedBarModifieru podla casti trate
   * @param sector - pre ktory sektor generujeme
   */
  public generateNextSpeedBarModifier(sector: number): void {

    this.speedBarModifier = THREE.MathUtils.randInt(
      opponentConfig.speedBarModifierRange[sector]?.min || 0,
      opponentConfig.speedBarModifierRange[sector]?.max || 0
    )
    console.log(`${this.opponent.uuid} sa vygeneroval novy speedBarModifier: ${this.speedBarModifier}`)

  }

  /**
   * Vratenie nahodneho poctu frameov pre auto inputy
   * @returns
   */
  private getRandomStartRunAutoInputsFrames(): number {

    const { min, max } = opponentConfig.startRunAutoInputsFrames

    return THREE.MathUtils.randInt(min, max)

  }

  /**
   * Inputy pri rozbehu
   */
  private handleStartRunInputs(): void {

    if (!this.opponent.isStartRun && !this.opponent.isStarting) return

    this.framesCounterStartRunInputs += 1

    if (this.framesCounterStartRunInputs % this.actualStartRunAutoInputsFrames === 0) {

      this.addStartRunPower()
      const { maxValue } = velocityConfig.speedBar
      if (this.speedPower < maxValue) {

        this.opponent.heartRateManager.addHeartRate(heartRateConfig.speedUpBPM, this.opponent)

      }
      this.freezeFrames = velocityConfig.startRun.freezeFrames
      this.frameCounterStartRun = 0

      // vypocitame novy random a zresetujeme counter frameov
      this.actualStartRunAutoInputsFrames = this.getRandomStartRunAutoInputsFrames()
      this.framesCounterStartRunInputs = 0

    }

  }

  /**
   * Navysenie hodnoty baru pri rozbehu
   */
  private addStartRunPower(): void {

    const { addValues, limits } = velocityConfig.startRun
    const { maxValue } = velocityConfig.speedBar

    let stepAdd = addValues[3]
    if (this.speedPower <= limits[0]) {

      stepAdd = addValues[0]

    } else if (this.speedPower <= limits[1]) {

      stepAdd = addValues[1]

    } else if (this.speedPower <= limits[2]) {

      stepAdd = addValues[2]

    }

    this.speedPower += stepAdd
    if (this.speedPower > maxValue) this.speedPower = maxValue

  }

  /**
   * Rozbehove automaticke klesanie baru
   */
  private handleStartRunAutoDecrease(): void {

    if ((!this.opponent.isStartRun && !this.opponent.isStarting) || this.freezeFrames > 0) return

    this.frameCounterStartRun += 1

    const { autoDecreaseFrames, autoDecreaseValue } = velocityConfig.startRun

    if (this.frameCounterStartRun % autoDecreaseFrames === 0) {

      this.speedPower -= autoDecreaseValue
      if (this.speedPower < 0) this.speedPower = 0

    }

  }

  /**
   * Aktualizovanie rychlosti
   */
  public update(): void {

    // rozbehove inputy
    this.handleStartRunInputs()

    this.checkAutoSpeedBarValue()
    const handleSpeedAndMove =
            this.opponent.setStartSkating ||
            this.opponent.isSkating ||
            this.opponent.isStartRun ||
            this.opponent.isStarting ||
            this.opponent.activeUpdatingMovementAnimations

    if (handleSpeedAndMove) {

      // speedManager.update()
      this.updateMaxSpeed()

    }
    this.updateSpeed()

    if (this.opponent.activeUpdatingMovementAnimations) {

      this.handleSprint()
      this.handleTuck()
      this.decideBehavior()

    }

    // rozbehove klesanie baru
    this.handleStartRunAutoDecrease()
    if (this.freezeFrames > 0) this.freezeFrames -= 1

    if (this.opponent.inSafeZoneOfAthlete !== undefined &&
            (this.opponent.inSafeZoneOfAthlete.speedManager.getActualSpeed() <=
            this.opponent.speedManager.getActualSpeed())
    ) {

      this.opponent.speedManager.setActualSpeed(this.opponent.inSafeZoneOfAthlete.speedManager.getActualSpeed())

    }

  }

  /**
   * Rozhodovanie spravania opponenta
   */
  private decideBehavior(): void {

    const {
      changePathFramesCondition, beforeFinishChangePathMeters, tuckStartSpeed
    } = opponentConfig

    this.framesFromLastChangePath += 1
    this.getTrackActualGradient()

    this.decideSprint()

    // downhill
    const downhillAllowed = this.lastGradient < velocityConfig.downhillGradientLimit
    const downhillSpeedMet = this.opponent.speedManager.getActualSpeed() > tuckStartSpeed

    if (downhillAllowed && downhillSpeedMet) {

      if (gameConfig.debugAI && !this.opponent.isTuck) {

        console.log(
          `super: ${this.opponent.uuid},`,
          `cas: ${timeManager.getGameTimeWithPenaltyInFormat(1)},`,
          'aktivovany zjazd'
        )

      }

      this.opponent.isTuck = true
      this.opponent.isSprinting = false

    }

    const actualPathIndex = this.opponent.hillLinesManager.actualPathIndex
    const isInBetweenTrack = actualPathIndex % 2 === 1
    const conditionsToChangePath = this.framesFromLastChangePath >= changePathFramesCondition
    const isBeforeFinish = this.opponent.hillLinesManager.isBeforeFinish(beforeFinishChangePathMeters)

    if (modes.isTutorial()) return
    if (conditionsToChangePath) {

      if (isInBetweenTrack) {

        this.changePathAndResetFrames()

      } else if (!isBeforeFinish) {

        if (actualPathIndex > 1) {

          if (
            this.opponent.hillLinesManager.calculateChange(actualPathIndex - 2, false)
          ) {

            this.opponent.changePath(Sides.RIGHT)

          }

        }

      }

    }

  }

  /**
   * Rozhodnutie ci spustit sprint
   */
  private decideSprint(): void {

    const {
      sprintOffTresholdFinish, sprintOffTreshold, sprintTurnOnValue, sprintTurnOnValueFinish,
      changePathFramesCondition, beforeFinishSprintMeters
    } = opponentConfig

    const isBeforeFinish = this.opponent.hillLinesManager.isBeforeFinish(beforeFinishSprintMeters)
    // gradient
    const sprintAllowed = this.lastGradient >= velocityConfig.gradientForDisabledSprint
    // sprintOffZone
    const computedSrintOffTreshold = isBeforeFinish ?
      sprintOffTresholdFinish :
      sprintOffTreshold
    // finish zone
    const sprintTurnOnValueFinishMet =
            this.opponentSprintBar.getBarValue() >= sprintTurnOnValueFinish

    const conditionsToStart =
            !this.opponent.isTuck &&
            this.sprintCooldownFrames <= 0 &&
            (this.opponentSprintBar.getBarValue() > sprintTurnOnValue ||
            (isBeforeFinish && sprintTurnOnValueFinishMet)) &&
            sprintAllowed
    const conditionsToEnd =
            this.opponent.inSafeZoneOfAthlete !== undefined || // dostal sa do safezone
            !sprintAllowed || // je moc dolekopec
            // mame vybity sprint pod urcenu hodnotu
            this.opponentSprintBar.getBarValue() <= computedSrintOffTreshold

    if (conditionsToStart && !conditionsToEnd) {

      if (gameConfig.debugAI && !this.opponent.isSprinting) {

        console.log(
          `super: ${this.opponent.uuid},`,
          `cas: ${timeManager.getGameTimeWithPenaltyInFormat(1)},`,
          'aktivovany sprint'
        )

      }

      this.sprintCooldownFrames = opponentConfig.sprintCooldownFrames
      this.opponentSprintBar.setSprinting(this.opponent)

    }
    if (conditionsToEnd) {

      this.opponent.isSprinting = false

    }
    if (modes.isTutorial()) return
    const conditionsToChangePath = this.framesFromLastChangePath >= changePathFramesCondition
    if (conditionsToStart && this.opponent.isInSlipStream && conditionsToChangePath) {

      this.changePathAndResetFrames()

    }

  }

  /**
   * Pokus o zmenu drahy
   */
  private changePathAndResetFrames(): void {

    if (!this.opponent.changePath(Sides.RIGHT)) {

      if (this.opponent.changePath(Sides.LEFT)) this.framesFromLastChangePath = 0

    } else {

      this.framesFromLastChangePath = 0

    }

  }

  /**
   * Resetovanie veci
   */
  public reset(): void {

    this.speedPower = velocityConfig.startRun.startValue
    this.frameWithoutEvent = 5
    this.maxSpeed = 0
    this.lastGradient = 0
    this.tuckPressed = false
    this.actualGradientCounterFrames = 0
    this.frameCounter = 0
    this.opponentSprintBar.reset()
    this.frameCounterStartRun = 0
    this.framesCounterStartRunInputs = 0
    this.framesFromLastChangePath = 0
    this.freezeFrames = 0
    this.actualStartRunAutoInputsFrames = this.getRandomStartRunAutoInputsFrames()

  }

}
