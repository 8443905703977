import { splitTimesConfig } from '@/app/config'
import type { OpponentVelocityManager } from '../../opponent/OpponentVelocityManager'
import type { Athlete } from '../Athlete'
import { splitTimeManager } from '../SplitTimeManager/SplitTimeManager'

/**
 * Classa na triggery pre prechod medzi modmi hry
 */
export class TriggersManager {


  /**
   * Skontrolovanie prejdenia aktualneho triggera
   * @param actualPercent - Aktualna % hodnota prejdenia trate
   * @param lastPercent - Posledna % hodnota prejdenia trate
   * @param pathIndex - index aktualnej drahy
   * @param athlete - atlet
   */
  public checkActualTrigger(
    actualPercent: number,
    lastPercent: number,
    pathIndex: number,
    athlete: Athlete
  ): void {

    if (athlete.actualSplitIndex > splitTimeManager.SPLIT_COUNT - 1) return
    const splitPercent = splitTimesConfig.splitTimePositions[
      athlete.actualSplitIndex
    ][pathIndex]

    if (
      lastPercent < splitPercent &&
            actualPercent > splitPercent
    ) {

      if (!athlete.playable) {

        const velocityManager = athlete.velocityManager as OpponentVelocityManager
        velocityManager.generateNextSpeedBarModifier(athlete.actualSplitIndex + 1)

      }
      splitTimeManager.setSplitTime(athlete)
      athlete.actualSplitIndex += 1

    }

  }

  /**
   * Update
   * @param actualPercent - Aktualna % hodnota prejdenia trate
   * @param lastPercent - Posledna % hodnota prejdenia trate
   * @param pathIndex - index aktualnej drahy
   */
  public update(
    actualPercent: number,
    lastPercent: number,
    pathIndex: number,
    athlete: Athlete
  ): void {

    this.checkActualTrigger(actualPercent, lastPercent, pathIndex, athlete)

  }

  /**
   * Resetovanie veci
   */
  public reset(): void {
    // nic
  }

}
