/**
 * Konfig pre finalnu fazu
 */
export const finishPhaseConfig = {

  /** Nelinearna faza znizovania rychlosti */
  nonLinearDecreasingSpeed: {

    /** Po kolkatich freamoch skonci tato faza */
    frames: 90,

    /** raz za x frameov sa zmeni rychlost */
    everyXFrames: 3,

    /** Rychlost sa prenasobi tymto koeficientom */
    coef: 0.95
  },

  /** linearna faza znizovania rychlosti */
  linearDecreasingSpeed: {

    /** Po kolkatich frameoch skonci tato faza */
    frames: 60

  },

  /** Pocet frameov, ktore bude lyziar stat po linearnej faze, dokym sa neukonci minihra */
  stayAtEndFrames: 30,

  /** Po kolkatich frameoch po prejdeni cielom sa prehra animacia end */
  startAnimationEndAfterFrames: 10,

  /** kedy ma zacat finish faza podla % na krivke */
  finishPhaseStartPercent: [
    0.9553,
    0.9561,
    0.9581999999999999,
    0.9605,
    0.964,
    0.9679,
    0.9728
  ],

  /** kedy ma zacat lunge podla % na krivke */
  lungeStartPercent: [
    0.9419,
    0.9428,
    0.9450000000000001,
    0.9474,
    0.9510000000000001,
    0.9549,
    0.96
  ],

  /** ako dlho po lunge animacii pockat */
  waitAfterLungeFrames: 10,

  /** pravdepodobnosti vyberu animacii v cieli */
  finishAnimationsProbabilities: {
    nothing: 0.333,
    finishAnimation: 0.5
  },

  /** cas prechodu animacie v cieli */
  crossfadeTimeFinish: 0.4

}
