<template>
  <div
    class="under-menu-component"
    :class="[isMobile() ? 'mobile-under-menu-component' : '']"
    :style="transformCoef"
  >
    <div class="component-item visibility-none">
      <time-keeper-component />
    </div>
    <div class="component-item">
      <heart-rate-component :glow="true" />
    </div>
  </div>
</template>

<script lang="ts">
// External Package codes
import { mapGetters } from 'vuex'
import { WindowAspect } from '@powerplay/core-minigames-ui'

// Game Components
import TimeKeeperComponent from '@/components/TimeKeeperComponent/index.vue'
import HeartRateComponent from '@/components/HeartRateComponent.vue'

import { defineComponent } from 'vue'

export default defineComponent({
  components: {
    TimeKeeperComponent,
    HeartRateComponent
  },
  mixins: [WindowAspect],
  computed: {
    ...mapGetters({
    }),
    transformCoef() {

      return {
        transform: `scale(${this.scaleCoef})`,
        'transform-origin': '100% 0'
      }

    }
  },
})

</script>

<style lang="less" scoped>

.under-menu-component{
    position: absolute;
    right: 1%;
    top: 17%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    &.mobile-under-menu-component {
        top: 17%;
    }
    .component-item {
        position: relative;
        margin-bottom: 15px;
    }

    .visibility-none {
        visibility: hidden;
    }

}

</style>
