import type { AudioObject } from '@powerplay/core-minigames'
import {
  AudioCategories,
  AudioGroups,
  AudioNames
} from '../types'

export const audioSprites: AudioObject[] = [
  {
    files: [
      AudioNames.spriteNotLooped
    ],
    startOnRandomPlace: false,
    randomDelayBeforeStartFrom: 0,
    randomDelayBeforeStartTo: 0,
    enabled: true,
    volume: 1,
    volumeRandom: 0,
    rate: 1,
    rateRandom: 0,
    loop: false,
    category: AudioCategories.sprite,
    version: 2,
    sprite: {
      'BI_skiing_break': [
        0,
        2997.120181405896
      ],
      'DH_checkpoint_good': [
        4000,
        1462.3129251700675
      ],
      'stick_pole_on_snow_soft_var01': [
        7000,
        933.9909297052155
      ],
      'vo_ready': [
        9000,
        2207.8231292517003
      ]
    }
  },
  {
    files: [
      AudioNames.spriteLooped
    ],
    startOnRandomPlace: false,
    randomDelayBeforeStartFrom: 0,
    randomDelayBeforeStartTo: 0,
    enabled: true,
    volume: 1,
    volumeRandom: 0,
    rate: 1,
    rateRandom: 0,
    loop: true,
    category: AudioCategories.sprite,
    version: 2,
    sprite: {
      'audience_sad2': [
        0,
        9997.00680272109
      ],
      'audience_yay3': [
        11000,
        8254.69387755102
      ],
      'BI_skiing_glide2': [
        21000,
        15997.00680272109
      ]
    }
  },
  {
    files: [
      AudioNames.spriteAudienceSpecial
    ],
    startOnRandomPlace: false,
    randomDelayBeforeStartFrom: 0,
    randomDelayBeforeStartTo: 0,
    volume: 1,
    volumeRandom: 0,
    rate: 1,
    rateRandom: 0,
    enabled: true,
    loop: true,
    category: AudioCategories.sprite,
    randomizeSprite: true,
    subset: true,
    version: 2,
    sprite: {
      'bells_var02': [
        0,
        7600
      ],
      'bells_var021': [
        9000,
        6433.333333333334
      ]
    }
  },
  {
    files: [
      AudioNames.spriteCommentators
    ],
    startOnRandomPlace: false,
    randomDelayBeforeStartFrom: 0,
    randomDelayBeforeStartTo: 0,
    ignoreModes: [3, 14, 9, 10],
    volume: 1,
    volumeRandom: 0,
    rate: 1,
    rateRandom: 0,
    enabled: true,
    loop: false,
    category: AudioCategories.sprite,
    randomizeSprite: true,
    group: AudioGroups.commentators,
    subset: true,
    sprite: {
      'finish1': [
        0,
        5331.111111111111
      ],
      'finish2': [
        7000,
        5402.902494331066
      ],
      'finish3': [
        14000,
        5448.594104308391
      ],
      'finish4': [
        21000,
        5814.013605442177
      ],
      'split_times_1_rank_1': [
        28000,
        5147.732426303854
      ],
      'split_times_1_rank_2_3': [
        35000,
        5989.931972789115
      ],
      'split_times_1_rank_4_5': [
        42000,
        4984.126984126988
      ],
      'split_times_1_rank_6': [
        48000,
        7158.208616780044
      ],
      'split_times_2_rank_1': [
        57000,
        6114.149659863948
      ],
      'split_times_2_rank_2_3': [
        65000,
        5246.303854875279
      ],
      'split_times_2_rank_4_5': [
        72000,
        5070.136054421766
      ],
      'split_times_2_rank_6': [
        79000,
        5840.090702947848
      ]
    }
  }
]