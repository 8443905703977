import {
  minigameConfig,
  PlayerRace,
  PlayerSex,
  type PlayerInfo
} from '@powerplay/core-minigames'

/**
 * Fake superi pre tutorial, trening a dennu ligu
 */
export const fakeOpponents: PlayerInfo[] = [
  {
    uuid: 'uuidOpponent1',
    name: 'Johannes',
    country: 'nor',
    countryString: 'nor',
    sex: PlayerSex.male,
    race: PlayerRace.white,
    experiences: 0,
    attribute: {
      'base': 0,
      'total': 0
    },
    personalBest: 0,
    playable: false,
    resultsArr: [
      {
        main: minigameConfig.dnfValue
      }
    ]
  },
  {
    uuid: 'uuidOpponent2',
    name: 'Frida',
    country: 'swe',
    countryString: 'swe',
    sex: PlayerSex.female,
    race: PlayerRace.white,
    experiences: 0,
    attribute: {
      'base': 0,
      'total': 0
    },
    personalBest: 0,
    playable: false,
    resultsArr: [
      {
        main: minigameConfig.dnfValue
      }
    ]
  },
  {
    uuid: 'uuidOpponent3',
    name: 'Hannu',
    country: 'fin',
    countryString: 'fin',
    sex: PlayerSex.male,
    race: PlayerRace.white,
    experiences: 0,
    attribute: {
      'base': 0,
      'total': 0
    },
    personalBest: 0,
    playable: false,
    resultsArr: [
      {
        main: minigameConfig.dnfValue
      }
    ]
  },
  {
    uuid: 'uuidOpponent4',
    name: 'Stefania',
    country: 'ita',
    countryString: 'ita',
    sex: PlayerSex.female,
    race: PlayerRace.white,
    experiences: 0,
    attribute: {
      'base': 0,
      'total': 0
    },
    personalBest: 0,
    playable: false,
    resultsArr: [
      {
        main: minigameConfig.dnfValue
      }
    ]
  },
  {
    uuid: 'uuidOpponent5',
    name: 'Richard',
    country: 'fra',
    countryString: 'fra',
    sex: PlayerSex.male,
    race: PlayerRace.white,
    experiences: 0,
    attribute: {
      'base': 0,
      'total': 0
    },
    personalBest: 0,
    playable: false,
    resultsArr: [
      {
        main: minigameConfig.dnfValue
      }
    ]
  },
  {
    uuid: 'uuidOpponent6',
    name: 'Jessica',
    country: 'usa',
    countryString: 'usa',
    sex: PlayerSex.female,
    race: PlayerRace.black,
    experiences: 0,
    attribute: {
      'base': 0,
      'total': 0
    },
    personalBest: 0,
    playable: false,
    resultsArr: [
      {
        main: minigameConfig.dnfValue
      }
    ]
  },
  {
    uuid: 'uuidOpponent7',
    name: 'Tobias',
    country: 'deu',
    countryString: 'deu',
    sex: PlayerSex.male,
    race: PlayerRace.white,
    experiences: 0,
    attribute: {
      'base': 0,
      'total': 0
    },
    personalBest: 0,
    playable: false,
    resultsArr: [
      {
        main: minigameConfig.dnfValue
      }
    ]
  },
]

/**
 * attribute strength pre superov - trening
 */
export const trainingOpponentsAttributeStrengths = [
  0.89, 0.88, 0.87, 0.86, 0.85, 0.84, 0.83
]

/**
 * attribute strength pre superov - denna liga
 */
export const dailyLeagueOpponentsAttributeStrengths = [
  0.9, 0.9, 0.9, 0.9, 0.9, 0.9, 0.9
]

