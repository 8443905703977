/** Konfig pre nastavenie rychlosti hraca */
export const velocityConfig = {

  /** Speed bar veci */
  speedBar: {

    /** minimalna hodnota speed baru */
    minValue: 50,

    /** maximalna hodnota speed baru */
    maxValue: 100,

    /** hodnota pri zvysovani hodnoty speed baru */
    stepAdd: 1,

    /** hodnota pri znizovani hodnoty speed baru */
    stepRemove: 1

  },

  /**
   * Konstanta pre max rychlost
   */
  speedConstForMaxSpeed: 8,

  /**
   * Koeficient pre sprint
   */
  sprintCoef: 2.5,

  /**
   * Koeficient pre sprint
   */
  sprintGradientCoef: 0.08,

  /**
   * Forward force konstanta
   */
  forwardForceConst: 0.002,

  /**
   * Forward force ktorym ide lyziar
   */
  forwardForceNormal: 0.2,

  /**
   * Forward force ktorym ide lyziar pri sprinte
   */
  forwardForceSprint: 0.2,

  /**
   * Koef pre force pri zjazde
   */
  tuckCoef: 1,

  /**
   * Forward force pre skating na starte
   */
  forwardForceSkatingStart: 0.2,

  /**
   * Spatna sila, ked ma lyziar rychlost vacsiu ako je max speed
   */
  backwardForce: 0.2,

  /**
   * Koeficient na prenasobenie gradientu, ak je gradient nad alebo pod nulou
   */
  gradientCoefPositive: 0.07,
  gradientCoefNegative: 0.1,

  /**
   * Zmena gradientu kazdy x-ty frame
   */
  changeGradientXFrames: 10,

  /**
   * Minimalny gradient, ktory musi byt, aby to bolo detekovane, ze ideme hore kopcom
   */
  uphillGradientLimit: 3,

  /**
   * Minimalny gradient, ktory musi byt, aby to bolo detekovane, ze ideme dole kopcom
   */
  downhillGradientLimit: -3,

  /**
   * Najnizsia mozna rychlost
   */
  minMaxSpeedCoef: 2,

  /** Minimalne sprint bonus */
  minSprintBonus: 0.5,

  /** % hodnota gradientu trate, pod ktorou nie je dovoleny sprint */
  gradientForDisabledSprint: -9,

  /** rychlostna konstanta */
  speedConst: 1,

  /** konstanta na vypocet backward force pri tucku */
  backwardForceConst: 0.1,

  /** koeficient na vypocet backward force pri tucku */
  backwardForceCoef: 0.01,

  /** max speed zvysovanie v zavetri */
  slipStreamCoef: 1.1,

  /** zona zavetria v metroch */
  slipStreamZone: 5,

  /** safe zona do ktorej sa iny hrac nemoze dostat */
  safeZoneBehind: 2.5,

  /** safe zona do ktorej sa iny hrac nemoze dostat za cielom */
  safeZoneBehindEnd: 5,

  /** kolko m za safezone je este sprint off zone */
  sprintOffZone: 0.5,

  /** veci pre rozbeh */
  startRun: {

    /** pociatocna hodnota nastavena prvym inputom */
    startValue: 40,

    /** kazdych x frameov nastane auto klesanie */
    autoDecreaseFrames: 1,

    /** kazde auto klesanie klesne o tuto hodnotu */
    autoDecreaseValue: 1,

    /** Hodnoty na pridanie pri roznych limitoch */
    addValues: [10, 5, 4, 3],

    /** Limity pre pridavanie roznych hodnot */
    limits: [40, 70, 90],

    /** Pocet frameov na freeznutie po inpute */
    freezeFrames: 4

  },

  /** koeficient pre nasobenie maxSpeed pre medzidzrahy */
  lanePenalty: 0.97,

  /** koeficient pre nasobenie forwardForceTuck pri slipstream */
  slipstreamDownhillBonus: 1.1,

  /** hranica gradientu kedy sa aplikuje forward force */
  tuckGradientThreshold: -2

}
