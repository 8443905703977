import store from '@/store'
import { THREE } from '@powerplay/core-minigames'
import {
  gameConfig,
  velocityConfig
} from '../../config'
import { player } from './Player'
import { PlayerSprintBarManager } from './PlayerSprintBarManager'
import { inputsManager } from '../../InputsManager'
import { speedBarMaxValueConfig } from '@/app/config/speedBarMaxValueConfig'
import { heartRateConfig } from '@/app/config/heartRateConfig'
import { endManager } from '@/app/EndManager'

/**
 * Trieda pre spravu rychlosti hraca
 */
export class PlayerVelocityManager {

  /** Sila ktorou upravujeme rychlost */
  private speedPower = velocityConfig.startRun.startValue

  /** Trok na frame bez eventu */
  public frameWithoutEvent = 5

  /** Aktualna max rychlost lyziara */
  private maxSpeed = 0

  /** Objekt sprint baru */
  public playerSprintBar = new PlayerSprintBarManager()

  /** Posledny vypocitany gradient */
  public lastGradient = 0

  /** Ci bol stlaceny tuck - kvoli tutorialu */
  public tuckPressed = false

  /** Aktualna hodnota pre pocitanie gradientu kazdych x frameov */
  private actualGradientCounterFrames = 0

  /** Frame counter pre frame counting  */
  private frameCounter = 0

  /** sprintBonus pre HUD */
  private sprintBonus = 0

  /** Pocitadlo frameov pre rozbeh */
  private frameCounterStartRun = 0

  /** Aktualny pocet frameov na freeznutie po inpute */
  private freezeFrames = 0

  /**
   * Vratenie speed bar stavu
   * @returns hodnota speed baru
   */
  public getSpeedPowerState(): number {

    return this.speedPower

  }

  /**
   * Ziskanie max rychlosti
   * @returns Rychlost
   */
  public getMaxSpeed(): number {

    return this.maxSpeed

  }

  /**
   * Pridanie rychlost
   * @returns void
   */
  private addPower(): void {

    const { stepAdd } = velocityConfig.speedBar

    const maxSpeedValue = player.getSpeedBarMaxValue()

    this.speedPower += stepAdd
    if (this.speedPower >= maxSpeedValue) this.speedPower = maxSpeedValue

    store.commit('GamePhaseState/SET_SPEED', this.speedPower)

  }

  /**
   * Ubranie rychlosti
   * @returns void
   */
  private removePower(): void {

    const { stepRemove } = velocityConfig.speedBar

    const maxSpeedValue = player.getSpeedBarMaxValue()
    const minSpeedValue = maxSpeedValue + speedBarMaxValueConfig.minValueCalcVal

    this.speedPower -= stepRemove
    if (this.speedPower <= minSpeedValue) this.speedPower = minSpeedValue

    store.commit('GamePhaseState/SET_SPEED', this.speedPower)

  }

  /**
   * Nastavenie pociatocnej hodnoty v speedbare po rozbehu, ak treba
   */
  public checkStartPowerAfterStartRun(): void {

    const { minValue } = velocityConfig.speedBar
    if (this.speedPower < minValue) this.speedPower = minValue
    store.commit('GamePhaseState/SET_SPEED', this.speedPower)
    endManager.startQuality = this.speedPower

  }

  /** Metoda zistujuca ci tychlost je nad povoleny limint */
  private isTuckingAvailable(): boolean {

    return player.speedManager.getActualSpeed() >= gameConfig.smallestSpeedToTuck

  }

  /** Zrusenie tucku ak nie je povolene z dovodu pomalosti */
  private handleTuck() {

    if (!this.isTuckingAvailable()) player.isTuck = false

  }

  /**
   * Kontrola inputov a spravenie veci, co sa maju pri nich vykonat
   */
  public handleInputs(): void {

    if (inputsManager.moveDirectionForward) this.addPower()
    if (inputsManager.moveDirectionBack) this.removePower()

    /** Dowhnill stav */
    if (this.manageDownhillState()) return

    /** Springing stav */
    if (this.manageSprintingState()) return

    this.frameWithoutEvent++

  }

  /**
   * Menezovanie stavu - tuck
   * @returns True, ak sa ma predcasne skoncit
   */
  private manageDownhillState(): boolean {

    const tutButtons = store.getters['TutorialState/getTutorialButtons']

    if (
      (store.getters['TuckState/isTuck'] || (inputsManager.actionPressed3)) &&
            (player.isDownhillAllowed() || player.isTuck || tutButtons.showDownhill)
    ) {

      if (this.frameWithoutEvent > 5) {

        if (!player.isDownhillAllowed()) player.allowDownhill()

        this.playerSprintBar.setSprinting(false)
        player.isTuck = !player.isTuck
        this.frameWithoutEvent = 0

      }

    }

    return false

  }

  /**
   * Menezovanie stavu - sprint
   * @returns True, ak sa ma predcasne skoncit
   */
  private manageSprintingState(): boolean {

    const sprintAllowed = store.getters['GamePhaseState/getIsAllowedToSprint']

    if (!sprintAllowed) return false
    const tutButtons = store.getters['TutorialState/getTutorialButtons']

    if (
      (inputsManager.actionPressed2 || store.getters['SprintState/isSprint']) &&
            this.playerSprintBar.isSprintAvailable() || tutButtons.showSprint
    ) {

      if (this.frameWithoutEvent > 5) {

        player.isTuck = false
        this.playerSprintBar.setSprinting()
        this.frameWithoutEvent = 0

      }

    }

    return false

  }

  /**
   * Kontrola sprint baru a vykonanie veci na nom
   */
  private handleSprint(): void {

    this.playerSprintBar.update(this.speedPower)

  }

  /**
   * ak je rychlost vacsia ako maximalna, spomalujeme cez backwardForce, inak zrychlujeme
   */
  private updateSpeed(): void {

    if (
      player.inSafeZoneOfAthlete !== undefined &&
            (player.inSafeZoneOfAthlete.speedManager.getActualSpeed() <=
            player.speedManager.getActualSpeed())
    ) {

      player.speedManager.setActualSpeed(player.inSafeZoneOfAthlete.speedManager.getActualSpeed())
      return

    }


    const {
      forwardForceSprint, forwardForceConst, forwardForceNormal,
      backwardForce, tuckCoef, tuckGradientThreshold,
      backwardForceConst, backwardForceCoef,
      slipstreamDownhillBonus, lanePenalty
    } = velocityConfig
    const gradient = this.getTrackActualGradient(true)
    let forwardForceTuck = -(backwardForceConst + gradient * backwardForceCoef)
    if (gradient <= tuckGradientThreshold) {

      forwardForceTuck = forwardForceConst * (1 - gradient * tuckCoef)

      if (player.inSafeZoneOfAthlete === undefined) {

        forwardForceTuck *= slipstreamDownhillBonus

      }
      if (player.hillLinesManager.actualPathIndex % 2 === 1) {

        forwardForceTuck *= lanePenalty

      }

    }

    store.commit('MainState/SET_FORCES', {
      forwardForce: (!player.isSprinting && !player.isTuck) ? forwardForceNormal : 0,
      forwardForceTuck: player.isTuck ? forwardForceTuck : 0,
      forwardForceSprint: player.isSprinting ? forwardForceSprint : 0,
      backwardForce: player.speedManager.getActualSpeed() >= this.maxSpeed ? backwardForce : 0
    })

    if (player.speedManager.getActualSpeed() >= this.maxSpeed) {

      player.speedManager.changeSpeed(-backwardForce)
      return

    }

    if (player.isSprinting) {

      player.speedManager.changeSpeed(forwardForceSprint)

    } else if (player.isTuck) {

      player.speedManager.changeSpeed(forwardForceTuck)

    } else {

      player.speedManager.changeSpeed(forwardForceNormal)

    }
    if (player.speedManager.getActualSpeed() > this.maxSpeed) {

      player.speedManager.setActualSpeed(this.maxSpeed)

    }

  }

  /**
   * Aktualizovanie maximalnej rychlosti
   */
  private updateMaxSpeed(): void {

    const {
      gradientCoefPositive, gradientCoefNegative,
      speedConstForMaxSpeed, minMaxSpeedCoef, sprintCoef, sprintGradientCoef,
      minSprintBonus, slipStreamCoef
    } = velocityConfig

    // ak je gradient mensi ako -3%, tak disablujeme sprint bar
    const gradient = this.getTrackActualGradient(true)
    const sprintAllowed = this.playerSprintBar.isSprintAvailable() &&
            gradient >= velocityConfig.gradientForDisabledSprint

    const gradientCoef = gradient < 0 ? gradientCoefNegative : gradientCoefPositive

    let isAllowedToSprintUI = sprintAllowed
    if (!player.isSprinting) {

      isAllowedToSprintUI = sprintAllowed && !player.inSprintOffZone

    }

    store.commit('GamePhaseState/SET_IS_ALLOWED_TO_SPRINT', isAllowedToSprintUI)

    if (player.isTuck) {

      this.maxSpeed = 9999999999
      return

    }
    const attrStrength = player.attrStrength

    // ked nie je sprint dovoleny a je zapnuty, tak ho na drzovku vypiname
    if (!sprintAllowed && player.isSprinting) {

      store.commit('SprintState/SET_STATE', { isSprinting: false })
      this.playerSprintBar.setSprinting(false)

    }

    const speedBar = player.isSprinting ?
      player.maxSpeedBarManager.getSpeedBarMaxValue() :
      this.getSpeedbarValue()
    const speedConst = speedConstForMaxSpeed

    if (player.isSprinting) {

      this.maxSpeed = attrStrength * speedBar / 100 * speedConst -
            gradient * gradientCoef


      let sprintBonus = sprintCoef * attrStrength
      if (gradient < 0) {

        sprintBonus = sprintCoef * attrStrength + gradient * sprintGradientCoef

      }
      this.sprintBonus = sprintBonus
      if (sprintBonus < minSprintBonus) sprintBonus = minSprintBonus


      if (player.isInSlipStream) {

        this.maxSpeed *= slipStreamCoef

      }
      this.maxSpeed += sprintBonus

    } else {

      this.maxSpeed = attrStrength * speedBar / 100 * speedConst -
            gradient * gradientCoef
      if (player.isInSlipStream) {

        this.maxSpeed *= slipStreamCoef

      }

    }

    if (player.hillLinesManager.actualPathIndex % 2 === 1) {

      this.maxSpeed *= velocityConfig.lanePenalty

    }

    /*
     * console.log(
     *     `max speed => ${this.maxSpeed} = `,
     *     `(${attrStrength} * ${speedBar} * ${speedConst}) - ${gradient}`,
     *     `; actual speed ${speedManager.actualSpeed}`
     * )
     */

    if (this.maxSpeed < minMaxSpeedCoef) this.maxSpeed = minMaxSpeedCoef

  }

  /**
   * Zistenie a vratenie gradientovej casti rise, pomocou ktorej sa pocita sklon v %
   * @param actualPositionY - aktualna pozicia na Y
   * @param lastPositionY - posledna pozicia na Y
   * @returns Hodnota rise
   */
  private getGradientRise(actualPositionY: number, lastPositionY: number): number {

    return actualPositionY - lastPositionY

  }

  /**
   * Ziskanie a vratenie gradientovej casti run, pomocou ktorej sa pocita sklon v %
   * @param actualPosition - aktualna pozicia
   * @param lastPosition - posledna pozicia
   * @param rise - Hodnota rise
   * @returns Hodnota run
   */
  private getGradientRun(
    actualPosition: THREE.Vector3,
    lastPosition: THREE.Vector3,
    rise: number
  ): number {

    const distance = lastPosition.distanceTo(actualPosition)
    return Math.sqrt(distance ** 2 - rise ** 2)

  }

  /**
   * Vratenie aktualneho gradientu kopca (sklon v %)
   * @param onlyCalculatedResult - vrati iba posledny result bez pocitania
   * @returns Aktualny gradient
   */
  public getTrackActualGradient(onlyCalculatedResult = false): number {

    if (onlyCalculatedResult) return this.lastGradient

    // az kazdy x-ty frame budeme menit hodnotu gradientu
    if (this.actualGradientCounterFrames % velocityConfig.changeGradientXFrames === 0) {

      const lastPosition = player.lastAthletePosition
      const actualPosition = player.getPosition()

      const rise = this.getGradientRise(actualPosition.y, lastPosition.y)
      const run = this.getGradientRun(actualPosition, lastPosition, rise)

      this.lastGradient = 0
      if (run !== 0) this.lastGradient = rise / run * 100

      player.lastAthletePosition.copy(actualPosition)

    }

    this.actualGradientCounterFrames++

    return this.lastGradient

  }

  /**
   * Vratenie specialnej hodnoty vypocitanej zo speedbaru na mensich hodnotach
   * @returns Hodnota zo speedbaru
   */
  private getSpeedbarValue(): number {

    return 50 + ((this.speedPower - 50) / 1)

  }

  /**
   * Checkuje ohnutie trate a automaticky stiahne speed bar hodnotu
   */
  private checkAutoSpeedBarValue() {

    const {
      gradientToChange, gradientCoefFrames, lowerLimit, minValueCalcVal, speedDeltaDown
    } = speedBarMaxValueConfig

    const maxSpeedValue = player.getSpeedBarMaxValue()
    if (this.speedPower >= maxSpeedValue) this.speedPower = maxSpeedValue

    const actualGradient = this.getTrackActualGradient()
    if (actualGradient > gradientToChange && !player.isSprinting) {

      this.frameCounter++

      // playerSpeedbar nesmie klesnut pod minimalnu hodnotu toto cislo sa meni
      let frameDecider = gradientCoefFrames - (actualGradient - gradientToChange)
      if (frameDecider < lowerLimit) frameDecider = lowerLimit

      if (this.frameCounter % Math.floor(frameDecider) === 0) {

        this.speedPower -= speedDeltaDown

        const minValue = player.getSpeedBarMaxValue() + minValueCalcVal
        if (this.speedPower <= minValue) this.speedPower = minValue

        store.commit('GamePhaseState/SET_SPEED', this.speedPower)

      }

    } else {

      this.frameCounter = 0

    }

  }

  /**
   * Inputy pri rozbehu
   */
  public handleStartRunInputs(): void {

    if (!player.isStartRun && !player.isStarting) return

    this.addStartRunPower()

    const { maxValue } = velocityConfig.speedBar
    if (this.speedPower < maxValue) {

      player.heartRateManager.addHeartRate(heartRateConfig.speedUpBPM, player)

    }
    this.freezeFrames = velocityConfig.startRun.freezeFrames
    this.frameCounterStartRun = 0

  }

  /**
   * Navysenie hodnoty baru pri rozbehu
   */
  private addStartRunPower(): void {

    const { addValues, limits } = velocityConfig.startRun
    const { maxValue } = velocityConfig.speedBar

    let stepAdd = addValues[3]
    if (this.speedPower <= limits[0]) {

      stepAdd = addValues[0]

    } else if (this.speedPower <= limits[1]) {

      stepAdd = addValues[1]

    } else if (this.speedPower <= limits[2]) {

      stepAdd = addValues[2]

    }

    this.speedPower += stepAdd
    console.log('Pri rozbehu pridavame hodnotu ', stepAdd)
    if (this.speedPower > maxValue) this.speedPower = maxValue

  }

  /**
   * Rozbehove automaticke klesanie baru
   */
  private handleStartRunAutoDecrease(): void {

    if ((!player.isStartRun && !player.isStarting) || this.freezeFrames > 0) return

    this.frameCounterStartRun += 1

    const { autoDecreaseFrames, autoDecreaseValue } = velocityConfig.startRun

    if (this.frameCounterStartRun % autoDecreaseFrames === 0) {

      this.speedPower -= autoDecreaseValue
      if (this.speedPower < 0) this.speedPower = 0

    }

  }

  /**
   * Aktualizovanie rychlosti
   */
  public update(): void {

    endManager.countTempo(this.speedPower)
    this.checkAutoSpeedBarValue()
    const handleSpeedAndMove =
            player.setStartSkating ||
            player.isStartRun ||
            player.isStarting ||
            player.activeUpdatingMovementAnimations

    if (handleSpeedAndMove) {

      // speedManager.update()
      this.updateMaxSpeed()

    }
    this.updateSpeed()

    // rychlost upravujeme iba vtedy ma aktivne pohybove animacie
    if (player.activeUpdatingMovementAnimations) {

      this.handleInputs()
      this.handleSprint()
      this.handleTuck()

    }

    // rozbehove klesanie baru
    this.handleStartRunAutoDecrease()
    if (this.freezeFrames > 0) this.freezeFrames -= 1

    // UI update
    store.commit('GamePhaseState/SET_SPEED', this.speedPower)
    store.commit('MainState/SET_STATE', {
      maxSpeed: this.maxSpeed,
      speed: player.speedManager.getActualSpeed(),
      gradient: this.lastGradient,
      sprintBonus: this.sprintBonus
    })

  }

  /**
   * Resetovanie veci
   */
  public reset(): void {

    this.speedPower = velocityConfig.startRun.startValue
    this.frameWithoutEvent = 5
    this.maxSpeed = 0
    this.playerSprintBar.reset()
    this.lastGradient = 0
    this.tuckPressed = false
    this.actualGradientCounterFrames = 0
    this.frameCounter = 0
    this.sprintBonus = 0
    this.frameCounterStartRun = 0
    this.freezeFrames = 0

  }

}
