/**
 * Konfig pre paths
 */
export const pathsConfig = {

  /** cisla v nazvoch trati */
  trackNumbers: [10, 15, 20, 25, 30, 35, 40],

  /** nastavenia pre zmenu drahy */
  changePathConfig: {

    /** kolko percent dopredu a dozadu pozeram */
    percentDiff: 0.05,
    /** kolko bodov na jedno percento berieme na vypocet */
    pointsPerPercent: 10000,
    /** kolko framov trva zmenit drahu */
    framesToChange: 30,

    /** kolko m +- od miesta, kde sa chce hrac presunut nemoze nikto byt */
    blockingOffset: 2.5

  },

  /** ktory effekt zobrazovat 'arrows' 'round' 'glow' */
  blockingEffectType: 'round'


}
