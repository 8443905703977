<template>
  <div
    id="app"
    class="app"
  >
    <div style="z-index: 1000000">
      <div v-show="!modalActive && !trainingState.firstTutorialMessage">
        <blocking-effect
          :is-visible="blockingState.isVisible && (blockingState.side !== undefined)"
          :side-x="sideX"
          side-y="top"
          :effect-type="effectType"
          :horizontal-position-offset="100"
        />
        <blocking-effect-center
          :is-visible="blockingState.isVisible && (blockingState.side === undefined)"
          side-x="center"
          side-y="top"
          rotate="90deg"
          :effect-type="effectType"
        />
        <countdown-component />
        <hud-component v-show="debugState.isHudActive" />
        <error-modal
          class="pointer-events-auto"
          @close="closeApp"
        />
        <finish-top-box
          v-if="uiState.showFinishTopBox"
          :show-first-box="finishTopBox.showFirstBox"
          :personal-best="finishTopBox.personalBest"
          :new-personal-best="finishTopBox.newPersonalBest"
          :first-place="finishTopBox.firstPlace"
          :show-second-box="finishTopBox.showSecondBox"
          :time="finishTopBox.time"
          :position="finishTopBox.position"
        />
        <phase-start />
        <under-menu-component />
        <traffic-component />
        <info-component />
        <mobile-inputs
          v-if="isMobile"
        />
        <efficiency-component :is-mobile="isMobile" />
        <split-times-biathlon v-if="uiState.showSplitTimes" />
        <speed-meter-component />
        <training-layout v-if="uiState.showTrainingLayout" />
        <training-results v-if="trainingResultsState.showResults" />
        <data-table />
        <info-keys v-if="!isMobile" />
        <black-overlay />
        <slip-stream-warning v-if="showSlipStream" />
      </div>
      <fps-component />
      <tutorial-logic-component
        v-show="!modalActive"
        :is-mobile="isMobile"
      />
      <tutorial-info-keys v-show="!modalActive && !isMobile && isTutorial" />
      <menu-section
        v-if="showMenu && !trainingState.firstTutorialMessage"
        :is-mobile="isMobile"
        style="pointer-events: auto"
        :is-app-wsm="isAppWSM2021"
        :show-overlay="modalActive"
        @toggle-ui="toggleUi"
      />
      <color-overlay-component
        v-if="isBlurActive && !blurState.isToggle"
        :opacity="0.6"
      />
      <loading-circle-overlay />
      <training-override
        style="pointer-events: auto"
        @mousedown.stop=""
        @mouseup.stop=""
        @touchstart.stop=""
        @touchend.stop=""
      />
      <shiny-text-animated-component />
    </div>
    <loading />
    <white-shine-component />
  </div>
</template>

<script lang="ts">
// External Package codes
import { mapGetters } from 'vuex'

// Internal Package codes
import {
  game,
  MobileDetector,
  requestManager,
  modes,
  inputsManager
} from '@powerplay/core-minigames'
import {
  ErrorModal,
  LoadingCircleOverlay,
  ColorOverlayComponent,
  BlockingEffect,
  BlackOverlay,
  BlockingEffectCenter
} from '@powerplay/core-minigames-ui'

// Component codes

// Debug Components
import HudComponent from '@/components/DebugComponents/HUD.vue'
// import DirectionsInfo from '@/components/DebugComponents/DirectionsInfo.vue'

// Game Components
import MobileInputs from '@/components/Inputs/MobileInputs.vue'
import PhaseStart from '@/components/PhaseStart.vue'
import SplitTimesBiathlon from '@/components/SplitTimesBiathlon.vue'
import FinishTopBox from '@/components/FinishTopBox.vue'
import DataTable from '@/components/Table/DataTable.vue'
import Loading from '@/components/Loading.vue'
import InfoComponent from '@/components/InfoComponent.vue'
import MenuSection from '@/components/MenuSection/index.vue'
import EfficiencyComponent from '@/components/Inputs/EfficiencyComponent.vue'
import FpsComponent from '@/components/FpsComponent.vue'
import SpeedMeterComponent from '@/components/SpeedMeterComponent.vue'
import TrafficComponent from '@/components/TrafficComponent.vue'
import TrainingLayout from '@/components/Training/TrainingLayout.vue'
import TrainingResults from '@/components/Training/TrainingResults.vue'
import UnderMenuComponent from '@/components/UnderMenuComponent/index.vue'
import TutorialLogicComponent from '@/components/Tutorial/TutorialLogicComponent.vue'
import CountdownComponent from '@/components/CountdownComponent/index.vue'
import TutorialInfoKeys from '@/components/Tutorial/TutorialInfoKeys.vue'
import InfoKeys from '@/components/InfoKeys.vue'
import TrainingOverride from '@/components/Training/TrainingOverride.vue'
import WhiteShineComponent from '@/components/WhiteShineComponent.vue'
import ShinyTextAnimatedComponent from '@/components/ShinyTextAnimatedComponent.vue'
import SlipStreamWarning from '@/components/SlipStreamWarning.vue'

// Internal Codes
import { initiateListeners } from '@/helpers/initiateListeners'
import { tutorialUIChange } from '@/app/modes/tutorial/TutorialUIChange'
import { disciplinePhasesManager } from '@/app/phases/DisciplinePhasesManager'
import { pathsConfig } from '@/app/config'

import { defineComponent } from 'vue'

export default defineComponent({
  name: 'StructuredComponent',
  components: {
    HudComponent,
    // DirectionsInfo,
    Loading,
    MobileInputs,
    PhaseStart,
    MenuSection,
    SplitTimesBiathlon,
    FinishTopBox,
    ErrorModal,
    DataTable,
    InfoComponent,
    EfficiencyComponent,
    SpeedMeterComponent,
    FpsComponent,
    TrafficComponent,
    TrainingLayout,
    TrainingResults,
    UnderMenuComponent,
    TutorialLogicComponent,
    BlackOverlay,
    CountdownComponent,
    LoadingCircleOverlay,
    TutorialInfoKeys,
    InfoKeys,
    TrainingOverride,
    ColorOverlayComponent,
    WhiteShineComponent,
    BlockingEffect,
    ShinyTextAnimatedComponent,
    BlockingEffectCenter,
    SlipStreamWarning
  },
  data() {

    return {
      modalActive: false,
      isAppWSM2021: false,
      effectType: ''
    }

  },
  computed: {
    ...mapGetters({
      uiState: 'UiState/getUiState',
      debugState: 'DebugState/getDebugState',
      showTrainingResults: 'TrainingResultsState/getTrainingResultsShow',
      finishTopBox: 'FinishTopBoxState/getfinishTopBoxState',
      trainingState: 'TrainingState/getTrainingState',
      isBlurActive: 'BlurState/getIsActive',
      blurState: 'BlurState/getBlurState',
      trainingResultsState: 'TrainingResultsState/getTrainingResultsState',
      blockingState: 'BlockingState/getBlockingState',
    }),
    isMobile(): boolean {

      return MobileDetector.isMobile()

    },
    isTutorial(): boolean {

      return modes.isTutorial()

    },
    showMenu(): boolean {

      return !this.showLoading() && !this.showTrainingResults

    },
    sideX(): string {

      if (this.blockingState.side) {

        return this.blockingState.side.toLowerCase()

      }
      return ''

    },
    sideY(): string {

      if (this.blockingState.side) {

        return 'top'

      }
      return 'top-centered'

    },
    rotate(): string {

      if (this.sideY === 'top') {

        return '0deg'

      }
      return '90deg'

    },
    showSlipStream(): boolean {

      return modes.isTutorial() || modes.isTrainingMode()

    }
  },
  watch: {
    isBlurActive(newState) {

      if (newState) {

        document.getElementsByTagName('canvas')[0].classList.add('blur-class')

      } else {

        document.getElementsByTagName('canvas')[0].classList.remove('blur-class')

      }

      // osetrenie vypnutia menu pocas zobrazenej tabulky
      if (!newState && this.blurState.isTable) {

        this.$store.commit('BlurState/SET_IS_ACTIVE', true)

      }

    }
  },
  created(): void {

    this.$store.commit('LoadingState/SET_STATE', {
      showLoading: true,
      loadingProgress: 0
    })

    this.isAppWSM2021 = modes.isAppWSM2021()
    initiateListeners(this)
    tutorialUIChange.registerVM(this)
    this.effectType = pathsConfig.blockingEffectType

  },
  methods: {
    showLoading(): boolean {

      return this.$store.getters['LoadingState/getLoadingState'].showLoading

    },
    closeApp(): void {

      disciplinePhasesManager.prematureEnded = true
      this.$store.commit('LoadingCircleState/SET_STATE', {
        isActive: true
      })
      requestManager.redirect(true)

    },
    toggleUi(toggle = true) {

      if (toggle) {

        this.$store.commit('BlurState/SET_IS_ACTIVE', true)
        this.$store.commit('BlurState/SET_IS_TOGGLE', true)
        inputsManager.actionInputsBlocked = true
        this.modalActive = true
        game.pauseGame()

      } else {

        inputsManager.actionInputsBlocked = false
        this.modalActive = false
        this.$store.commit('BlurState/SET_IS_ACTIVE', false)
        this.$store.commit('BlurState/SET_IS_TOGGLE', false)
        game.resumeGame()

      }

    }
  }
})
</script>

<style>
.blur-class {
  filter: blur(4px);
}
</style>
<style lang="less" scoped>
.app {
  min-height: 100%;
  min-width: 100%;
  pointer-events: none;
}

.pointer-events-auto {
  pointer-events: auto;
}

.absolute {
  position: absolute;
}

.midder {
  top: 50%;
  left: 50%;
}

.blocking-effect-container {
  &.blocking-effect-top {
    top: 40%;
  }
}
</style>
