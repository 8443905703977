import {
  playersManager,
  type SplitTimeData,
  timeManager,
  gsap,
  modes,
  audioManager
} from '@powerplay/core-minigames'
import store from '@/store'
import type { Athlete } from '../Athlete'
import { splitTimesConfig } from '@/app/config'
import {
  AudioGroups,
  AudioNames
} from '@/app/types'

/**
 * Trieda pre spravu medzicasov
 */
export class SplitTimeManager {

  /** Indexy branok, kde budu medzicasy */
  public splitData: SplitTimeData[][] = []

  /** index split time ktory zobrazujeme */
  private activeSplitIndex = 0

  /** pozicia playable hraca */
  private positionsOfPlayer: number[] = []

  /** najlepsie casy jednotlivych splitov */
  private bestTimes: number[] = []

  /** Pocet medzicasov */
  public SPLIT_COUNT = 3

  /** tween na schovavanie ui */
  private hideUITween?: gsap.core.Tween

  /** ci sa spustil tween */
  private tweenStarted = false

  /** hracove casy */
  public playerSplitTimes: number[] = []

  /** hracove medzicasy */
  public playerSplitPositions: number[] = []

  public constructor() {

    for (let i = 0; i < this.SPLIT_COUNT; i += 1) {

      this.splitData[i] = []

    }

  }

  /**
   * Prehratie komentatora na medzicase
   * @param splitIndex - Index medzicasu
   * @param playerPosition - Pozicia hraca
   */
  private playSplitCommentator(splitIndex: number, playerPosition: number): void {

    const first = splitIndex === 0
    let audio = first ? AudioNames.commentSplitTimes1Rank6 : AudioNames.commentSplitTimes2Rank6

    if (playerPosition <= 1) {

      audio = first ? AudioNames.commentSplitTimes1Rank1 : AudioNames.commentSplitTimes2Rank1

    } else if (playerPosition <= 3) {

      audio = first ? AudioNames.commentSplitTimes1Rank23 : AudioNames.commentSplitTimes2Rank23

    } else if (playerPosition <= 5) {

      audio = first ? AudioNames.commentSplitTimes1Rank45 : AudioNames.commentSplitTimes2Rank45

    }

    audioManager.stopAudioByGroup(AudioGroups.commentators)
    audioManager.play(audio)

  }

  /**
   * Zaznamenanie splitTime atleta
   * @param athlete - atlet
   */
  public setSplitTime(athlete: Athlete): void {

    if (modes.isTrainingMode() || modes.isTutorial()) return

    const athleteData = playersManager.getPlayerById(athlete.uuid)
    const time = timeManager.getGameTimeWithPenaltyInSeconds(false, undefined, 1)
    const position = this.splitData[athlete.actualSplitIndex].length + 1
    const { splitRecordsToShow } = splitTimesConfig

    let difference = 0

    if (position === 1) {

      this.bestTimes[athlete.actualSplitIndex] = time
      this.hideUITween?.kill()
      if (this.activeSplitIndex < this.SPLIT_COUNT - 1 && this.tweenStarted) {

        this.activeSplitIndex += 1

      }
      if (athlete.playable) {

        this.activeSplitIndex = athlete.actualSplitIndex

      }
      this.tweenStarted = false

    } else {

      difference = time - this.bestTimes[athlete.actualSplitIndex]

    }

    const differenceText = this.getDifferenceText(difference)

    const splitData: SplitTimeData = {
      position: position.toString(),
      country: athleteData?.country ?? '',
      countryString: athleteData?.countryString ?? '',
      player: {
        name: athleteData?.name ?? '',
        isPlayer: athlete.playable,
      },
      color: this.getColor(difference),
      time: timeManager.getTimeInFormatFromSeconds(time, 1),
      timeDiff: differenceText
    }
    this.splitData[athlete.actualSplitIndex].push(splitData)

    if (athlete.playable) {

      this.playerSplitTimes.push(time)
      this.playerSplitPositions.push(position)
      this.positionsOfPlayer[athlete.actualSplitIndex] = position
      if (position === 1) {

        store.commit('SplitTimeState/SET_SHOW_PLAYER_TIME', true)

      }

      this.playSplitCommentator(athlete.actualSplitIndex, position)

    }
    let sliceSize = splitRecordsToShow - 1
    if (
      this.positionsOfPlayer[this.activeSplitIndex] !== undefined &&
            this.positionsOfPlayer[this.activeSplitIndex] <= splitRecordsToShow
    ) {

      sliceSize = splitRecordsToShow

    }

    this.storeResultData(sliceSize)
    this.startHideUITween()
    this.rerenderUIIfNeeded(position, athlete.playable)

  }

  /**
   * Zacatie tweenu na schovanie UI ak treba
   * @param position - pozicia atleta
   * @param playable - atlet je hrac
   */
  private rerenderUIIfNeeded(position: number, playable: boolean): void {

    const { splitRecordsToShow } = splitTimesConfig
    if (
      position < splitRecordsToShow ||
            playable
    ) {

      store.commit('SplitTimeState/SET_RERENDER_KEY', this.splitData[this.activeSplitIndex].length)

    }

  }

  /**
   * Zacatie tweenu na schovanie UI ak treba
   */
  private startHideUITween(): void {

    if (modes.isTrainingMode()) return

    const { splitRecordsToShow, uiHideAfterSeconds } = splitTimesConfig

    if (
      this.positionsOfPlayer[this.activeSplitIndex] !== undefined &&
            this.splitData[this.activeSplitIndex].length >= splitRecordsToShow &&
            !this.tweenStarted
    ) {

      this.tweenStarted = true
      this.hideUITween = gsap.to({}, {
        onComplete: () => {

          if (this.activeSplitIndex < this.SPLIT_COUNT - 1) {

            this.activeSplitIndex += 1

          }
          store.commit('SplitTimeState/SET_SHOW_PLAYER_TIME', false)
          store.commit(
            'SplitTimeState/SET_SPLIT_TIME_DATA',
            []
          )
          this.tweenStarted = false

        },
        duration: uiHideAfterSeconds
      })

    }

  }

  /**
   * Ulozenie result dat pre UI
   * @param sliceSize - pocet riadkov na zobrazenie
   */
  private storeResultData(sliceSize: number): void {

    if (modes.isTrainingMode()) return

    const resultData = this.splitData[this.activeSplitIndex].slice(0, sliceSize)

    if (sliceSize === 3 && this.positionsOfPlayer[this.activeSplitIndex] !== undefined) {

      resultData.push(this.splitData[
        this.activeSplitIndex
      ][this.positionsOfPlayer[this.activeSplitIndex] - 1])

    }
    store.commit(
      'SplitTimeState/SET_SPLIT_TIME_DATA',
      resultData
    )

  }

  /**
   * Naformatovanie diff s prefixom
   * @param difference - diff
   * @returns - text
   */
  private getDifferenceText(difference: number): string {

    const differencePrefix = this.getDifferencePrefix(difference)
    return this.formatDifferenceTime(difference, differencePrefix)

  }

  /**
   * Rozhodnutie farby diff
   * @param difference - diff
   * @returns color
   */
  private getColor(difference: number): string {

    return difference > 0 ? 'red' : 'green'

  }

  /**
   * Zistenie prefixu pre diff
   * @param difference - Diff
   * @returns Prefix
   */
  private getDifferencePrefix(difference: number): string {

    let differencePrefix = ''
    if (difference > 0) differencePrefix = '+'
    if (difference < 0) differencePrefix = '-'
    return differencePrefix

  }

  /**
   * Naformatovanie diffu casu
   * @param difference - diff
   * @param differencePrefix - prefix pre diff
   * @returns Naformatovany diff time
   */
  private formatDifferenceTime(difference: number, differencePrefix: string): string {

    const timeInFormat = timeManager.getTimeInFormatFromSeconds(Math.abs(difference), 1)
    return `${differencePrefix}${timeInFormat}`

  }

  /**
   * Vratenie vsetkych checkpointov
   * @returns Pole checkpointov
   */
  public getAllSplitTimes(): SplitTimeData[][] {

    return this.splitData

  }

}

export const splitTimeManager = new SplitTimeManager
