<template>
  <section class="positioner">
    <section
      class="flex justify-between"
      :class="[!gameSettingsState.isLeft ? 'bottom-right' : 'bottom-left']"
      :style="{transform: `scale(${scaleCoef})`}"
    >
      <div
        v-if="!gameSettingsState.isLeft"
        class="fake-space"
      />
      <section class="relative">
        <arrow-animation
          style="position: absolute; width: 580px; height: 460px; bottom: 0;"
          position="bottom"
          :is-scaled="false"
        />
        <mobile-button
          :glow="true"
          :type="'start-cross-country'"
          :is-scaled="false"
          @mousedown.stop=""
          @mouseup.stop=""
          @touchstart.stop="buttonClick()"
          @touchend.stop=""
        />
        <start-efficiency-bar
          :forced-size="100"
          :is-scaled="false"
          :glow="true"
          :is-left="gameSettingsState.isLeft"
          :is-mobile="isMobile"
        />
      </section>
    </section>
  </section>
</template>

<script lang="ts">
import {
  MobileButton,
  ArrowAnimation,
  WindowAspect
} from '@powerplay/core-minigames-ui'
import { mapGetters } from 'vuex'
import StartEfficiencyBar from '@/components/Inputs/StartEfficiencyBar.vue'
import { tutorialFlow } from '@/app/modes/tutorial/TutorialFlow'
import {
  tutorialManager,
  TutorialSectionType
} from '@powerplay/core-minigames'

import { defineComponent } from 'vue'

export default defineComponent({
  name: 'TutorialButtonStart',
  components: {
    MobileButton,
    StartEfficiencyBar,
    ArrowAnimation
  },
  mixins: [WindowAspect],
  props: {
    isMobile: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    ...mapGetters({
      gameSettingsState: 'GameSettingsState/getGameSettingsState'
    })
  },
  methods: {
    buttonClick() {

      if (tutorialManager.getTypeWriting()) {

        this.$store.commit('TutorialCoreState/SET_TYPE_WRITER', false)
        tutorialManager.setTypeWriting(false)
        return

      }
      tutorialFlow.checkInput()

      if (tutorialManager.getActualSectionType() === TutorialSectionType.storyInput) {

        tutorialManager.nextSection()

      }

    }
  },
})
</script>

<style scoped lang="less">
.positioner {
  position: absolute;
  bottom: 0;
  width: 100%;
}
.flex {
  display: flex;
}

.justify-between {
  justify-content: space-between;
}

.relative {
  position: relative;
}

.power-mobile-button {
    pointer-events: auto;
}

</style>
