<template>
  <section
    v-if="show"
    class="heart-rate-position"
  >
    <heart-monitor-box
      :heart-rate="String(getRate)"
      :glow="glow"
    />
  </section>
</template>

<script lang="ts">
import { HeartMonitorBox } from '@powerplay/core-minigames-ui'
import { mapGetters } from 'vuex'

import { defineComponent } from 'vue'

export default defineComponent({
  name: 'HearthRateComponent',
  components: {
    HeartMonitorBox
  },
  props: {
    glow: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters({
      show: 'HeartRateState/isShown',
      getRate: 'HeartRateState/getHeartRate',
      uiState: 'UiState/getUiState'
    })
  }
})

</script>
