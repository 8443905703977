import {
  CameraStates,
  THREE
} from '@powerplay/core-minigames'

/*
 * pozicia poslednej branky pre config veci nizsie
 * const lastGate = batchingConfig.get('Branka0')
 * const lastGateData = lastGate ? lastGate.data : [{ offset: new THREE.Vector3() }]
 * const lastGatePosition = lastGateData[lastGateData.length - 1].offset.clone()
 */

/**
 * Konfig pre kameru pre vsetky stavy
 */
export const cameraConfig = {

  /** Nastavenie pozicii a pohybov kamery pre vsetky stavy */
  tweenSettingsForCameraStates: {
    [CameraStates.intro]: {
      startPosition: new THREE.Vector3(-20.897787, 17.401676, 90.69728),
      endPosition: new THREE.Vector3(-11.31566, 10.02555, 28.5807476),
      startRotation: new THREE.Vector3(1.706828, -0.4782, 0.451),
      endRotation: new THREE.Vector3(1.61551677, 0.12154289, 0.694317),
      duration: 5,
      notSkippableFrames: 20,
      fov: 72
    },
    [CameraStates.intro2]: {
      startPosition: new THREE.Vector3(20.1984329, 10.342081, -42.3437538),
      endPosition: new THREE.Vector3(-7.1556477, 6.1026368, -33.79144668),
      startRotation: new THREE.Vector3(1.721604953, 0.20508, -2.78302),
      endRotation: new THREE.Vector3(1.5835426, -0.186939, 2.67015444),
      duration: 3,
      notSkippableFrames: 20,
      fov: 90
    },
    [CameraStates.table]: {
      startPosition: new THREE.Vector3(100, 55, -10),
      startLookAt: new THREE.Vector3(-25, 1, 50),
      endPosition: new THREE.Vector3(101, 56, -11),
      duration: 5
    },
    [CameraStates.disciplineIntro]: [
      {
        startPosition: new THREE.Vector3(10.9622478, 1.4391, -55.58615875),
        endPosition: new THREE.Vector3(7.19345, 1.05574858, -27.09197998),
        startRotation: new THREE.Vector3(1.6020949, -0.079529858, -2.9614157),
        endRotation: new THREE.Vector3(1.59836, 0.03823966, -3.0848578),
        duration: 3,
        fov: 36
      },
      {
        startPosition: new THREE.Vector3(8.1014, 0.952975869, 11.1544),
        endPosition: new THREE.Vector3(9.7373819, 0.952975869, 10.7478599),
        startRotation: new THREE.Vector3(1.559679, 0.00266, -0.2301366),
        endRotation: new THREE.Vector3(1.574887, -0.00127, -0.313927),
        duration: 3,
        fov: 40
      }
    ],
    [CameraStates.disciplineIntroSecond]: undefined,
    [CameraStates.discipline]: undefined,
    [CameraStates.disciplineOutro]: {
      startPosition: new THREE.Vector3(17, 1.5, -25),
      endPosition: new THREE.Vector3(17, 1.5, -44),
      duration: 4,
      durationZoom: 3,
      zoom: 1.5,
      zoomEnd: 1.5,
      fov: 40,
      offsetCamera: false
    },
    [CameraStates.static]: undefined
  },
  distanceFromGround: 1.5

}
