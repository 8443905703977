import { pathAssets } from '@/globals/globalvariables'
import type { LoaderDataTypes } from '@powerplay/core-minigames'
import { checkpointManager } from '../modes/training'
import { ModelsNames } from '../types'

// Hlavny priecinok s modelmi
const modelsDir = `${pathAssets}/models/`

/**
 * Konfig pre modely
 */
export const modelsConfig: LoaderDataTypes = {
  [ModelsNames.skier]: {
    version: 240206,
    ext: 'glb',
    genderActive: true,
    femaleVersion: 240206,
    dir: modelsDir,
    mainMeshNames: [ModelsNames.skier],
    opponentCheck: true
  },
  [ModelsNames.hill]: {
    ext: 'glb',
    version: 14,
    dir: modelsDir
  },
  [ModelsNames.trackLines]: {
    ext: 'glb',
    dir: modelsDir,
    version: 16
  },
  [ModelsNames.checkpoint]: {
    ext: 'glb',
    dir: modelsDir,
    version: 8,
    mainMeshNames: [checkpointManager.CHECKPOINT_MESH_NAME],
    isTrainingModel: true
  },
  /*
   * [ModelsNames.cameraBoxes]: {
   *   ext: 'glb',
   *   dir: modelsDir,
   * }
   */

}
